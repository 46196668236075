import { useEffect, useRef, useState } from "react";
import { useAPIHandler } from "../../hooks/useAPIHandler";
import { useApp } from "../RealmApp";
import { isLocal } from "../utils/appUtils";
import { GZT } from "../DataManagement/DataManagement";
import ExportActivities from "./ExportActivities";
import ExportCapabilityComponent from "./ExportCapabilityComponent";
import { Button } from "primereact/button";
import { g_api } from "../../globals/api";
import JSZip from 'jszip';
import ExportAcceptanceCriteriaPage from "./ExportAcceptanceCriteriaPage";
import SurveyReport from "../SurveyReport/SurveyReport";

const LegendItem = ({ name, color, link }: any) => {

    return (
        <a href={`#${link}`} className="flex flex-row align-items-center gap-1">
            <div style={{ width: '15px', height: '15px', backgroundColor: color, borderRadius: '50%' }}></div>
            <div>{name}</div>
        </a>
    )
}

const downloadZip = async (projectName: string) => {
    try {
        const fetchUrl = `${g_api.root}${g_api.getProjectFiles(projectName)}`;
        console.log(fetchUrl);
        // Make the fetch request to the server
        const response = await fetch(fetchUrl, {
            method: 'GET',
            credentials: 'include',
        });

        // Ensure the response is OK
        if (!response.ok) {
            throw new Error('Failed to download the zip file');
        }

        // Get the binary data (Blob) from the response
        return await response.blob();
    } catch (err) {
        console.error('Error downloading zip:', err);
    }
};


const ExportProjectPage = ({ }) => {
    const [data, setData] = useState<any>({});
    const [isLoaded, setIsLoaded] = useState(0);
    const { getEntireProject, getEntireZTDatabase } = useAPIHandler();
    const { activeProject }: any = useApp();
    const projectName = activeProject?.selection?.name;
    const pageRef = useRef<any>(null);

    useEffect(() => {
        if (!projectName) return;
        getEntireZTDatabase()
            .then(data => {
                GZT.entire = [...data];
                setIsLoaded(prev => prev + 1);
            })
        if (!isLocal) {
            setData(activeProject);
            setIsLoaded(prev => prev + 1);
            return;
        }
        getEntireProject(projectName)
            .then(data => {
                setData(data);
                setIsLoaded(prev => prev + 1);
            })
    }, [projectName])

    const saveAsHtml = async () => {
        let pageHtml = pageRef.current.outerHTML; // Get HTML content

        let tempDiv = document.createElement('div');
        tempDiv.innerHTML = pageHtml;

        const liveCanvases = pageRef.current.querySelectorAll('canvas');
        const clonedCanvases: any = tempDiv.querySelectorAll('canvas');
        liveCanvases.forEach((liveCanvas: any, index: number) => {
            const clonedCanvas = clonedCanvases[index];

            if (!clonedCanvas) return;

            const img = document.createElement('img');
            img.src = liveCanvas.toDataURL('image/png');
            img.alt = liveCanvas.ariaLabel || '';
            clonedCanvas.parentNode?.replaceChild(img, clonedCanvas);
        })

        const exportBtn: any = tempDiv.querySelector('#export-btn');
        exportBtn.parentNode.removeChild(exportBtn)

        pageHtml = tempDiv.outerHTML;

        const location = String(window.location.href).replace('.', '\.')
        const reg = new RegExp(location, 'g')

        pageHtml = pageHtml.replace(reg, './');

        // Replace absolute URLs with relative ones
        const currentOrigin = window.location.origin;
        pageHtml = pageHtml.replace(new RegExp(currentOrigin, 'g'), '.');

        // Collect stylesheets
        const stylesheetsNodes = Array.from(
            document.querySelectorAll('link[rel="stylesheet"], style')
        );

        const stylesheetsPromises = stylesheetsNodes.map(async (node) => {
            if (node.tagName === 'LINK') {
                const href:any = node.getAttribute('href');
                const absoluteHref = new URL(href, window.location.href).href;

                try {
                    const response = await fetch(absoluteHref);
                    if (response.ok) {
                        const cssText = await response.text();
                        return `<style>${cssText}</style>`;
                    } else {
                        console.warn(`Failed to fetch CSS file: ${href}`);
                        return ''; // Return empty string if fetch fails
                    }
                } catch (error) {
                    console.warn(`Error fetching CSS file: ${href}`, error);
                    return ''; // Handle fetch errors
                }
            } else if (node.tagName === 'STYLE') {
                return node.outerHTML;
            }
        });

        // Wait for all CSS content to be fetched
        const stylesheetsArray = await Promise.all(stylesheetsPromises);
        const stylesheets = stylesheetsArray.join('');

        // const stylesheets = Array.from(document.styleSheets)
        //     .map((sheet) => {
        //         try {
        //             if (sheet.href) {
        //                 // Include external stylesheets as <link> elements
        //                 return `<link rel="stylesheet" href="${sheet.href}">`;
        //             } else {
        //                 // For inline styles, retrieve the rules
        //                 const rules = Array.from(sheet.cssRules)
        //                     .map((rule) => rule.cssText)
        //                     .join('');
        //                 return `<style>${rules}</style>`;
        //             }
        //         } catch (e) {
        //             console.warn("Access to stylesheet denied:", e);
        //             return ''; // Some stylesheets may be cross-origin restricted, so we catch those errors
        //         }
        //     })
        //     .join('');

        const completeHtml = `
      <html>
      <head>
        <meta charset="UTF-8">
        <title>Saved Page</title>
        ${stylesheets} <!-- Add any other styles if necessary -->
      </head>
      <body>
        ${pageHtml}
      </body>
      </html>
    `;

        const webblob = new Blob([completeHtml], { type: 'text/html' });
        const arrayBuffer = await webblob.arrayBuffer();

        const fileBlob: any = downloadZip(projectName);

        const zip = await JSZip.loadAsync(fileBlob);
        zip.file(`Report_${projectName}.html`, arrayBuffer);

        const updatedZipBlob = await zip.generateAsync({ type: 'blob' });

        const url = URL.createObjectURL(updatedZipBlob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `ZT Survey_${projectName}_Export.zip`); // File name to download

        // link.download = 'page.html'; // The name of the saved file
        document.body.appendChild(link);
        link.click();
        // Remove the link after the download
        document.body.removeChild(link);

        // Release the object URL to free memory
        URL.revokeObjectURL(url);
    };

    if (!projectName) return <>Select A Project.</>

    if (isLoaded < 2) return <></>

    return (
        <div style={{ height: '100vh', overflowY: 'hidden' }} ref={pageRef}>
            <div className="p-menubar flex flex-column justify-content-center align-items-center" style={{ height: '100px', width: 'var(--width-survey)' }}>

                <div className="w-full text-lg text-center"><strong>Project: </strong>{activeProject.selection.name}</div>
                <div className="ztac-subtitle text-center m-2">ZT Survey Tool</div>

                <div className="flex flex-row gap-3 justify-content-center align-items-center">
                    <Button size="small" id='export-btn' label="Export" onClick={() => saveAsHtml()} style={{}} />
                    <LegendItem name='Reports' color='var(--color-p3)' link='pg-reports' />
                    <LegendItem name='Capabilities' color='var(--color-v5)' link='pg-capabilities' />
                    <LegendItem name='Activities' color='var(--color-v6)' link='pg-activities' />
                    <LegendItem name='Acceptance Criteria' color='var(--color-v7)' link='pg-acriteria' />
                </div>
            </div>
            <div style={{ overflowY: 'auto', height: 'calc(100vh - 100px)' }}>

                <div className="flex flex-row" id='pg-reports'>
                    <div style={{ width: '1rem', backgroundColor: 'var(--color-p3)' }}></div>
                    <div className="w-full">
                        <SurveyReport />
                    </div>
                </div>

                <h3 id='pg-capabilities'>Capabilities / Products / Services</h3>
                <div className="flex flex-row">
                    <div style={{ width: '1rem', backgroundColor: 'var(--color-v5)' }}></div>
                    <div className="w-full" style={{ maxWidth: 'var(--width-survey)' }}>
                        {data.ztCapabilities.map((capObj: any) => {
                            return <ExportCapabilityComponent key={`rcid-${capObj.name}`} state={capObj} activeProject={data} />
                        })}
                    </div>
                </div>

                <h3 id='pg-activities'>ZT Activities:</h3>
                <div className="flex flex-row">
                    <div style={{ width: '1rem', backgroundColor: 'var(--color-v6)' }}></div>
                    <div className="w-full">
                        <ExportActivities activeProject={data} />
                    </div>
                </div>

                <h3 id='pg-acriteria'>Acceptance Criteria:</h3>
                <div className="flex flex-row">
                    <div style={{ width: '1rem', backgroundColor: 'var(--color-v7)' }}></div>
                    <div className="container-survey-content">
                        <ExportAcceptanceCriteriaPage projectData={data} />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ExportProjectPage;