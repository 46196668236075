import { useApp } from "../components/RealmApp";
import { useAPIHandler } from "./useAPIHandler";

const useModifyZTACData = () => {
    const { activeProject }: any = useApp();
    const { postZTACDataModify } = useAPIHandler();

    const handleClick = async (modData: any) => {
        const project = activeProject?.selection?.name;

        return postZTACDataModify({
            project,
            modData,
        });
    }
    return handleClick;
}

export default useModifyZTACData;