import axios from "axios";
import { g_api } from "../globals/api";

export const getProjectOverview = async (projectName: string) => {
    return axios.get(`${g_api.root}/ztkm/project/${projectName}`, { withCredentials: true }).then(res => res.data);
}

export const getCapabilityData = async (cId: string) => {
    return axios.get(`${g_api.root}/zt/capability/${cId}`, { withCredentials: true }).then(res => res.data);
}

export const postModifyZTStatuses = async (data:any) => {
    if(!data.project){
        return Promise.reject(new Error("No Project Selected."));
    }
    return axios.post(`${g_api.root}${g_api.modifyStatuses(data.project)}`, data, { withCredentials: true }).then(res => res.data);
}

export const getProjectAnalytics = async (projectName:string, type:'data'|'ztac'='data') => {
    if(!projectName){
        return Promise.reject(new Error("No Project Selected."));
    }
    return axios.get(`${g_api.root}${g_api.getProjectAnalytics(projectName)}/${type}`, { withCredentials: true }).then(res => res.data);
}


export const postModifyCapabilities = async (data: any, type: 'add' | 'update' | 'delete') => {
    // const url = (type === 'add') ? g_api.addCapabilities : g_api.updateCapabilities;
    let url = null;
    switch (type) {
        case 'add':
            url = g_api.addCapabilities;
            break;
        case 'update':
            url = g_api.updateCapabilities;
            break;
        case 'delete':
            url = g_api.deleteCapabilities;
            break;
        default:
            return null;
    }
    return axios.post(
        `${g_api.root}${url}`,
        data,
        { withCredentials: true },
    ).then(res => res.data);
}

