import { useMemo } from "react";
import { useApp } from "../RealmApp";
import { MultiSelect } from "primereact/multiselect";

type T_LinkArtifactProps = {
    onChange?: any;
}

type T_FileObject = {
    filename: string;
    backendName: string;
    tags: string[];
    notes: string;
    size: number;
    meta? : {
        displayName?: string;
    };
    submitDate: Date;
}

const LinkArtifactComponent = ({ onChange }: T_LinkArtifactProps) => {
    const { activeProject }: any = useApp();
    const files:T_FileObject[] = activeProject?.files || [];

    const options = useMemo(() => {
        if(files.length === 0) return [];
        return files.map((file) => {
            const displayName = file?.meta?.displayName || file.filename;
            return {
                ...file,
                filename : displayName,
            }
        })
    },[files])

    return (
        <MultiSelect value={null} onChange={onChange} options={options} optionLabel="filename"
            placeholder="Link an Artifact" filter maxSelectedLabels={10} className="w-full md:w-20rem align-self-center" />
    )
}

export default LinkArtifactComponent;