import React, { useEffect, useReducer, useState } from "react";
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import './ProjectSelection.css';
import { Button } from "primereact/button";
import { useApp } from "../RealmApp";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Chips } from "primereact/chips";
import axios from "axios";
import useProjectsData from "../../hooks/useProjectsData";
import { g_api } from "../../globals/api";
import ImportNewProjectXlsx from "../ImportProject/ImportNewProjectXlsx";

// const projectProfiles = [
//     { name: 'ZT-All', code: 'za' },
//     { name: 'MPE Baseline', code: 'mp' }
// ];

interface Projects {
    name: string;
    code: string;
}

type T_ZTKMProject = {
    name: string;
    createdBy: string;
    notes: string;
    customer: string;
    profile: string;
    tags: string[];
    validProject: boolean;
    showEditor: boolean;
    isNewProject: boolean;
}

export type T_AddProjectUpdateTypes = {
    type: string;
    field?: string;
    payload?: any;
}

export const emptyProjectObject: T_ZTKMProject = {
    name: '',
    createdBy: '',
    notes: '',
    profile: 'ZT-All',
    customer: '',
    tags: [],
    validProject: false,
    showEditor: false,
    isNewProject: false,
}

const regValidProjectName = /^[A-Za-z0-9\-]{5,30}$/;
const regRestrictedName = /^[A-Za-z0-9\-]+/;

const validProjectName = (name: string): boolean => {
    return regValidProjectName.test(name);
}

export function addProjectReducer(state: T_ZTKMProject, action: T_AddProjectUpdateTypes): T_ZTKMProject {
    switch (action.type) {
        case 'init':
            return {
                ...state,
                ...action.payload,
                validProject: validProjectName(action.payload?.name),
            }
        case 'savedProject':
            return {
                ...state,
                showEditor: false,
                isNewProject: false,
            }
        case 'update':
            let isValid = state.validProject;
            if (!action?.field) return state;
            if (action.field === 'name') {
                isValid = validProjectName(action.payload);
            }
            return {
                ...state,
                validProject: isValid,
                [action.field]: action.payload,
            }
        case 'addProject':
            return {
                ...emptyProjectObject,
                showEditor: true,
                isNewProject: true,
            }
        case 'empty':
            const showEditor = state.showEditor;
            return {
                ...emptyProjectObject,
                showEditor: showEditor,
            }
        default:
            return state;
    }
}

export default function ProjectSelection() {
    const app: any = useApp();
    const { projects, refetch } = useProjectsData();
    const [state, dispatch] = useReducer(addProjectReducer, { ...emptyProjectObject,  });

    useEffect(() => {
        if(projects && projects.length === 0) return;
        const lastSelectedProject = localStorage.getItem('selectedProject') || null;
        if(!lastSelectedProject) return;
        const foundProject = projects.find((x:any) => x.name === lastSelectedProject);
        if(!foundProject) return;
        app.getProject(foundProject);
    },[projects])

    useEffect(() => {
        if (app.activeProject.name === state.name) return;
        if (app.activeProject.name === '') {
            dispatch({ type: 'empty', payload: '', field: '' });
            return;
        }
        dispatch({ type: 'init', payload: app.activeProject, field: '' });
    }, [app.activeProject])

    useEffect(() => {
        const newSelect = projects.find((x: any) => x.name === state.name);
        if (newSelect) app.getProject(newSelect);
        dispatch({ type: 'empty', payload: '', field: '' });
    }, [projects])

    const handleSelection = (e: DropdownChangeEvent) => {
        const newProject = e.value;
        localStorage.setItem('selectedProject', e.value.name);
        app.getProject(newProject);
    }

    const footer = () => (
        <Button size="small" label="Add Project"
            icon="pi pi-plus" severity="success" outlined
            style={{ width: '100%' }}
            disabled={app?.permissions?.app !== 'f'}
            onClick={() => {
                dispatch({ type: 'addProject', field: '', payload: '' });
            }} />
    )

    const handleSaveProject = () => {
        const successNotice = (state.isNewProject) ? 'saved' : 'updated';
        const urlPath = (state.isNewProject) ? g_api.addProject : g_api.updateProject;
        const url = new URL(urlPath, g_api.root);

        axios.post(url.href, state, {
            withCredentials: true,
            headers: { 'Content-Type': 'application/json' },
        }).then((msg: any) => {
            app?.toast.current?.show({ severity: 'success', summary: 'Success', detail: `Project ${state.name} ${successNotice}.`, life: 3000 });
            (state.isNewProject) ? refetch() : app.getProject(app.activeProject.selection);
            dispatch({ type: 'savedProject' });
        }).catch(error => {
            const log: string = error?.response?.data?.message || error?.response?.data?.error?.message || `Unknown Error for: ${state.name}`;
            app?.toast.current?.show({ severity: 'error', summary: 'Error', detail: log, life: 3000 });
        })
    }

    const header = () => (
        <>
            {(state.name) ? `Edit: ${state.name}` : 'Create New Project'}
        </>
    )

    const itemTemplate = (option: any) => {
        return (
            <div className="flex flex-row justify-content-between align-items-center">
                <div>{option.name}</div>
                <Button icon='pi pi-pencil' text rounded severity="secondary" size="small" onClick={() => dispatch({ type: 'update', field: 'showEditor', payload: true })} />
            </div>
        )
    }

    const setRFIData = (rfiData:any) => {
        dispatch({type: 'init', payload: rfiData})
    }

    return (
        <>
            <div className="card project-selection">
                <Dropdown value={app?.activeProject?.selection || null} onChange={handleSelection} options={projects} optionLabel="name"
                    itemTemplate={itemTemplate}
                    placeholder="Select a Project" className="w-full md:w-14rem" checkmark={true} highlightOnSelect={false} panelFooterTemplate={footer} />
            </div>
            <Dialog visible={state.showEditor} onHide={() => dispatch({ type: 'update', field: 'showEditor', payload: false })} header={header}>
                <div className="card flex flex-column justify-content-center gap-4" style={{ padding: '1rem', overflowY: 'auto' }}>
                    {state.isNewProject &&
                        <div className="flex flex-column gap-2 align-items-center">
                            <ImportNewProjectXlsx setData={setRFIData}/>
                        </div>
                    }
                    <div className="flex flex-column gap-2">
                        <label htmlFor="addP-name">Project Name</label>
                        <InputText id="addP-name" keyfilter={regRestrictedName}
                            invalid={!state.validProject}
                            value={state.name}
                            disabled={!state.isNewProject}
                            onChange={(e) => dispatch({ type: 'update', field: 'name', payload: e.target.value })} />
                        <small id="addP-name-help" className="font-italic">
                            Must be 5-30 characters. Accepted inputs are alphanumber and dashes.
                        </small>
                    </div>
                    <div className="flex flex-column gap-2">
                        <label htmlFor="addP-customer">Customer</label>
                        <InputText id="addP-customer" value={state.customer} onChange={(e) => dispatch({ type: 'update', field: 'customer', payload: e.target.value })} />
                    </div>
                    <div className="flex flex-column gap-2">
                        <label htmlFor="addP-profile">Survey Profile</label>
                        <Dropdown value={{'name' : state.profile}} onChange={(e) => dispatch({ type: 'update', field: 'profile', payload: e.value.name })} options={app.ztProfiles} optionLabel="name"
                            placeholder="Select a Profile" className="w-full md:w-14rem" />
                    </div>
                    <div className="flex flex-column gap-2">
                        <label htmlFor="addP-tags">Tags</label>
                        <div className="flex flex-row w-full">
                            <Chips id='addP-tags' value={state.tags}
                                keyfilter={regRestrictedName}
                                allowDuplicate={false}
                                onChange={(e) => dispatch({ type: 'update', field: 'tags', payload: e.value })}
                            />
                            <Button icon='pi pi-times' size="small" outlined severity="warning"
                                disabled={state.tags.length === 0}
                                onClick={(e) => dispatch({ type: 'update', field: 'tags', payload: [] })}
                            />
                        </div>
                    </div>
                    <div className="flex flex-column gap-2">
                        <label htmlFor="addP-notes">Notes</label>
                        <InputTextarea id="addP-notes" value={state.notes} onChange={(e) => dispatch({ type: 'update', field: 'notes', payload: e.target.value })} rows={5} cols={30} />
                    </div>
                    <Button onClick={handleSaveProject} label="Save Project" severity="success" disabled={!state.validProject} />
                </div>
            </Dialog>
        </>
    )
}
