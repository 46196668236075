import ACStatus from "./ACStatusButton";
import { CodeBlock, useZTACContext, ZTACBullet } from "./ZTAC_Component";



const ZTAC8 = () => {
    return (
        <></>
    )
};

ZTAC8.Title = function ZTAC1_Title() {
    return (
        <>
            <div className="ztac-title">8.0: Can Enforcement Services apply ZT computational determinations instantly and accurately?</div>
            {/* <div className="ztac-subtitle">None</div> */}
        </>
    )
}

ZTAC8.Intro = function ZTAC8_Intro() {
    return (
        <>
            <div>
                <h3>Acceptance Criteria:</h3>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="ac-001">Access control enforcement is based on the computational disposition for each specific challenge element or a comprehensive disposition of all challenge elements in aggregate and/or supplemental data protection applied.</ZTACBullet>
                    <p>Note: The enforcement service is not required to complete the determination calculation of a challenge request, but it may offer a combined capability as a programmable offering (I.e.; the computational disposition can be programmatically controlled and turned on or off as a configuration setting). In the event the enforcement service does offer the determination calculation of a challenge request, it is required to use all attributes of a challenge request, have a logic processing capability to allow business rules to be constructed and executed and must fully accommodate all elements of the policy using logic rules of the associated attributes from IBAC/RBAC/ABAC. The computational outcome of a logic rule must be able to be applied discretely to each of the Identity affirmation challenge, the role being able to be assumed by and authorized identity and the attributes of each associated resource, asset and object to provide a binary disposition based on:</p>
                    <ZTACBullet code="ac-002">Non-repudiable determination as prescribed in policy</ZTACBullet>
                    <ZTACBullet code="ac-003">A capability to consume the complete role management expression set</ZTACBullet>
                    <ZTACBullet code="ac-004">A capability to discretely identify and determine if a non-repudiable identity is authorized to associate with a discrete role</ZTACBullet>
                    <ZTACBullet code="ac-005">A capability to consume the complete resource, asset and object expression set</ZTACBullet>
                    <ZTACBullet code="ac-006">A capability that is able to support the maximum possible attribute and role permutations to discretely identify and determine if a role is authorized to use each and then in aggregate all of the resources, assets and objects of the request.</ZTACBullet>
                    <ZTACBullet code="ac-007">The ability to produce a binary disposition that is machine readable</ZTACBullet>
                    <ZTACBullet code="ac-008">The ability to enable or restrict the request from being executed</ZTACBullet>
                    <ZTACBullet code="ac-009">Has one and only one interpretation and is understood by all consumers (man or machine) regardless of their specific domain or functional area</ZTACBullet>
                    <ZTACBullet code="ac-010">Executed so that all elements of the rule processing allows for discrete evaluation for the individual and overall determination of the disposition</ZTACBullet>
                    <ZTACBullet code="ac-011">No additional attributes or logic elements are added or subtracted to the executing rule</ZTACBullet>
                    <ZTACBullet code="ac-012">Can resolve, programmatically, any competitive outcome disagreement by using a programmable precedence scheme that is systematically configurable.</ZTACBullet>
                    <ZTACBullet code="ac-013">Prevents any rule from modifying the meaning or outcome of another rule. (Note: Other rules can be “superior” and overrule what this rule is intended to achieve based on programmatic execution. For example, if a superior rule states that all rules are suspended for a period of time, it does not alter any other rule, it simply nullifies the use of the other rules for that period of time.)</ZTACBullet>
                </ul>
            </div>

            <div>
                <h3>Outcome:</h3>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="ou-001">Every enforcement disposition outcome is consistently pre-computable and repeatable.</ZTACBullet>
                    <ZTACBullet code="ou-002">All enforcement actions are demonstrable to be consistent and compliant with the digital policy desired behavioral intent.</ZTACBullet>
                    <ZTACBullet code="ou-003">IBAC/RBAC/ABAC enforcement can be separated and sequenced for optimization consideration</ZTACBullet>
                    <ZTACBullet code="ou-004">The result of rule enforcement is identical, regardless of operating domain</ZTACBullet>
                    <ZTACBullet code="ou-005">The rules and attributes are machine readable and system agnostic</ZTACBullet>
                    <ZTACBullet code="ou-006">All enforcement outcomes can be pre-generated and consumable by another enforcement service (assuming it is compliant with the model) to allow white-box testing of all digital policy enforcement outcomes.</ZTACBullet>
                    <ZTACBullet code="ou-007">The logic rules and associated attributes and specific attribute values can be comprehensively generated to create a complete logic map of all possible enforcement outcomes.</ZTACBullet>
                </ul>
            </div>

            <div>
                <h3>Conceptual Measures:</h3>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="cm-001">Each logic rule and associated attributes and specific attribute values can be discretely executed and a disposition outcome produced. (The rule-attributes can be computationally pre-computed)</ZTACBullet>
                    <ZTACBullet code="cm-002">A complete report and disposition can be generated for each digital policy statement and all attributes and the associated allowed values</ZTACBullet>
                    <ZTACBullet code="cm-003">All disposition outcomes are discrete and not dependent on other disposition processing to derive the outcome.</ZTACBullet>
                    <ZTACBullet code="cm-004">Rule execution does not deviate from the digital policy.</ZTACBullet>
                    <ZTACBullet code="cm-005">The rules can only resolve to a single outcome</ZTACBullet>
                    <ZTACBullet code="cm-006">A dynamic modification of a rule produces the correct result near-instantaneously (e.g.; once a rule has been updated, the disposition henceforth is based on the current rule, and there is no “retained memory or caching”</ZTACBullet>
                </ul>
            </div>
        </>
    )
}

ZTAC8.UseCase = function ZTAC8_UseCase() {
    return (
        <div>
            <h3>Implementation Use Case: (example)</h3>
            <p>Zero Trust Enforcement Services for Access Control</p>
            <p>Scenario: Access control enforcement is based on the computational disposition for each specific challenge element or a comprehensive disposition of all challenge elements in aggregate and/or supplemental data protection applied.</p>

            <ul>
                <li>
                    Define Policy Attributes:
                    <ul>
                        <li>Collaborate with IT, security, and compliance teams to define the necessary user, behavioral, and activity attributes for monitoring.</li>
                    </ul>
                </li>
                <li>
                    Develop Logic Rules:
                    <ul>
                        <li>Develop logic rules that describe the policy in terms of attributes and allowed values. For example:</li>
                        <CodeBlock style={{ margin: '1rem' }}>
                            IF User Role = 'Financial Analyst' AND <br />
                            Clearance Level = 'Confidential' AND <br />
                            Access Time = 'Business Hours' AND <br />
                            Access Location = 'Secure Office Network' AND <br />
                            Device Compliance = 'Up-to-date Security Patches' AND <br />
                            Device Type = 'Company-issued Laptop' THEN <br />
                            Access = 'Granted' ELSE <br />
                            Access = 'Denied'
                        </CodeBlock>
                    </ul>
                </li>
                <li>
                    Automate Data Collection:
                    <ul>
                        <li>Implement automated data collection mechanisms to gather information on user activities, access times, locations, device compliance, and transaction volumes.</li>
                    </ul>
                </li>
                <li>
                    Pre-compute Outcomes:
                    <ul>
                        <li>Pre-compute all possible outcomes of the logic rules to ensure they produce the exact and explicit outcome intended.</li>
                    </ul>
                </li>
                <li>
                    Generate Reports:
                    <ul>
                        <li>Use automated tools to generate static and dynamic reports of each event and type, providing insight into typical and atypical behaviors.</li>
                    </ul>
                </li>
                <li>
                    Trend Tracking and Evolution:
                    <ul>
                        <li>Implement trend tracking mechanisms to monitor behavioral patterns over time and detect shifts based on rule adjustments.</li>
                    </ul>
                </li>
                <li>
                    Policy Recommendations:
                    <ul>
                        <li>Use analytic yields to recommend remediation approaches and formulate predictive digital policies.</li>
                    </ul>
                </li>
                <li>
                    Preemptive Defense Postures:
                    <ul>
                        <li>Implement preemptive defense mechanisms based on identified atypical behaviors and potential threats.</li>
                    </ul>
                </li>
                <li>
                    Example Scenarios:
                    <ul>
                        <li>Typical Access Granted:</li>
                        <CodeBlock style={{ margin: '1rem' }}>
                            User ID: 12345 <br />
                            Role: Financial Analyst <br />
                            Access Time: 10 AM <br />
                            Access Location: Secure Office Network <br />
                            Device Compliance: Up-to-date Security Patches <br />
                            Device Type: Company-issued Laptop <br />
                            Access Frequency: 5 times/day <br />
                            Access Duration: 30 minutes/session <br />
                            Data Accessed: Financial Reports <br />
                            Transaction Volume: Normal <br />
                            Outcome: Access Granted
                        </CodeBlock>
                        <li>Access Denied (Non-Compliant Device):</li>
                        <CodeBlock style={{ margin: '1rem' }}>
                            User ID: 12345 <br />
                            Role: Financial Analyst <br />
                            Access Time: 2 PM <br />
                            Access Location: Secure Office Network <br />
                            Device Compliance: Outdated Security Patches <br />
                            Device Type: Company-issued Laptop <br />
                            Access Frequency: 1 time <br />
                            Access Duration: 5 minutes <br />
                            Data Accessed: Financial Reports <br />
                            Transaction Volume: Normal <br />
                            Outcome: Access Denied
                        </CodeBlock>
                    </ul>
                </li>
                <li>
                    Validate Analytic Models:
                    <ul>
                        <li>Validate AI and ML models to ensure they accurately identify typical, unacceptable, and unanticipated behaviors.</li>
                    </ul>
                </li>
                <li>
                    Generate and Validate Reports:
                    <ul>
                        <li>Generate static and dynamic reports and validate that they provide accurate insights into behavioral patterns.</li>
                    </ul>
                </li>
                <li>
                    Monitor Trends:
                    <ul>
                        <li>Continuously monitor trends and validate that trend tracking mechanisms accurately depict behavioral shifts.</li>
                    </ul>
                </li>
                <li>
                    Policy Recommendations:
                    <ul>
                        <li>Validate that policy recommendations are based on accurate analytic yields and provide effective remediation approaches.</li>
                    </ul>
                </li>
            </ul>
        </div>
    )
}

export default ZTAC8;