import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { useEffect, useMemo, useRef, useState } from "react";
import { dropDownIconSurvey, getMaturityObject, getStatusObject, rand, surveyStatuses } from "./ZTSurveyActivitiesComponent";
import { confirmDialog } from 'primereact/confirmdialog';
import { useApp } from "../RealmApp";
import { getCapabilityData, postModifyCapabilities } from "../../apiCalls/apiZTKM";
import { toastError, toastSuccess } from "../utils/toastUtils";
import { T_CapabilityOverview } from "./reducerEditCapability";
import { Inplace, InplaceContent, InplaceDisplay } from "primereact/inplace";
import { getCapabilityDataCache, GZT } from "../DataManagement/DataManagement";

import './ZTSurveyCapabilityComponent.css';
import { Card } from "primereact/card";
import useModifyCapabilityLink from "../../hooks/useModifyLinkCapability";
import EditCapabilityPopup from "./EditCapabilityPopup";
import useModifyZTKMData from "../../hooks/useModifyZTKMData";
import { useApi } from "../ProviderAPIs";

type T_CapabilityData = {
    cId: string;
    name: string;
}

type T_ZTSurveyCapabilityComponent = {
    data: T_CapabilityData,
    isView: boolean;
    capabilities?: string[];
}


type T_CapabilityContext = {
    name: string;
    cId: string;
    description: string;
    impact: string;
    outcomes: string[],
    pillar: string;
    type: string;
}

const emptyCapabilityContext: T_CapabilityContext = {
    name: '',
    cId: '',
    description: '',
    impact: '',
    outcomes: [],
    pillar: '',
    type: 'capability',
}

export const closeContextIcon = (
    <div className="flex align-items-center gap-1">
        <div>Close Context</div>
        <i className="pi pi-chevron-up" />
    </div>
)

export const openContextIcon = (
    <>
        More Context
        <i className="pi pi-chevron-down" />
    </>
)

type T_CapabilityCard = {
    capabilityData: T_CapabilityOverview;
    project: string;
    cId: string;
}


const CapabilityCard = ({ capabilityData, project, cId }: T_CapabilityCard) => {
    const { toast, refreshProjectData }: any = useApp();
    const [showEditCapability, setShowEditCapability] = useState(false);
    const modifyCapabilityLink = useModifyCapabilityLink();
    const maturity = getMaturityObject(capabilityData.status);

    const handleDeleteLink = () => {
        modifyCapabilityLink(capabilityData.name, cId, 'delete')
            .then((res) => {
                refreshProjectData();
            })
            .catch((err) => {
                toastError(toast, err?.response?.data?.message || 'Unknown Error', err?.code || 'Error');
            })
    }

    return (
        <>
            <div className="flex flex-column" style={{ border: '1px solid grey', borderRadius: '0.5rem', width: 'fit-content' }}>
                <div className="flex flex-row gap-1 p-2 justify-content-between align-items-center">
                    <div className="" style={{ fontSize: 'larger', fontWeight: 'bold' }}>{capabilityData.name}</div>
                    <Button size="small" icon='pi pi-pen-to-square' severity="info" outlined onClick={() => setShowEditCapability(true)} />
                    <Button size="small" icon='pi pi-times' severity="danger" outlined onClick={handleDeleteLink} />
                </div>
                <div className="p-2"
                    style={{
                        color: 'white',
                        textAlign: 'center',
                        borderTop: '1px solid grey',
                        background: `var(--color-badge-${maturity.severity})`,
                        borderBottomLeftRadius: '0.5rem', borderBottomRightRadius: '0.5rem'
                    }}>
                    {capabilityData.status}
                </div>
            </div>
            <EditCapabilityPopup onClose={() => setShowEditCapability(false)} visible={showEditCapability} capabilityName={capabilityData.name} selectNew={() => { }} />
        </>
    )
}

const ZTSurveyCapabilityComponent = ({ data, isView, capabilities }: T_ZTSurveyCapabilityComponent) => {
    const pillar = (data?.cId && data.cId[0]) || '';
    const { toast, activeProject, refreshProjectData, analytics, ztProfileIds }: any = useApp();
    const api: any = useApi();
    const modifyCapabilityLink = useModifyCapabilityLink();
    const modifyResponse = useModifyZTKMData();
    // const [selectStatus, setSelectStatus] = useState(surveyStatuses[rand(0, 3)]);
    const selectStatus = analytics.find((x: any) => x.ztId === data.cId)?.status || 'Incomplete';
    const selectStatusObj = getStatusObject(selectStatus);
    // const [userClick, setUserClick] = useState(false);
    const [capabilityContextData, setCapabilityContextData] = useState<T_CapabilityContext>(emptyCapabilityContext);

    const listCapabilities: any = activeProject?.ztCapabilities?.filter((x: any) => !capabilities?.includes(x.name)) || [];

    const badgeTemplate = (option: any) => (
        <Badge value={option.status} severity={option.severity} className="" />
    )

    const sendModifyResponse = async (sendData: any) => {
        return modifyResponse(sendData)
            .then((res: any) => {
                refreshProjectData();
                // setSelectStatus(statusObj); // Just for demo. Need to refresh project data and add query to get status across all data points.
            })
            .catch((err) => {
                toastError(toast, err?.response?.data?.message || 'Unknown Error', err?.code || 'Error');
            })
    }

    const handleStatusChange = (statusObj: any) => {
        const sendData = [{
            ztId: data.cId,
            status: statusObj.status,
        }]
        if (['Skipped', 'NA'].includes(statusObj.status)) {
            confirmDialog({
                message: `Mark all activities under capability ${data.cId}: ${data.name} as [${statusObj.status}]?`,
                header: 'BULK Update',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-warning',
                // @ts-ignore
                accept: () => {
                    const cIdSplits = data.cId.split('.');
                    if (cIdSplits.length !== 2) return;
                    const skipActivities = ztProfileIds
                        .filter((ztId: string) => new RegExp(`^${cIdSplits[0]}\.${cIdSplits[1]}\.`).test(ztId))
                        .map((ztId: string) => ({ ztId: ztId, status: statusObj.status }));
                    sendModifyResponse([
                        ...sendData,
                        ...skipActivities,
                    ]).then((res: any) => {
                        toast.current.show({ severity: 'success', summary: 'BULK Update', detail: `All activities for ${data.cId}: ${data.name} changed to [${statusObj.status}]`, life: 3000 });
                    })
                },
                reject: () => sendModifyResponse(sendData),
            });
        } else {
            sendModifyResponse(sendData);
        }
    }

    const header = () => (
        <div className="survey-cap inView" style={{ backgroundColor: `var(--color-p${pillar}-dark)` }}>
            <div className="flex align-items-center gap-4">
                <span className="font-bold">{data.cId}: {data.name}</span>
            </div>
            <div className="flex flex-row justify-content-center align-items-center">
                <Dropdown value={selectStatusObj} onChange={(e) => handleStatusChange(e.value)} options={surveyStatuses} optionLabel="status" placeholder="Status" panelClassName="survey-dropdown" dropdownIcon={dropDownIconSurvey}
                    valueTemplate={badgeTemplate} itemTemplate={badgeTemplate} className="w-full" style={{ borderRadius: '1rem', backgroundColor: `var(--color-badge-${selectStatusObj.severity})`, borderColor: 'transparent' }}
                />
            </div>
        </div>
    )

    // const confirmSkipActivities = (data: any) => {

    // };

    const handleOpenContext = () => {
        getCapabilityDataCache(data.cId, api.getCapability).then((data: any) => {
            setCapabilityContextData(data);
        })
    }

    const handleModifyLink = async (capabilityObj: any) => {
        modifyCapabilityLink(capabilityObj.name, data.cId, 'add').then((msg) => {
            return refreshProjectData()
        })
            .catch((err) => {
                toastError(toast, err?.response?.data?.message || err?.message || 'Unknown Error', err?.code || 'Error');
            })
    }

    return (
        <>
            {(isView) ?
                <>
                    <Panel key={`cap-${data.name}-${activeProject?.name}`} headerTemplate={header} toggleable={false} className="flex flex-column zt-capability-component zt-inline-custom" >
                        <Inplace closable onOpen={handleOpenContext} closeIcon={closeContextIcon} className="survey-inplace">
                            <InplaceDisplay>{openContextIcon}</InplaceDisplay>
                            <InplaceContent>
                                <div>
                                    <h3>Description:</h3>
                                    {capabilityContextData.description}
                                </div>
                                <div>
                                    <h3>Impact:</h3>
                                    {capabilityContextData.impact}
                                </div>
                                <div>
                                    <h3>Outcome:</h3>
                                    {capabilityContextData.outcomes.map((outcome: string, index: number) => <p key={`${data.cId}-cli-${index}`}>{outcome}</p>)}
                                </div>
                            </InplaceContent>
                        </Inplace>
                        <div className="flex flex-column md:flex-row gap-2" style={{ padding: '1rem 0', flexWrap: 'wrap' }}>
                            {capabilities?.map((capabilityName: string, index: number) => {
                                const capabilityData = activeProject.ztCapabilities.find((x: any) => x.name === capabilityName);
                                if (!capabilityData) return <div key={`atoc-${data.cId}-${capabilityName}-${index}`}></div>
                                return (
                                    <CapabilityCard key={`atoc-${data.cId}-${capabilityName}-${index}`}
                                        capabilityData={capabilityData} cId={data.cId} project={activeProject.selection.name} />
                                )
                            })}
                        </div>
                        <div className="card flex justify-content-center">
                            <Dropdown value={null} onChange={(e) => handleModifyLink(e.value)} options={listCapabilities} optionLabel="name" placeholder="Select a Capability"
                                filter className="select-capability w-full md:w-14rem p-1" />
                        </div>
                    </Panel>
                </>
                :

                <div className="survey-cap" style={{ backgroundColor: `var(--color-p${pillar}-dark)` }}>
                    {data.cId}: {data.name}
                </div>
            }
        </>
    )
}

export default ZTSurveyCapabilityComponent;