
import { Menubar } from 'primereact/menubar';
import { InputText } from 'primereact/inputtext';
import { MenuItem } from 'primereact/menuitem';
import { Badge } from 'primereact/badge';
import { Avatar } from 'primereact/avatar';
import ProjectSelection from '../ProjectSelection/ProjectSelection';
import { isLocal } from '../utils/appUtils';
import { useApp } from '../RealmApp';
import { NavLink } from 'react-router-dom';

import { RiHome2Line } from "react-icons/ri";
import { RiSurveyLine } from "react-icons/ri";
import { BiTargetLock } from "react-icons/bi"
import { VscSymbolMisc } from "react-icons/vsc";
import { TbReportAnalytics } from "react-icons/tb";

import './NavigateZT.css';
import UserOptions from './UserOptions';
import { useRef } from 'react';
import SurveyDropdownOptions from './SurveyDropdownOptions';

export default function NavigateZT() {
    const app = useApp();
    const userOptionsRef = useRef(null);

    const start = () => (
        <div className='flex flex-row gap-4 navApp'>
            <img alt="logo" src={`${process.env.PUBLIC_URL}/imgs/app.ztkm-blk.svg`} height="40" className="mr-2" />
            {isLocal && <ProjectSelection />}
            <NavLink to="/explore" className='navApp-item'>
                <RiHome2Line className='navItem-icon' />
                <div className='navItem-text'>Home</div>
            </NavLink>
            <SurveyDropdownOptions />
            <NavLink to="/attack" className='navApp-item'>
                <BiTargetLock className='navItem-icon' />
                <div className='navItem-text'>ATT&CK</div>
            </NavLink>
            <NavLink to="/reports" className='navApp-item'>
                <TbReportAnalytics className='navItem-icon' />
                <div className='navItem-text'>Reports</div>
            </NavLink>
            <NavLink to="/Other" className='navApp-item'>
                <VscSymbolMisc className='navItem-icon' />
                <div className='navItem-text'>Other</div>
            </NavLink>
        </div>
    );

    const openUserOptions = (event: any) => {
        // @ts-ignore
        userOptionsRef.current.openMenu(event);
    }

    const end = (
        <div className="flex align-items-center gap-2">
            <UserOptions ref={userOptionsRef} />
            <Avatar label={'UR'} shape="circle" style={{ backgroundColor: '#2196F3', color: '#ffffff' }} onClick={(event) => openUserOptions(event)} />
        </div>
    );

    return (
        <div className="card">
            <Menubar start={start} end={end} />
        </div>
    )
}
