import ACStatus from "./ACStatusButton";
import { useZTACContext, ZTACBullet } from "./ZTAC_Component";



const ZTAC2 = () => {
    return (
        <></>
    )
};

ZTAC2.Title = function ZTAC1_Title() {
    return (
        <>
            <div className="ztac-title">2.0: Are you are affirmed as an actor that is allowed to assume a role?</div>
            <div className="ztac-subtitle">authorization, verification and validation</div>
        </>
    )
}

ZTAC2.Intro = function ZTAC2_Intro() {

    return (
        <>
            <div>
                <h3>Acceptance Criteria:</h3>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="ac-001">The specific Actor is affirmed to be mapped to a specific Role designation. The Role will typically be requesting to access resources (and indirectly assets) as well as objects to perform an action.</ZTACBullet>
                </ul>
            </div>

            <div>
                <h3>Outcome:</h3>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="ou-001">A confirmation is obtained verifying that Actor and Role association is valid.</ZTACBullet>
                </ul>
            </div>

            <div>
                <h3>Conceptual Measures:</h3>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="cm-001">The Actor is “who they say they are” - verification of the identity and validation of the Actor being registered in the enterprise, a non-repudiation measurement process</ZTACBullet>
                    <ZTACBullet code="cm-002">The Role is valid in the enterprise - the specific role is formally authorized for use and is able to pair with an Actor (specifically this Actor)</ZTACBullet>
                    <ZTACBullet code="cm-003">The Actor-Role pairing is authorized and confirmable using computational methods</ZTACBullet>
                </ul>
            </div>
        </>
    )
}

ZTAC2.UseCase = function ZTAC2_UseCase() {
    return (
        <div>
            <h3>Implementation Use Case: (example)</h3>
            <p>Scenario: A financial analyst (Actor) needs to access the organization's financial records system (Resource) to generate a fiscal year report. The analyst must assume the role of "Financial Analyst" (Role) to perform this action.</p>
            <ul>
                <li>
                    Identity Verification:
                    <ul>
                        <li>The financial analyst logs into the enterprise portal using their CAC and PIN.</li>
                    </ul>
                </li>
                <li>
                    Role Validation:
                    <ul>
                        <li>The system queries the enterprise's role management system to validate the "Financial Analyst" role.</li>
                        <li>The role management system confirms that the "Financial Analyst" role is active and has the necessary permissions to access the financial records system.</li>
                    </ul>
                </li>
                <li>
                    Actor-Role Pairing Authorization:
                    <ul>
                        <li>The system checks the access control list (ACL) to confirm that the financial analyst is authorized to assume the "Financial Analyst" role.</li>
                        <li>The system logs the Actor's request and the confirmation of the Actor-Role pairing for non-repudiation purposes.</li>
                    </ul>
                </li>
                <li>
                    Access Granting:
                    <ul>
                        <li>Upon successful verification, validation, and authorization, the system grants the financial analyst access to the financial records system.</li>
                        <li>The analyst can now generate the fiscal year report as required.</li>
                    </ul>
                </li>
            </ul>
        </div>
    )
}

export default ZTAC2;