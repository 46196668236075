import { T_DefaultReducerAction } from "../SurveyTool/reducerEditActivity.tsx";

export type T_ExportRFI = {
    profile: string | null;
    project: string | null;
    activeIndex: number;
}

export const emptyStateExportRFI:T_ExportRFI = {
    profile: null,
    project: null,
    activeIndex: 0,
}

export default function reducerSurveyExportRFI(state: T_ExportRFI, action: T_DefaultReducerAction): T_ExportRFI {
    switch (action.type) {
        case 'init':
            return {
                ...emptyStateExportRFI
            }
        case 'exportType':
            let project = null;
            if(action.payload.type === 'project') project = action.payload.project;

            return {
                ...state,
                activeIndex: action.payload.index,
                project: project,
            }
        case 'update':
            if (!action?.field) return state;
            return {
                ...state,
                [action.field]: action?.payload,
            }
        default:
            return state;
    }
}