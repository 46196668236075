import { useEffect, useMemo, useReducer, useState } from "react";
import { Dialog } from "primereact/dialog";
import { T_EditCapabilityActions } from "./reducerEditCapability";
import { getMaturityObject, StatusDropdown, T_FileObjectBackend, T_MaturityObject } from "./ZTSurveyActivitiesComponent";
import { InputTextarea } from "primereact/inputtextarea";


import useResponsive from "../../hooks/useResponsive";
import { Height } from "@mui/icons-material";
import { useZT } from "../../hooks/useZTfw";
import { GZT } from "../DataManagement/DataManagement";
import { Dropdown } from "primereact/dropdown";
import { CascadeSelect } from "primereact/cascadeselect";
import './EditCapabilityPopup.css';
import UploadFile from "../UploadFile/UploadFile";
import { formatFileSize } from "../utils/miscUtils";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { postModifyCapabilities } from "../../apiCalls/apiZTKM";
import { useApp } from "../RealmApp";
import { toastError, toastSuccess } from "../utils/toastUtils";
import useModifyZTKMData from "../../hooks/useModifyZTKMData";
import { g_api } from "../../globals/api";
import LinkArtifactComponent from "./LinkArtifactComponent";

type T_EditCapabilityPopup = {
    visible: boolean;
    onClose: () => void;
    capabilityName: string;
    selectNew: (capabilityName: string) => void;
}

export type T_FileObject = {
    filename: string;
    tags: string[];
    url: string;
    notes: string;
    size: number;
    meta: any;
    backendName: string;
    createdBy: string;
    submitDate: Date;
}

type T_CapabilityObject = {
    name: string;
    ztcIds: string[];
    status: string;
    notes: string;
    files: T_FileObject[],
    hasChanged: boolean;
    lastUpdated: Date;
}

const emptyCapabilityData: T_CapabilityObject = {
    name: '',
    ztcIds: [],
    status: 'Incomplete',
    notes: '',
    files: [],
    hasChanged: false,
    lastUpdated: new Date(),
}

function reducerCapabilityData(state: T_CapabilityObject, action: T_EditCapabilityActions): T_CapabilityObject {
    switch (action.type) {
        case 'init':
            return {
                ...action.payload,
            }
        case 'deleteActivity':
            let deleteActivities = new Set(state.ztcIds);
            deleteActivities.delete(action.payload)
            return {
                ...state,
                hasChanged: true,
                ztcIds: Array.from(deleteActivities),
            }
        case 'addActivity':
            let addActivities = new Set(state.ztcIds);
            addActivities.add(action.payload)
            return {
                ...state,
                hasChanged: true,
                ztcIds: Array.from(addActivities).sort(),
            }
        case 'update':
            if (!action?.field) return state;
            return {
                ...state,
                hasChanged: true,
                [action.field]: action?.payload,
            }
        default:
            return state;
    }
}

const EditCapabilityPopup = ({ visible, onClose, capabilityName, selectNew }: T_EditCapabilityPopup) => {
    const { toast, activeProject, refreshProjectData }: any = useApp();
    const [state, dispatch] = useReducer(reducerCapabilityData, emptyCapabilityData);
    const isAddNew = (capabilityName === '');
    const projectName = activeProject?.selection?.name;

    useEffect(() => {
        if (!activeProject?.ztCapabilities) return;
        const newData = activeProject.ztCapabilities.find((obj: any) => obj.name === capabilityName) || emptyCapabilityData;
        // Function to grab capability data
        dispatch({ type: 'init', payload: newData });
    }, [capabilityName, activeProject])

    const title = (state.name) ? `${state.name}` : 'Add New Capability'
    const maturityObject = getMaturityObject(state.status);

    const onStatusUpdate = (newStatus: T_MaturityObject) => {
        dispatch({ type: 'update', field: 'status', payload: newStatus.status })
    }

    const pickList = useMemo(() => {
        return GZT.pillars.map((pObj: any) => {
            const caps = GZT.capabilities.filter((capObj: any) => capObj.cId[0] === pObj.pillarNum);
            const capAddNames = caps.map((capObj: any) => ({ ...capObj, cName: `${capObj.cId}: ${capObj.name}` }))
            return {
                ...pObj,
                pName: `${pObj.pillarNum}: ${pObj.name}`,
                capabilities: [...capAddNames],
            }
        })
    }, []);

    const handleSaveCapabilities = () => {

        const modType = (isAddNew) ? 'add' : 'update';
        if (!projectName) {
            toastError(toast, "No Project Selected.");
            return;
        }

        postModifyCapabilities({
            project: projectName,
            capabilities: [{
                ...state,
            }]
        }, modType)
            .then((msg) => {
                toastSuccess(toast, msg?.msg || '', 'Update')
                return refreshProjectData()
            })
            .then((res: any) => {
                if (modType === 'add') {
                    selectNew(state.name);
                } else if (modType === 'update') {
                    onClose();
                }
            })
            .catch((err) => {
                toastError(toast, err?.response?.data?.message || 'Unknown Error', err?.code || 'Error');
            })
    }

    const footer = () => (
        <Button label='Save' icon='pi pi-save' size="small" severity="success" style={{ width: '100%' }} disabled={!state.hasChanged} onClick={handleSaveCapabilities} />
    )

    const handleUploadSuccess = (fileData: any) => {
        if (!fileData?.response?.files) return;

        const uploadFiles = fileData.response.files.map((file:any) => file.backendName);

        postModifyCapabilities({
            project: projectName,
            capabilities: [{
                ...state,
                files: state.files.concat(uploadFiles),
            }]
        }, 'update')
            .then((msg) => {
                toastSuccess(toast, msg?.msg || '', 'Update')
                return refreshProjectData()
            })
            .catch((err) => {
                toastError(toast, err?.response?.data?.message || 'Unknown Error', err?.code || 'Error');
            })
    }

    const handleLinkArtifact = (e: any) => {
        const file = e?.value?.[0]?.backendName;
        if (!file) return;
        handleUploadSuccess({
            'response' : {
                'files' : e.value
            }
        });
    }

    return (
        <Dialog
            visible={visible} onHide={onClose} closeOnEscape showHeader={false}
            footer={footer}
            className="survey-ae-capabilty-dialog survey-activity-content flex"
            contentClassName="flex flex-column" contentStyle={{ padding: 0, height: '100%' }}
            style={{ width: '50vw', height: '100%' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}
        >
            <div className="flex flex-row justify-content-between font-bold text-center p-2 gap-2" style={{ border: '1px solid #e5e7eb' }}>
                <div>{title}</div>
                <i className="pi pi-times p-button p-button-text p-button-secondary" onClick={onClose} style={{ padding: '0.1rem' }} />
            </div>
            <div className="overflow-y-auto p-3">
                {!capabilityName &&
                    <div className="flex flex-column justify-content-center align-items-center gap-1">
                        <h3>Capability Name:</h3>
                        <InputText value={state.name} onChange={(e) => dispatch({ type: 'update', field: 'name', payload: e.target.value })} />
                    </div>
                }
                <div className="flex flex-row justify-content-center align-items-center gap-1">
                    <h3>Response:</h3>
                    <StatusDropdown maturity={maturityObject} onChange={onStatusUpdate} />
                </div>
                <InputTextarea style={{ width: '100%', minHeight: '100px' }} value={state.notes} onChange={(e) => dispatch({ type: 'update', field: 'notes', payload: e.target.value })} />
                <div className="flex flex-column justify-content-center align-content-center w-full text-center">
                    <h3>Linked Capabilities:</h3>
                    <div className="link-capabilities-dropdown flex flex-column gap-1">
                        {state?.ztcIds && state.ztcIds.map((ztId) => {
                            const capabilityObject = GZT.capabilities.find((obj: any) => obj.cId === ztId);
                            return (
                                <div key={`${state?.name}-edit-${ztId}`} className="flex flex-row gap-2 text-left justify-content-between align-items-center">
                                    <div>{ztId} {capabilityObject?.name}</div>
                                    <i className="pi pi-times" style={{ cursor: 'pointer' }} onClick={() => dispatch({ type: 'deleteActivity', payload: ztId })} />
                                </div>
                            )
                        })}
                    </div>
                    <div className="w-full" style={{ marginTop: '1rem' }}>
                        <CascadeSelect value={null} onChange={(e) => dispatch({ type: 'addActivity', payload: e.value.cId })} options={pickList}
                            optionLabel="cName" optionGroupLabel="pName" optionGroupChildren={['capabilities']}
                            className="w-14rem" breakpoint="767px" placeholder="Select a Capability" style={{ whiteSpace: 'normal', overflowWrap: 'break-word' }} />
                    </div>
                </div>
                <div className="flex flex-column justify-content-center align-items-center w-full text-center gap-2">
                    <h3>Artifacts:</h3>
                    {state?.files && state.files.map((linkFile, i) => {
                        if (!activeProject?.files) return;
                        const file = activeProject.files.find((x: any) => x.backendName === linkFile);
                        if (!file) return;
                        return (
                            <div key={`f-${state.name}-${i}-${file.filename}`}
                                className="flex flex-row gap-3 p-button p-button-secondary p-button-outlined w-full"
                                onClick={() => window.open(`${g_api.root}${file.backendName}`, '_blank')}>
                                <i className="pi pi-file" />
                                <div>{formatFileSize(file.size || 0)}</div>
                                <div>{file?.meta?.displayName || file.filename}</div>
                            </div>
                        )
                    })}
                    <LinkArtifactComponent onChange={handleLinkArtifact}/>
                    {!capabilityName &&
                        <h4>Save Before Uploading Artifacts.</h4>
                    }
                    {capabilityName &&
                        <UploadFile projectName={projectName} uploadSuccess={handleUploadSuccess} />
                    }
                </div>
            </div>
        </Dialog>
    )
}

export default EditCapabilityPopup;