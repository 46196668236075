import ZTAC1 from "../AcceptanceCriteria/ZTAC1";
import ZTAC2 from "../AcceptanceCriteria/ZTAC2";
import ZTAC3 from "../AcceptanceCriteria/ZTAC3";
import ZTAC4 from "../AcceptanceCriteria/ZTAC4";
import ZTAC5 from "../AcceptanceCriteria/ZTAC5";
import ZTAC6 from "../AcceptanceCriteria/ZTAC6";
import ZTAC7 from "../AcceptanceCriteria/ZTAC7";
import ZTAC8 from "../AcceptanceCriteria/ZTAC8";
import ZTAC9 from "../AcceptanceCriteria/ZTAC9";
import ZTAC10 from "../AcceptanceCriteria/ZTAC10";
import ExportAcceptanceCriteriaComponent from "./ExportAcceptanceCriteriaComponent";
import { T_ZTACAnalytics, ZTACRootContext } from "../SurveyTool/SurveyToolACProvider";
import { getProjectAnalytics } from "../../apiCalls/apiZTKM";
import { useEffect, useState } from "react";
import { useApp } from "../RealmApp";


const ExportAcceptanceCriteriaPage = ({ projectData }: any) => {
    const [ztacAnalytics, setZTACAnalytics] = useState<T_ZTACAnalytics>([]);
    const { activeProject }:any = useApp();

    const projectName = activeProject?.selection?.name;
    const acData = projectData?.ztacData || [];

    useEffect(() => {
        if(!projectName) return;
        getZTACAnalytics(projectName);
    },[projectName])

    const refetchZTACAnalytics = () => {};

    const getZTACAnalytics = async (projectName: string) => {
        return getProjectAnalytics(projectName, 'ztac').then((data) => {
            if (!data?.ztacData) return setZTACAnalytics([]);
            if (!Object.entries(data.ztacData).length) return setZTACAnalytics([]);
            setZTACAnalytics([
                ...data.ztacData
            ])
        });
    }

    return (
        <ZTACRootContext.Provider value={{ztacAnalytics, refetchZTACAnalytics}}>
            <ExportAcceptanceCriteriaComponent acId="1.0" acData={acData}>
                <ZTAC1.Title />
                <ZTAC1.Intro />
            </ExportAcceptanceCriteriaComponent>
            <ExportAcceptanceCriteriaComponent acId="2.0" acData={acData}>
                <ZTAC2.Title />
                <ZTAC2.Intro />
            </ExportAcceptanceCriteriaComponent>
            <ExportAcceptanceCriteriaComponent acId="3.0" acData={acData}>
                <ZTAC3.Title />
                <ZTAC3.Intro />
            </ExportAcceptanceCriteriaComponent>
            <ExportAcceptanceCriteriaComponent acId="4.0" acData={acData}>
                <ZTAC4.Title />
                <ZTAC4.Intro />
            </ExportAcceptanceCriteriaComponent>
            <ExportAcceptanceCriteriaComponent acId="5.0" acData={acData}>
                <ZTAC5.Title />
                <ZTAC5.Intro />
            </ExportAcceptanceCriteriaComponent>
            <ExportAcceptanceCriteriaComponent acId="6.0" acData={acData}>
                <ZTAC6.Title />
                <ZTAC6.Intro />
            </ExportAcceptanceCriteriaComponent>
            <ExportAcceptanceCriteriaComponent acId="7.0" acData={acData}>
                <ZTAC7.Title />
                <ZTAC7.Intro />
            </ExportAcceptanceCriteriaComponent>
            <ExportAcceptanceCriteriaComponent acId="8.0" acData={acData}>
                <ZTAC8.Title />
                <ZTAC8.Intro />
            </ExportAcceptanceCriteriaComponent>
            <ExportAcceptanceCriteriaComponent acId="9.0" acData={acData}>
                <ZTAC9.Title />
                <ZTAC9.Intro />
            </ExportAcceptanceCriteriaComponent>
            <ExportAcceptanceCriteriaComponent acId="10.0" acData={acData}>
                <ZTAC10.Title />
                <ZTAC10.Intro />
            </ExportAcceptanceCriteriaComponent>
        </ZTACRootContext.Provider>
    )
}

export default ExportAcceptanceCriteriaPage;