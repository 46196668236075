import { Button } from 'primereact/button';
import React, { useState, useEffect } from 'react';

interface ErrorBoundaryProps {
    children: React.ReactNode;
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        const handleError = (error: ErrorEvent) => {
            console.error("Error caught by Error Boundary:", error.message);
            setHasError(true);
        };

        // Catch any unhandled errors globally
        window.addEventListener('error', handleError);

        return () => {
            window.removeEventListener('error', handleError);
        };
    }, []);

    const handleGoBack = () => {
        window.location.href = '/'; // Redirect to the root of the app
    };

    if (hasError) {
        return (
            <div>
                <h1>Something went wrong.</h1>
                <p>We're sorry, but an unexpected error occurred. Try to reload the page.</p>
                <Button label='Reload' onClick={handleGoBack} />
            </div>
        );
    }

    return <>{children}</>;
};

export default ErrorBoundary;
