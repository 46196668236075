import ACStatus from "./ACStatusButton";
import { useZTACContext, ZTACBullet } from "./ZTAC_Component";



const ZTAC5 = () => {
    return (
        <></>
    )
};

ZTAC5.Title = function ZTAC1_Title() {
    return (
        <>
            <div className="ztac-title">5.0: Are the objects/assets (data, etc.) ascribed with access and use characterizations?</div>
            <div className="ztac-subtitle">attributions - when, where and how information is allowed for use</div>
        </>
    )
}

ZTAC5.Intro = function ZTAC5_Intro() {
    return (
        <>
            <p className="text-lg font-bold">*Objects and assets are separated for this discussion.</p>

            <h2>A. Object</h2>

            <div>
                <h3>Acceptance Criteria:</h3>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="aca-001">Objects and assets are separated for this discussion.</ZTACBullet>
                    <ZTACBullet code="aca-002">Each Object has a fully defined characterization profile (attributes) that provides a clear and precise understanding of the constraints that restrict use during any operation.</ZTACBullet>
                </ul>
            </div>

            <p className="font-italic">The constraints are notionally administered using an automated business rule engine to determine if a leveraging the resource remains in compliance with policy and established constraints.</p>
            <p className="font-italic">For the purpose of this acceptance criteria, “object” is typically identified as actor addressable information containers, UI/UX capabilities, data stores, or information orchestration tools (RDBMs), or other capabilities that perform an action based on instructions, commands, programmed sequences or mechanical mechanisms. They can be as simple as a simple text file or data field or as complex as a geographically dispersed cooperative multi-sourced data repository with multiple interchange mechanisms.</p>
            <p className="font-italic">For example: if the object is classified at the Secret level, then one attribute that must be present is “level_classfication” with a value set to “Secret” [e.g., Classification: “Secret”].</p>
            <p className="font-italic">NOTE: level_classification is notional and a CCV would actually be used (or an LCV) or the local “Metadata Label”.</p>

            <div>
                <h3>Outcome:</h3>
                <p>The set of attributes for an object can be explicitly and exhaustively mapped to any use case, vignette, day-in-the-Life thread or process model to regulate and arbitrate if constraint conditions are encountered while executing any action in the “operational” stream that is specified.</p>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="oua-001">Each of the “attributes” are clearly articulated.</ZTACBullet>
                    <ZTACBullet code="oua-002">Each attribute has the specified value for all known (and unknown) operating conditions.</ZTACBullet>
                    <ZTACBullet code="oua-003">A full map of the attributes and outcomes (requirements streams) can be generated to provide comprehensive insight to all acceptable operating conditions for the object.</ZTACBullet>
                </ul>
            </div>

            <div>
                <h3>Conceptual Measures:</h3>
                <p>The Object attributes are discrete and identifiable:</p>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="cma-001">A complete listing of all attributes that are applied to the Object are discoverable (if authorized).</ZTACBullet>
                    <ZTACBullet code="cma-002">No attribute combination creates a non-executable state.</ZTACBullet>
                    <ZTACBullet code="cma-003">The attributes can be computationally applied to a role request and determine if access is granted to perform an action.</ZTACBullet>
                    <ZTACBullet code="cma-004">The attributes are expressed in a way that are machine-readable and can be applied to Business rules for policy enforcement (digital in cyberspace and non-digital in non-cyberspace [think SCIF access]).</ZTACBullet>
                    <ZTACBullet code="cma-005">The object attributes are sufficient and can be demonstrated to properly constrain operational execution.</ZTACBullet>
                    <ZTACBullet code="cma-006">The entirety of attributes cannot be composed to an unknown state.</ZTACBullet>
                    <ZTACBullet code="cma-007">All attributes of an object can be modeled for positive Human affirmation over time and change states can be reported.</ZTACBullet>
                </ul>
            </div>

            <br />

            <h2>B. Asset</h2>

            <div>
                <h3>Acceptance Criteria:</h3>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="acb-001">Each Asset has a fully defined characterization profile (attributes) that provides a clear and precise understanding of the constraints that restrict use during any operation.</ZTACBullet>
                </ul>
            </div>

            <p className="font-italic">The constraints are notionally administered using an automated business rule engine to determine if a leveraging the resource remains in compliance with policy and established constraints.</p>
            <p className="font-italic">For the purpose of this acceptance criteria, “asset” is typically identified as infrastructure elements that are not directly user addressable, such as routers, bridges, network pathways, backend processing systems, or other capabilities that perform an action based on a resource needs to service an Actor request. They can be as simple as a hub or as complex as a multi-tiered HPC backend and data store that processes complex analytics using hyperscale servicing technology.</p>
            <p className="font-italic">For example: if the assets is graded in such a way as it is restricted to material handling no higher than CUI, then one attribute that must be present is “material_handling_level” with a value set to “CUI” [e.g., material_handling_level:”CUI”].</p>
            <p className="font-italic">NOTE: material_handling_level is notional and a CCV would actually be used (or an LCV) or the local “Metadata Label”.</p>

            <div>
                <h3>Outcome:</h3>
                <p>The set of attributes for an asset can be explicitly and exhaustively mapped to any use case, vignette, day-in-the-Life thread or process model to regulate and arbitrate if constraint conditions are encountered while executing any action in the “operational” stream that is specified.</p>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="oub-001">Each of the “attributes” are clearly articulated.</ZTACBullet>
                    <ZTACBullet code="oub-002">Each attribute has the specified value for all known (and unknown) operating conditions.</ZTACBullet>
                    <ZTACBullet code="oub-003">A full map of the attributes and outcomes (requirements streams) can be generated to provide comprehensive insight to all acceptable operating conditions for the asset.</ZTACBullet>
                </ul>
            </div>

            <div>
                <h3>Conceptual Measures:</h3>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="cmb-001">The asset attributes are discrete and identifiable.</ZTACBullet>
                    <ZTACBullet code="cmb-002">A complete listing of all attributes that are applied to the asset are discoverable (if authorized).</ZTACBullet>
                    <ZTACBullet code="cmb-003">No attribute combination creates a non-executable state.</ZTACBullet>
                    <ZTACBullet code="cmb-004">The attributes can be computationally applied to a role request and determine if access is granted to perform an action.</ZTACBullet>
                    <ZTACBullet code="cmb-005">The attributes are expressed in a way that are machine-readable and can be applied to Business rules for policy enforcement (digital in cyberspace and non-digital in non-cyberspace [think SCIF access])..</ZTACBullet>
                    <ZTACBullet code="cmb-006">The asset attributes are sufficient and can be demonstrated to properly constrain operational execution.</ZTACBullet>
                    <ZTACBullet code="cmb-007">The entirety of attributes cannot be composed to an unknown state.</ZTACBullet>
                    <ZTACBullet code="cmb-008">All attributes of an asset can be modeled for positive human affirmation over time and change states can be reported.</ZTACBullet>
                </ul>
            </div>
        </>
    )
}

ZTAC5.UseCase = function ZTAC5_UseCase() {
    return (
        <div>
            <h3>Implementation Use Case: (example)</h3>
            <p>Scenario: Zero Trust Resource Characterization for an Object and an Asset:</p>

            <h4>Object Definition: Secure Document</h4>
            <ul>
                <li>
                    Define Object Attributes:
                    <ul>
                        <li>Collaborate with IT, security, and compliance teams to define the necessary identity, operational, behavioral, and supplemental attributes for the Secure Document.</li>
                    </ul>
                </li>
                <li>
                    Map Attributes to Use Cases:
                    <ul>
                        <li>Map each attribute of the Secure Document to the required use cases, ensuring all operational and exceptional conditions are covered.</li>
                    </ul>
                </li>
                <li>
                    Automate Business Rules:
                    <ul>
                        <li>Implement an automated business rule engine to enforce constraints based on the defined attributes and policies.</li>
                    </ul>
                </li>
                <li>
                    Generate Attribute Maps:
                    <ul>
                        <li>Use automated tools to generate a comprehensive map of attributes and outcomes, providing insight into all acceptable operating conditions for the object.</li>
                    </ul>
                </li>
                <li>
                    Policy Enforcement:
                    <ul>
                        <li>Implement policy enforcement mechanisms to ensure that access control and operational constraints are maintained according to the defined attributes and business rules.</li>
                    </ul>
                </li>
            </ul>

            <br />

            <h4>Asset Definition: Secure Server</h4>
            <ul>
                <li>
                    Define Asset Attributes:
                    <ul>
                        <li>Collaborate with IT, security, and compliance teams to define the necessary identity, operational, behavioral, and supplemental attributes for the Secure Server.</li>
                    </ul>
                </li>
                <li>
                    Map Attributes to Use Cases:
                    <ul>
                        <li>Map each attribute of the Secure Server to the required use cases, ensuring all operational and exceptional conditions are covered.</li>
                    </ul>
                </li>
                <li>
                    Automate Business Rules:
                    <ul>
                        <li>Implement an automated business rule engine to enforce constraints based on the defined attributes and policies.</li>
                    </ul>
                </li>
                <li>
                    Generate Attribute Maps:
                    <ul>
                        <li>Use automated tools to generate a comprehensive map of attributes and outcomes, providing insight into all acceptable operating conditions for the asset.</li>
                    </ul>
                </li>
                <li>
                    Policy Enforcement:
                    <ul>
                        <li>Implement policy enforcement mechanisms to ensure that access control and operational constraints are maintained according to the defined attributes and business rules.</li>
                    </ul>
                </li>
            </ul>
        </div>
    )
}

export default ZTAC5;