import { useState } from "react";
import {
  LinearProgress,
} from "@mui/material";
import { useShowLoader } from "../../hooks/util-hooks";
import { useZT } from "../../hooks/useZTfw";

import './ZTPage.css';

import ZTPillarSVG from "./ZTPillarSVG";
import ZTActivityPage from "./ZTAcitivtyPage";
import { Tooltip } from "primereact/tooltip";
import ZTActivityTable from "./ZTActivityTable";
import { useApp } from "../RealmApp";

export function ZTPage() {
  const { loading, apiError, ztfw, ...ztActions } = useZT();
  const { currentUser } = useApp();
  const showLoader = useShowLoader(loading, 200);
  const [selectedPillar, setSelectedPillar] = useState("1");
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [tooltipContent, setTooltip] = useState("...");

  const tooltip = {
    set: setTooltip,
  }

  const changeActivityById = (aId) => {
    const fndElement = ztfw.activities.find(obj => obj.aId === aId);
    if(!fndElement) return;
    setSelectedActivity(fndElement);
  }
  
  if(apiError) return<>Error loading backend server. Try reloading.</>

  return (
    <>
      {loading ? (
        showLoader ? (
          <LinearProgress />
        ) : null
      ) : (
        <div className="container-zt-page">
          <Tooltip target=".zt-tooltip" content={tooltipContent} showDelay={200} onHide={() => setTooltip("...")} autoHide={false} style={{ maxWidth: '600px' }} />
          <div className="ztpage-left">
            <ZTPillarSVG setPillar={setSelectedPillar} height="10.87rem" />
            <ZTActivityTable rows={ztfw.activities}
                     selectedPillar={selectedPillar}
                     selectedActivity={selectedActivity}
                     setSelectedActivity={setSelectedActivity}
                     ztActions={ztActions}
                     user={currentUser}
                     tooltip={tooltip} />
          </div>
          <div className="ztpage-right" style={{overflowY: 'auto'}}>
            <ZTActivityPage ztActions={ztActions}
                            selectedActivity={selectedActivity}
                            changeActivityById={changeActivityById}
                            user={currentUser} />
          </div>
        </div>
      )}
    </>
  );
}
