
import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'; 
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { Badge } from 'primereact/badge';
import { MenuItem } from 'primereact/menuitem';
import { classNames } from 'primereact/utils';
import { Avatar } from 'primereact/avatar';

const UserOptions = forwardRef((props, ref) => {
    const menuRef = useRef(null);

    useImperativeHandle(ref, () => ({
        openMenu(event:any) {
            // @ts-ignore
            menuRef.current.toggle(event);
        }
    }));

    const itemRenderer = (item:any) => (
        <div className='p-menuitem-content'>
            <a className="flex align-items-center p-menuitem-link">
                <span className={item.icon} />
                <span className="mx-2">{item.label}</span>
                {item.badge && <Badge className="ml-auto" value={item.badge} />}
                {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
            </a>
        </div>
    );
    let items: any = [
        {
            template: () => {
                return (
                    <span className="inline-flex align-items-center gap-1 px-2 py-2">
                        <img alt="logo" src={`${process.env.PUBLIC_URL}/imgs/app.ztkm-blk.svg`} height="40" className="mr-2" />
                        <span className="font-medium text-xl font-semibold">
                            ZTKM<span className="text-primary">APP</span>
                        </span>
                    </span>
                );
            }
        },
        {
            separator: true
        },
        {
            label: 'Documents',
            items: [
                {
                    label: 'New',
                    icon: 'pi pi-plus',
                    shortcut: '⌘+N',
                    template: itemRenderer
                },
                {
                    label: 'Search',
                    icon: 'pi pi-search',
                    shortcut: '⌘+S',
                    template: itemRenderer
                }
            ]
        },
        {
            label: 'Profile',
            items: [
                {
                    label: 'Settings',
                    icon: 'pi pi-cog',
                    shortcut: '⌘+O',
                    template: itemRenderer
                },
                {
                    label: 'Messages',
                    icon: 'pi pi-inbox',
                    badge: 2,
                    template: itemRenderer
                },
                {
                    label: 'Logout',
                    icon: 'pi pi-sign-out',
                    shortcut: '⌘+Q',
                    template: itemRenderer
                }
            ]
        },
        {
            separator: true
        },
        {
            command: () => {
                // toast.current?.show({ severity: 'info', summary: 'Info', detail: 'Item Selected', life: 3000 });
            },
            template: (item:any, options:any) => {
                return (
                    <button onClick={(e) => options.onClick(e)} className={classNames(options.className, 'w-full p-link flex align-items-center p-2 pl-4 text-color hover:surface-200 border-noround')}>
                        <Avatar label='U0' className="mr-2" shape="circle" />
                        <div className="flex flex-column align">
                            <span className="font-bold">User 01</span>
                            <span className="text-sm">User</span>
                        </div>
                    </button>
                );
            }
        }
    ]

    return (
        <div className="card flex justify-content-center">
            <Menu ref={menuRef} model={items} popup className="w-full md:w-15rem"  />
        </div>
    )
});

export default UserOptions;