
import React, { useState, useRef, useMemo } from 'react';
import { Steps } from 'primereact/steps';
import { Toast } from 'primereact/toast';
import { MenuItem } from 'primereact/menuitem';
import { Knob } from 'primereact/knob';

import './StepsSurveyNavigation.css';
import { ProgressBar } from 'primereact/progressbar';
import { Badge } from 'primereact/badge';
import { useApp } from '../RealmApp';
import { GZT } from '../DataManagement/DataManagement';
import { useZTACRootContext } from './SurveyToolACProvider';

type T_ZTACDataObject = {
    ztacId: string;
    maturity?: string;
    status: string;
}

const validResponses = ['Answered'];

const getScores = (analytics: any) => {
    let answeredItems = 0;

    analytics.forEach((obj: T_ZTACDataObject) => {
        if (validResponses.includes(obj.status)) answeredItems += 1;  // Skip if not answered
    })

    return {
        low: answeredItems,
        high: 10
    }
}

export default function StepsSurveyNavigation_AC({ activeIndex, setActiveIndex, style = {} }: any) {
    const { activeProject }: any = useApp();
    const { ztacAnalytics } = useZTACRootContext();

    const scores = useMemo(() => {
        return getScores(ztacAnalytics);
    }, [activeProject, ztacAnalytics])

    const homeRenderer = (item: any, itemIndex: number) => {
        const isActiveItem = activeIndex === itemIndex;
        const backgroundColor = isActiveItem ? 'var(--primary-color)' : 'var(--surface-b)';
        const textColor = isActiveItem ? 'var(--surface-b)' : 'var(--text-color-secondary)';

        return (
            <div className='p-steps-item'>
                <span
                    className="p-menuitem-link inline-flex align-items-center justify-content-center align-items-center border-circle border-primary border-1 z-1 cursor-pointer"
                    style={{ backgroundColor: backgroundColor, color: textColor, height: '40px', width: '40px' }}
                    onClick={() => setActiveIndex(itemIndex)}
                >
                    <i className={`${item.icon} text-xl`} />
                </span>
            </div>
        );
    };

    const iconRender = (item: any, itemIndex: number) => {
        const isActiveItem = activeIndex === itemIndex;
        const backgroundColor = isActiveItem ? 'var(--primary-color)' : 'var(--surface-b)';
        const textColor = isActiveItem ? 'var(--surface-b)' : 'var(--text-color-secondary)';

        return (
            <div className='p-steps-item'>
                <span className='p-menuitem-link inline-flex align-items-center justify-content-between align-items-center cursor-pointer'
                    onClick={() => setActiveIndex(itemIndex)}>
                    <span
                        className="inline-flex align-items-center justify-content-center align-items-center border-circle border-primary border-1 z-1"
                        style={{ backgroundColor: backgroundColor, color: textColor, height: '35px', width: '35px' }}
                    >
                        <i className={`${item.icon} text-xl`} />

                    </span>
                    <div className='p-steps-title m-0'>{item.label}</div>
                </span>
            </div>
        );
    };



    const itemRenderer = (item: any, itemIndex: number) => {
        const value = ((scores.low / scores.high) * 100).toFixed(0);

        const valueTemplate = () => {
            return (
                <div style={{ width: '0px', color: 'black', fontSize: 'smaller', position: 'absolute', top: 0, left: '0', marginLeft: '5px' }}>
                    {scores.low}/{scores.high}
                </div>
            );
        };

        return (

            <div
                className="card p-menuitem-link inline-flex flex-column align-items-center justify-content-between align-items-center cursor-pointer"
                style={{ height: '60px' }}
                onClick={() => setActiveIndex(itemIndex)}
            >
                {/* <Knob value={valRan[itemIndex - 1]} valueTemplate={'{value}%'} size={40} style={{ zIndex: 9, backgroundColor: 'white' }} readOnly /> */}
                <ProgressBar color='var(--color-badge-success)' value={value} displayValueTemplate={valueTemplate} style={{ width: '60px', marginTop: '3px' }} />
                <div className='p-steps-title m-0'>{item.label}</div>
            </div>

        )
    }

    const capabilityRenderer = (item: any, itemIndex: number) => {
        return (

            <div
                className="card p-menuitem-link inline-flex flex-column align-items-center justify-content-between align-items-center cursor-pointer"
                style={{ height: '60px' }}
                onClick={() => setActiveIndex(itemIndex)}
            >
                {/* <Knob value={valRan[itemIndex - 1]} valueTemplate={'{value}%'} size={40} style={{ zIndex: 9, backgroundColor: 'white' }} readOnly /> */}
                {/* <ProgressBar color='var(--color-badge-success)' value={valRan[itemIndex -1][0]} displayValueTemplate={valueTemplate} style={{width: '60px', marginTop: '3px'}}/> */}
                <Badge value={activeProject?.ztCapabilities?.length || 0} size="large" severity="info" style={{ zIndex: 9 }}></Badge>
                <div className='p-steps-title m-0'>{item.label}</div>
            </div>

        )
    }

    const items: MenuItem[] = [
        {
            label: 'Home',
            icon: 'pi pi-home',
            template: (item) => homeRenderer(item, 0)
        },
        {
            label: '1: Capabilities',
            template: (item) => capabilityRenderer(item, 1)
        },
        {
            label: '2: Criteria',
            template: (item) => itemRenderer(item, 2)
        },
        {
            label: 'Reports',
            icon: 'pi pi-chart-bar',
            template: (item) => iconRender(item, 3)
        }
    ];

    return (
        <div className="card" style={{ padding: '0.5rem 0rem', ...style }}>
            <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} />
        </div>
    )
}
