

export default function TBCS(){


    return(
        <>
            MITRE ATT&CK and D3FEND mappings.
        </>
    )
}