import { useEffect, useReducer, useState } from "react"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, LinearProgress } from '@mui/material';
import { Button } from "primereact/button";
import './ZTActivityPage.css';
import { InputTextarea } from "primereact/inputtextarea";
import activityReducer, { T_ActivityReducer, emptyActivtyReducer, nistControlsSortGroup, simpleMarkupToArray } from "./reducerZTActivity";
import { GZT } from "../DataManagement/DataManagement";
import { Dialog } from "primereact/dialog";
import { EnhancementElement, NistButton, NistGroup, NISTPanel, PVButton, T_PVObject } from "./Nist";
import { Panel } from "primereact/panel";
import { ZTMapPredSucc } from "../ZTMapping/ZTMapping";
import { IconLevelBody, T_ZTActivityTable } from "./ZTActivityTable";
import { isRegisteredUser } from "../utils/userUtils";
import ReactMarkdown from "react-markdown";
import { useApp } from "../RealmApp";
import { useShowLoader, useTimeout } from "../../hooks/util-hooks";

const markdown = `
Knowing what is in an organization's environment is a foundation to establishing trust in the environment. A device inventory lists what devices are known and expected in the environment. The device inventory can then be used as the basis for starting to establish trust in a device. 

A device inventory must capture device existence, usage, and risks. All devices that communicate in an environment require a unique identity and authentication as non-person entities (NPE). Device usage can vary examples include devices leveraging session access protocols, resource devices hosting or providing network for applications, or devices running embedded services. Enterprises must understand their use of devices and that there is a difference in the way these devices present cybersecurity risks. 

The first step to securing the device pillar in a “deny all” by default environment is done by establishing a complete inventory of registered devices that are allowed to access enterprise resources. In some cases, inventory solutions can collect hardware and software information, including versions, patch levels, and installed applications, which are important in establishing security baselines, application allowlisting, and situational awareness across all inventoried devices. Dynamic inventories may include both managed and unmanaged devices that have been granted authorized access to enterprise resources. As maturity increases, dynamic inventories are updated in real time. 

Devices may be added or removed from an inventory over time. The action of modifying an inventory requires establishing enterprise policies governing:
- **Procurement:** Identify criteria governing device purchases. Device Authorization discussed later in this document may involve the need for specific Trusted Platform Module (TPM) certificates, firmware configuration, or component part revisions. Vendors may list multiple variants or configurations of the same device, but only some may have the necessary components and capabilities. 
- **Acceptance Testing:** NIST SP 800-161 calls for enterprises to adopt acceptance testing as a mechanism to audit supply chain integrity. Software Bill of Materials (SBOM), Reference Integrity Manifest (RIM), and TPM Platform Certificate provide artifacts that establish an auditable chain of custody from the production factory to the receiving organization.
- **Deprovisioning: Devices may store protected data within components other than the storage drive. Plan to securely erase storage media, factory reset firmware, securely erase TPM NVRAM memory, reset Baseboard Management Controller (BMC) configurations, remove UEFI Secure Boot modifications, and clean up other organization-specific customizations before retiring a device. Inventory should support status records necessary to ensure safe and secure deprovisioning.
`

export type T_ZTActivityElement = {
    aId: string;
    cId: string;
    type: string;
    associatedCapability: string;
    phase: number;
    isMPEBase: boolean;
    name: string;
    nist: string[];
    level: "advanced" | "target";
    pillar: "1" | "2" | "3" | "4" | "5" | "6" | "7";
    m: number;
    description: string;
    outcomes: string[];
    mpeOutcomes: string[];
    assessmentPlan: string[];
    predecessor: string[];
    successor: string[];
} | null;

// type T_ActivityMode = 'read' | 'edit';

function checkUpdateActivity(oldData: T_ZTActivityElement, newData: T_ActivityReducer) {
    let update: any = {};
    // Check mpeOutcomes
    const oldOutcomes = JSON.stringify(oldData?.mpeOutcomes || []);
    const newOutcomes = JSON.stringify(simpleMarkupToArray(newData?.mpeOutcomes || ''));
    if (oldOutcomes !== newOutcomes) update.mpeOutcomes = JSON.parse(newOutcomes);

    // Check Assessment Plan
    const oldPlan = JSON.stringify(oldData?.assessmentPlan || []);
    const newPlan = JSON.stringify(simpleMarkupToArray(newData?.assessmentPlan || ''));
    if (oldPlan !== newPlan) update.assessmentPlan = JSON.parse(newPlan);

    return update;
}

export default function ZTActivityPage({ selectedActivity, ztActions, changeActivityById, user }: any) {
    const app: any = useApp();
    const [content, setContent] = useState<T_ZTActivityElement>(null);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [aState, aDispatch] = useReducer(activityReducer, emptyActivtyReducer);
    const currentActivity = selectedActivity?.aId || null;
    const isUser = isRegisteredUser(user) || app?.isLocalApp;

    useEffect(() => {
        if (!currentActivity) return;
        initActivityData();
    }, [currentActivity])

    useEffect(() => {
        if (isEdit) return;
        const updateValues = checkUpdateActivity(content, aState);
        if (Object.keys(updateValues).length === 0) return; //Nothing to update
        ztActions.updateActivity({ aId: currentActivity, ...updateValues }).then((res: any) => initActivityData());
    }, [isEdit])

    const initActivityData = () => {
        ztActions.getActivity(currentActivity, isUser).then((data: any) => {
            setContent(data[0]);
            aDispatch({ 'type': 'init', payload: data[0], activity: currentActivity });
        });
    }

    const getCapabilityName = (cId: string) => {
        const obj: any = GZT?.capabilities.find((item: any) => item.cId === cId);
        if (!obj) return "Unknown";
        return obj.name;
    }

    const checkDisplayClass = () => {
        if (!aState.displaySplit && aState.showNist) return 'hide';
        return 'show';
    }

    if (!content) return <></>;

    const ActivityHeader: any = () => {
        return (
            <>
                <div className="flex flex-row justify-content-center align-items-center gap-2">
                    <Button icon='pi pi-times' size="small" outlined severity="secondary" onClick={() => setContent(null)}/>
                    <div>
                        {content.aId} {content.name}
                        <div style={{ textTransform: 'uppercase', fontStyle: 'italic', fontWeight: 'initial', fontSize: 'small' }}>{getCapabilityName(content.cId)}</div>
                    </div>
                </div>
                {IconLevelBody(content)}
            </>
        )
    }

    

    return (
        <>
            {/* <ReactMarkdown children={markdown} /> */}
            <div className={`activity-container ${(aState.displaySplit) ? 'split' : ''}`}>
                <Panel header={ActivityHeader} className={`activity-main ${checkDisplayClass()}`}>
                    <div className="grid">
                        <div className="col-12">
                            <ZTMapPredSucc data={content} changeActivityById={changeActivityById} />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <div className="act-header">DoD Descriptions:</div>
                            <span>{content.description}</span>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <div className="act-header">NIST Controls:</div>
                            {content.nist.length === 0 ? 'None' :
                                <div className="nist-groupings-container">
                                    {nistControlsSortGroup(content.nist).map((nistGroup: any, index:number) => (
                                        <NistGroup key={`ng-${index}-${nistGroup}-${aState.currentActivity}`} nistGroup={nistGroup} aDispatch={aDispatch} content={content} />
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <div className="act-header">DoD Outcomes:</div>
                            <ol>
                                {content.outcomes.map((outcome, i) => (
                                    <li key={content.aId + 'eo' + i}>{outcome}</li>
                                ))}
                            </ol>
                        </div>
                    </div>
                    {isUser && <>
                        <div className="grid">
                            <div className="col-12">
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className="act-header">MPE Outcome:</div>
                                    <Button severity={isEdit ? "success" : "secondary"}
                                        outlined
                                        size="small"
                                        icon={`pi pi-${isEdit ? 'save' : 'pen-to-square'}`}
                                        className="btn-activity-edit"
                                        onClick={() => setIsEdit(prev => !prev)} />
                                </div>
                                {!isEdit &&
                                    <ol>
                                        {content?.mpeOutcomes && content.mpeOutcomes.map((outcome, i) => (
                                            <li key={content.aId + 'mpeo' + i}>{outcome}</li>
                                        ))}
                                    </ol>}
                                {isEdit &&
                                    <InputTextarea value={aState.mpeOutcomes}
                                        autoResize
                                        style={{ width: '100%' }}
                                        onChange={(e) => aDispatch({ type: 'update', field: 'mpeOutcomes', payload: e.target.value })} />}
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-12">
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className="act-header">Assessment Plan:</div>
                                    <Button severity={isEdit ? "success" : "secondary"}
                                        outlined
                                        size="small"
                                        icon={`pi pi-${isEdit ? 'save' : 'pen-to-square'}`}
                                        className="btn-activity-edit"
                                        onClick={() => setIsEdit(prev => !prev)} />
                                </div>
                                {!isEdit &&
                                    <ol>
                                        {content?.assessmentPlan && content.assessmentPlan.map((outcome, i) => (
                                            <li key={content.aId + 'apeo' + i}>{outcome}</li>
                                        ))}
                                    </ol>}
                                {isEdit &&
                                    <InputTextarea value={aState.assessmentPlan}
                                        autoResize
                                        style={{ width: '100%' }}
                                        onChange={(e) => aDispatch({ type: 'update', field: 'assessmentPlan', payload: e.target.value })} />}
                            </div>
                        </div>
                    </>
                    }
                </Panel>
                {aState.showNist &&
                    <NISTPanel selectedNIST={aState.selectedNIST} pvSelected={aState.pvSelected} onExit={() => aDispatch({ type: 'resetDisplay' })}/>
                }
            </div>
            {/* <Dialog onHide={() => {}} onShow={() => {}} visible={true}>
                <p>NIST!</p>
            </Dialog> */}
        </>
    )
}