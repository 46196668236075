import ACStatus from "./ACStatusButton";
import { CodeBlock, useZTACContext, ZTACBullet } from "./ZTAC_Component";



const ZTAC7 = () => {
    return (
        <></>
    )
};

ZTAC7.Title = function ZTAC1_Title() {
    return (
        <>
            <div className="ztac-title">7.0: Is the orchestration of IBAC/RBAC/ABAC and rules computationally consistent, coherent, complete, congruent and validated?</div>
            {/* <div className="ztac-subtitle">None</div> */}
        </>
    )
}

ZTAC7.Intro = function ZTAC7_Intro() {
    return (
        <>
            <div>
                <h3>Acceptance Criteria:</h3>
                <p>All elements of the policy are completely described using logic rules that consume the attributes from IBAC/RBAC/ABAC. The computational outcome of a logic rule is:</p>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="ac-001">Consistent regardless of the attribute name and associated value – the computed result is always the same.</ZTACBullet>
                    <ZTACBullet code="ac-002">Has one and only one interpretation and is understood by all consumers regardless of their specific domain or functional area.</ZTACBullet>
                    <ZTACBullet code="ac-003">Expressed so that all elements of the rule are present and there is no additional attributes or logic elements that can be added or subtracted.</ZTACBullet>
                    <ZTACBullet code="ac-004">Produces a binary disposition (1 or 0, Yes or No, Grant or Deny, etc.).</ZTACBullet>
                    <ZTACBullet code="ac-005">Non-competitive with any other rule. There is no situation where this rule can modify the meaning or outcome of another rule. However, other rules can be “superior” and overrule what this rule is intended to achieve. For example, if a superior rule states that all rules are suspended for a period of time, it does not alter any other rule, it simply nullifies the use of the other rules for that period of time.</ZTACBullet>
                    <ZTACBullet code="ac-006">Pre-computed and produces the exact and explicit outcome intended.</ZTACBullet>
                </ul>
            </div>

            <div>
                <h3>Outcome:</h3>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="ou-001">The business rules and associated attributes (and values) are fully mapped and have a pre-computed inviolate disposition.</ZTACBullet>
                    <ZTACBullet code="ou-002">All pre-computed outcomes are generated and validated as the desired behavioral intent.</ZTACBullet>
                    <ZTACBullet code="ou-003">The IBAC/RBAC/ABAC attribute labels are aligned regardless of owner control - the name of the attribute is not a critical consideration, as long as the local computed outcome is identical.</ZTACBullet>
                    <ZTACBullet code="ou-004">The result of rule enforcement is identical, regardless of operating domain.</ZTACBullet>
                    <ZTACBullet code="ou-005">The rules and attributes are machine readable and system agnostic.</ZTACBullet>
                </ul>
            </div>

            <div>
                <h3>Conceptual Measures:</h3>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="cm-001">All rule-attribute combinations are distinct and addressable.</ZTACBullet>
                    <ZTACBullet code="cm-002">A complete listing of all attributes and the associated allowed values are discoverable (if authorized).</ZTACBullet>
                    <ZTACBullet code="cm-003">No attribute combination creates a non-executable state.</ZTACBullet>
                    <ZTACBullet code="cm-004">The rule-attributes can be computationally pre-computed.</ZTACBullet>
                    <ZTACBullet code="cm-005">The rules are expressed in a way that are machine-readable and can be applied to Business rules for policy enforcement (digital in cyberspace and non-digital in non-cyberspace [think SCIF access]).</ZTACBullet>
                    <ZTACBullet code="cm-006">The attributes are expressed in a way that are machine-readable and can be applied to Business rules for policy enforcement.</ZTACBullet>
                    <ZTACBullet code="cm-007">Rule execution does not deviate for the stipulated constraints of operational execution.</ZTACBullet>
                    <ZTACBullet code="cm-008">The rules can only resolve to a single outcome.</ZTACBullet>
                    <ZTACBullet code="cm-009">All rule-attributes can be modeled for positive human affirmation over time and change states can be reported.</ZTACBullet>
                </ul>
            </div>
        </>
    )
}

ZTAC7.UseCase = function ZTAC7_UseCase() {
    return (
        <div>
            <h3>Implementation Use Case: (example)</h3>
            <p>Zero Trust Orchestration of IBAC/RBAC/ABAC for Access Control in OCFO</p>
            <p>Scenario: Only authorized financial analysts can access financial data during business hours from secure locations using compliant devices.</p>

            <ul>
                <li>
                    Define Policy Attributes:
                    <ul>
                        <li>Collaborate with Department/Division supervisor, IT, and security teams to define the necessary IBAC, RBAC, and ABAC attributes for the Access Control Policy.</li>
                    </ul>
                </li>
                <li>
                    Develop Logic Rules:
                    <ul>
                        <li>Develop logic rules that describe the policy in terms of attributes and allowed values. For example:</li>
                        <CodeBlock style={{ margin: '1rem' }}>
                            IF User Role = 'Financial Analyst' AND <br />
                            Clearance Level = 'Confidential' AND <br />
                            Access Time = 'Business Hours' AND <br />
                            Access Location = 'Secure Office Network' AND <br />
                            Device Compliance = 'Up-to-date Security Patches' AND <br />
                            Device Type = 'Company-issued Laptop' THEN <br />
                            Access = 'Granted' ELSE <br />
                            Access = 'Denied'
                        </CodeBlock>
                    </ul>
                </li>
                <li>
                    Automate Business Rules:
                    <ul>
                        <li>Implement an automated business rule engine to enforce the logic rules based on the defined attributes and policies.</li>
                    </ul>
                </li>
                <li>
                    Generate Attribute Maps:
                    <ul>
                        <li>Use automated tools to generate a comprehensive map of attributes and outcomes, providing insight into all acceptable operating conditions for the policy.</li>
                    </ul>
                </li>
                <li>
                    Policy Enforcement:
                    <ul>
                        <li>Implement policy enforcement mechanisms to ensure that access control is maintained according to the defined attributes and business rules.</li>
                    </ul>
                </li>
                <li>
                    Monitor and Report:
                    <ul>
                        <li>Continuously monitor policy attributes and report any changes or anomalies to ensure ongoing compliance and security.</li>
                    </ul>
                </li>
                <li>
                    Example Logic Statements:
                    <ul>
                        <li>Access Granted:</li>
                        <CodeBlock style={{ margin: '1rem' }}>
                            IF User Role = 'Financial Analyst' AND <br />
                            Clearance Level = 'Confidential'AND <br />
                            Access Time = 'Business Hours' AND <br />
                            Access Location = 'Secure Office Network' AND <br />
                            Device Compliance = 'Up-to-date Security Patches'AND <br />
                            Device Type = 'Company-issued Laptop' THEN <br />
                            Access = 'Granted'
                        </CodeBlock>
                        <li>Access Denied (Outside Business Hours):</li>
                        <CodeBlock style={{ margin: '1rem' }}>
                            IF User Role = 'Financial Analyst' AND <br />
                            Clearance Level = 'Confidential' AND <br />
                            Access Time != 'Business Hours' THEN <br />
                            Access = 'Denied'
                        </CodeBlock>
                        <li>Access Denied (Non-Compliant Device):</li>
                        <CodeBlock style={{ margin: '1rem' }}>
                            IF User Role = 'Financial Analyst' AND <br />
                            Clearance Level = 'Confidential' AND <br />
                            Device Compliance != 'Up-to-date Security Patches' THEN <br />
                            Access = 'Denied'
                        </CodeBlock>
                    </ul>
                </li>
            </ul>

            <h3>Validation and Testing:</h3>

            <ul>
                <li>
                    Pre-compute Outcomes:
                    <ul>
                        <li>Pre-compute all possible outcomes of the logic rules to ensure they produce the exact and explicit outcome intended.</li>
                    </ul>
                </li>
                <li>
                    Validate Behavioral Intent:
                    <ul>
                        <li>Validate that all pre-computed outcomes align with the desired behavioral intent of the policy.</li>
                    </ul>
                </li>
                <li>
                    Consistency Across Domains:
                    <ul>
                        <li>Ensure that the IBAC/RBAC/ABAC attribute labels are aligned and that the result of rule enforcement is identical across different operating domains.</li>
                    </ul>
                </li>
                <li>
                    Machine-Readable and System-Agnostic:
                    <ul>
                        <li>Confirm that the rules and attributes are machine-readable and system-agnostic, allowing for seamless integration and enforcement across various systems and platforms. By following these steps, the financial institution can ensure that the orchestration of IBAC/RBAC/ABAC and rules is computationally consistent, coherent, complete, congruent, and validated. This ensures that access control policies are tightly controlled and monitored to prevent unauthorized access and potential security breaches, while also providing comprehensive insight into their operational constraints and conditions.</li>
                    </ul>
                </li>
            </ul>
        </div>
    )
}

export default ZTAC7;