import { useState } from "react";
import ZTAC1 from "../AcceptanceCriteria/ZTAC1";
import ZTAC2 from "../AcceptanceCriteria/ZTAC2";
import ZTAC_Component from "../AcceptanceCriteria/ZTAC_Component";
import ZTAC3 from "../AcceptanceCriteria/ZTAC3";
import ZTAC4 from "../AcceptanceCriteria/ZTAC4";
import ZTAC5 from "../AcceptanceCriteria/ZTAC5";
import ZTAC6 from "../AcceptanceCriteria/ZTAC6";
import ZTAC7 from "../AcceptanceCriteria/ZTAC7";
import ZTAC8 from "../AcceptanceCriteria/ZTAC8";
import ZTAC9 from "../AcceptanceCriteria/ZTAC9";
import ZTAC10 from "../AcceptanceCriteria/ZTAC10";

type T_ACSurveyCriteriaProps = {
    setACId: (acId: string) => void;
}

const ACSurveyCriteria = ({ setACId }: T_ACSurveyCriteriaProps) => {

    return (
        <>
            <ZTAC_Component acId="1.0" onClick={setACId}>
                <ZTAC1.Title />
                <ZTAC1.Intro />
                <ZTAC1.UseCase />
            </ZTAC_Component>
            <ZTAC_Component acId="2.0" onClick={setACId}>
                <ZTAC2.Title />
                <ZTAC2.Intro />
                <ZTAC2.UseCase />
            </ZTAC_Component>
            <ZTAC_Component acId="3.0" onClick={setACId}>
                <ZTAC3.Title />
                <ZTAC3.Intro />
                <ZTAC3.UseCase />
            </ZTAC_Component>
            <ZTAC_Component acId="4.0" onClick={setACId}>
                <ZTAC4.Title />
                <ZTAC4.Intro />
                <ZTAC4.UseCase />
            </ZTAC_Component>
            <ZTAC_Component acId="5.0" onClick={setACId}>
                <ZTAC5.Title />
                <ZTAC5.Intro />
                <ZTAC5.UseCase />
            </ZTAC_Component>
            <ZTAC_Component acId="6.0" onClick={setACId}>
                <ZTAC6.Title />
                <ZTAC6.Intro />
                <ZTAC6.UseCase />
            </ZTAC_Component>
            <ZTAC_Component acId="7.0" onClick={setACId}>
                <ZTAC7.Title />
                <ZTAC7.Intro />
                <ZTAC7.UseCase />
            </ZTAC_Component>
            <ZTAC_Component acId="8.0" onClick={setACId}>
                <ZTAC8.Title />
                <ZTAC8.Intro />
                <ZTAC8.UseCase />
            </ZTAC_Component>
            <ZTAC_Component acId="9.0" onClick={setACId}>
                <ZTAC9.Title />
                <ZTAC9.Intro />
                <ZTAC9.UseCase />
            </ZTAC_Component>
            <ZTAC_Component acId="10.0" onClick={setACId}>
                <ZTAC10.Title />
                <ZTAC10.Intro />
                <ZTAC10.UseCase />
            </ZTAC_Component>
        </>
    )
}

export default ACSurveyCriteria;