import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';

import './SurveyDropdownOptions.css';
import { RiSurveyLine } from 'react-icons/ri';

// Define the type for options in the dropdown
interface DropdownOption {
    id: string;
    label: string;
    route: string;
}

const SurveyDropdownOptions: React.FC = () => {
    // Options array with defined types
    const options: DropdownOption[] = [
        { id: 'option1', label: 'ZT Activities', route: '/survey/zt' },
        { id: 'option2', label: 'Acceptance Criteria', route: '/survey/ac' },
    ];

    // State to store selected option's ID, defaulting to empty string initially
    const [selectedOption, setSelectedOption] = useState<string>(localStorage.getItem('selectedOption') || options[0].id);

    useEffect(() => {
        // Check localStorage for previously selected option
        const savedOption = localStorage.getItem('selectedOption');

        if (savedOption) {
            setSelectedOption(savedOption);
        } else {
            // Default to first option if no selection is stored in localStorage
            setSelectedOption(options[0].id);
        }
    }, [options]);

    // Handle button click (you can expand this logic if needed)
    const handleClick = () => {
        const savedOption = localStorage.getItem('selectedOption') || options[0].id;
        setSelectedOption(savedOption);
    };

    // Handle selection of a new option
    const handleOptionSelect = (optionId: string) => {
        setSelectedOption(optionId);
        localStorage.setItem('selectedOption', optionId);
    };

    // Get the route of the selected option
    const selectedRoute: string = options.find(option => option.id === selectedOption)?.route || options[0].route;

    return (
        <div className='flex flex-row'>
            <NavLink to={selectedRoute} onClick={handleClick} className='navApp-item'>
                <RiSurveyLine className='navItem-icon' />
                <div className='navItem-text'>Survey</div>
            </NavLink>
            <i className='ztkm-dropdown pi pi-chevron-down align-self-end' style={{ marginLeft: '0.25rem' }}>
                <div className="ztkm-dropdown-content">
                    {options.map((option) => (
                        <Link
                            key={option.id}
                            to={option.route}
                            onClick={() => handleOptionSelect(option.id)}
                            className={option.id === selectedOption ? 'active' : ''}
                        >
                            {option.label}
                        </Link>
                    ))}
                </div>
            </i>
        </div>
    );
};

export default SurveyDropdownOptions;
