
export const q_getCapabilityDesc = (cId) => [
  { $match: { type: 'capability', cId: cId } },
  { $project: { _id: 0, description: 1, } }
]

export const q_getCapability = (cId) => [
  { $match: { type: 'capability', cId: cId } },
  { $project: { _id: 0, successor: 0, predecessor: 0 } }
]

export const q_getActivity = (aId, isUser = false) => {
  const userQuery = (!isUser) ? [] :
    [{
      $lookup:
      {
        from: "zt-mpe-plan",
        localField: "aId",
        foreignField: "aId",
        as: "extended"
      }
    },
    { $unwind: { path: "$extended", preserveNullAndEmptyArrays: true } },
    {
      $replaceRoot:
      {
        newRoot: {
          $mergeObjects: ["$extended", "$$ROOT"]
        }
      }
    }]

  return [
    { $match: { aId: aId } },
    ...userQuery,
    {
      $lookup:
      {
        from: "overlay-mapping",
        localField: "aId",
        foreignField: "ztId",
        as: "nist"
      }
    },
    {
      $lookup: {
        from: "zt-survey",
        localField: "aId",
        foreignField: "ztId",
        as: "survey"
      }
    },
    {
      $addFields: {
        nist: {
          $map: {
            input: "$nist",
            as: "item",
            in: {
              cId: "$$item.cId",
              pv: {
                $cond: {
                  if: { $eq: ["$$item.pv", ""] },
                  then: false,
                  else: true
                }
              }
            }
          }
        },
        survey: { $arrayElemAt: ["$survey", 0] }
      }
    },
    { $project: { extended: 0, _id: 0, "survey._id": 0, "survey.ztId": 0 } }
  ]
}

export const q_getZTInitial = [
  {
    $facet: {
      pillars: [
        { $match: { type: 'pillar' } },
        {
          $project: {
            _id: 0,
            name: 1,
            pillarNum: 1
          }
        },
        { $sort: { pillarNum: 1 } }
      ],
      capabilities: [
        { $match: { type: 'capability' } },
        {
          $project: { _id: 0, name: 1, cId: 1 }
        },
        { $sort: { cId: 1 } }
      ],
      activities: [
        { $match: { type: 'activity' } },
        {
          $project: {
            _id: 0,
            name: 1,
            aId: 1,
            cId: 1,
            pillar: 1,
            isMPEBase: 1,
            level: 1,
            phase: 1,
          }
        },
        { $sort: { aId: 1 } }
      ]
    }
  },
  {
    $project: {
      pillars: 1,
      capabilities: 1,
      activities: {
        $map: {
          input: '$activities',
          as: 'activity',
          in: {
            name: '$$activity.name',
            aId: '$$activity.aId',
            cId: '$$activity.cId',
            pillar: '$$activity.pillar',
            isMPEBase: '$$activity.isMPEBase',
            level: '$$activity.level',
            phase: '$$activity.phase',
            capName: {
              $arrayElemAt: [
                {
                  $map: {
                    input: {
                      $filter: {
                        input: '$capabilities',
                        as: 'cap',
                        cond: {
                          $eq: [
                            '$$cap.cId',
                            '$$activity.cId'
                          ]
                        }
                      }
                    },
                    as: 'filteredCap',
                    in: '$$filteredCap.name'
                  }
                },
                0
              ]
            }
          }
        }
      }
    }
  }
]

export const q_getActivityMapping = [
  { $match: { type: 'activity' } },
  { $project: { _id: 0, aId: 1, m: 1, predecessor: 1, successor: 1 } }
]

export const q_getNistFamily = [
  { $match: { type: 'family' } },
  { $project: { _id: 0, type: 0 } }
]

export const q_getNISTNamesByIds = (cIds) => {
  return [
    {
      $match: {
        type: 'control',
        cId: {
          $in: cIds
        }
      }
    },
    {
      $project: {
        _id: 0,
        name: 1,
        cId: 1,
      }
    }
  ]
}

export const q_getNIST = (cId) => {
  const fId = cId.substring(0, 2);

  return [
    { $match: { cId: cId } },
    {
      $lookup: {
        from: "nist800-53-controls",
        localField: "cId",
        foreignField: "control",
        as: "enhance"
      }
    },
    {
      $lookup: {
        from: "overlays",
        localField: "cId",
        foreignField: "cId",
        as: "overlays"
      }
    },
    {
      $unionWith: {
        coll: "nist800-53-controls",
        pipeline: [
          { $match: { type: "family", fId: fId } },
          { $project: { fName: "$name", _id: 0 } }
        ]
      }
    },
    {
      $group: {
        _id: null,
        combined: { $mergeObjects: "$$ROOT" }
      }
    },
    { $replaceRoot: { newRoot: "$combined" } },
    {
      $addFields: {
        enhancements: {
          $map: {
            input: "$enhance",
            as: "enhance",
            in: {
              name: "$$enhance.name",
              cId: "$$enhance.cId"
            }
          }
        }
      }
    },
    {
      $project: {
        _id: 0,
        enhance: 0,
        "overlays._id" : 0,
        "overlays.justification" : 0,
        "overlays.regulation" : 0,
        "overlays.cId" : 0,
        "overlays.supplemental" : 0,
      }
    }
  ]
}