import ACStatus from "./ACStatusButton";
import { CodeBlock, useZTACContext, ZTACBullet } from "./ZTAC_Component";



const ZTAC6 = () => {
    return (
        <></>
    )
};

ZTAC6.Title = function ZTAC1_Title() {
    return (
        <>
            <div className="ztac-title">6.0: Is Policy sufficiently specified to dictate prescriptive actions?</div>
            <div className="ztac-subtitle">rules</div>
        </>
    )
}

ZTAC6.Intro = function ZTAC6_Intro() {
    return (
        <>
            <div>
                <h3>Acceptance Criteria:</h3>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="ac-001">All elements of the policy are completely described by one or more logic statements that have a deterministic outcomes based on all combinations of all attributes and all allowed values.</ZTACBullet>
                </ul>
            </div>

            <p className="font-italic">The approach to satisfying this acceptance criteria may have multiple parts, steps and build phases to establish the final logic rules sets to assure the policy is completely and exhaustively covered. While it is acceptable to only specify the final disposition of the policy described in logic statements, the buildup and evolution of the logic statements is often helpful to ensure a full understanding of the designed outcome.</p>

            <div>
                <h3>Outcome:</h3>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="ou-001">Policy exists.</ZTACBullet>
                    <ZTACBullet code="ou-002">All policy statements have been modeled using attributes.</ZTACBullet>
                    <ZTACBullet code="ou-003">The logic statements are implementation independent.</ZTACBullet>
                    <ZTACBullet code="ou-004">All logic statements of the policy have been fully exercised and achieve the desired behavior based on specific discrete attribute value combinations.</ZTACBullet>
                    <ZTACBullet code="ou-005">All logic statements have been designed as digital rule that has one or more identified business rule engines that is able to execute the “implementation” of the logic statement.</ZTACBullet>
                    <ZTACBullet code="ou-006">Each digital rule is crafted using explicitly identified attributes and the associated attribute value, and results in a repeatable pre-computed outcome.</ZTACBullet>
                </ul>
            </div>

            <div>
                <h3>Conceptual Measures:</h3>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="cm-001">All rules are distinct and addressable.</ZTACBullet>
                    <ZTACBullet code="cm-002">Each rule only uses well defined attribute and allowed values.</ZTACBullet>
                    <ZTACBullet code="cm-003">A complete listing of all attributes and the associated allowed values are discoverable (if authorized).</ZTACBullet>
                    <ZTACBullet code="cm-004">No attribute combination creates a non-executable state.</ZTACBullet>
                    <ZTACBullet code="cm-005">The rule-attributes can be computationally pre-computed.</ZTACBullet>
                    <ZTACBullet code="cm-006">The rules are expressed in a way that are machine-readable and can be applied to Business rules for policy enforcement (digital in cyberspace and non-digital in non-cyberspace [think SCIF access]).</ZTACBullet>
                    <ZTACBullet code="cm-007">The attributes are expressed in a way that are machine-readable and can be applied to Business rules for policy enforcement.</ZTACBullet>
                    <ZTACBullet code="cm-008">Rule execution does not deviate for the stipulated constraints of operational execution.</ZTACBullet>
                    <ZTACBullet code="cm-009">The rules have complete coverage of the policy.</ZTACBullet>
                    <ZTACBullet code="cm-010">All rule-attributes can be modeled for positive human affirmation over time and change states can be reported.</ZTACBullet>
                </ul>
            </div>
        </>
    )
}

ZTAC6.UseCase = function ZTAC6_UseCase() {
    return (
        <div>
            <h3>Implementation Use Case: (example)</h3>
            <p>Scenario: Only authorized financial analysts can access financial data during business hours from secure locations using compliant devices.</p>

            <ul>
                <li>
                    Define Policy Attributes:
                    <ul>
                        <li>Collaborate with Department/Division supervisors, IT, and security teams to define the necessary user, behavioral, and device attributes for the Access Control Policy.</li>
                    </ul>
                </li>
                <li>
                    Develop Logic Statements:
                    <ul>
                        <li>Develop logic statements that describe the policy in terms of attributes and allowed values. For example:</li>
                        <CodeBlock style={{ margin: '1rem' }}>
                            IF Role = 'Financial Analyst' AND <br />
                            Clearance Level = 'Confidential' AND <br />
                            Access Time = 'Business Hours' AND <br />
                            Access Location = 'Secure Office Network' AND <br />
                            Device Compliance = 'Up-to-date Security Patches' AND <br />
                            Device Type = 'Company-issued Laptop' THEN <br />
                            Access = 'Granted' ELSE <br />
                            Access = 'Denied'
                        </CodeBlock>
                    </ul>
                </li>
                <li>
                    Automate Business Rules:
                    <ul>
                        <li>Implement an automated business rule engine to enforce the logic statements based on the defined attributes and policies.</li>
                    </ul>
                </li>
                <li>
                    Generate Attribute Maps:
                    <ul>
                        <li>Use automated tools to generate a comprehensive map of attributes and outcomes, providing insight into all acceptable operating conditions for the policy.</li>
                    </ul>
                </li>
                <li>
                    Policy Enforcement:
                    <ul>
                        <li>Implement policy enforcement mechanisms to ensure that access control is maintained according to the defined attributes and business rules.</li>
                    </ul>
                </li>
                <li>
                    Monitor and Report:
                    <ul>
                        <li>Continuously monitor policy attributes and report any changes or anomalies to ensure ongoing compliance and security.</li>
                    </ul>
                </li>
                <li>
                    Example Logic Statements:
                    <ul>
                        <li>Access Granted:</li>
                        <CodeBlock style={{ margin: '1rem' }}>
                            IF Role = 'Financial Analyst' AND <br />
                            Clearance Level = 'Confidential'AND <br />
                            Access Time = 'Business Hours' AND <br />
                            Access Location = 'Secure Office Network' AND <br />
                            Device Compliance = 'Up-to-date Security Patches'AND <br />
                            Device Type = 'Company-issued Laptop' THEN <br />
                            Access = 'Granted'
                        </CodeBlock>
                        <li>Access Denied (Outside Business Hours):</li>
                        <CodeBlock style={{ margin: '1rem' }}>
                            IF Role = 'Financial Analyst' AND <br />
                            Clearance Level = 'Confidential' AND <br />
                            Access Time != 'Business Hours' THEN <br />
                            Access = 'Denied'
                        </CodeBlock>
                        <li>Access Denied (Non-Compliant Device):</li>
                        <CodeBlock style={{ margin: '1rem' }}>
                            IF Role = 'Financial Analyst' AND <br />
                            Clearance Level = 'Confidential' AND <br />
                            Device Compliance != 'Up-to-date Security Patches' THEN <br />
                            Access = 'Denied'
                        </CodeBlock>
                    </ul>
                </li>
            </ul>
        </div>
    )
}

export default ZTAC6;