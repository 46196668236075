
import React, { useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { FileUpload, FileUploadHeaderTemplateOptions, FileUploadSelectEvent, FileUploadUploadEvent, ItemTemplateOptions, } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import { FileUploadHandlerEvent } from 'primereact/fileupload';
import axios from 'axios';
import './UploadFile.css';
import { g_api } from '../../globals/api';
import { InputText } from 'primereact/inputtext';
import { isLocal } from '../utils/appUtils';

const MAX_FILE_SIZE = 25 * 1000 * 1000; // 25 MB
const MAX_FILE_SIZE_STR = '25 MB';

type T_UploadFileProps = {
    projectName: string;
    uploadSuccess: (data:any) => void;
}

export default function UploadFile({projectName, uploadSuccess}:T_UploadFileProps) {
    const toast = useRef<Toast>(null);
    const [totalSize, setTotalSize] = useState(0);
    const fileUploadRef = useRef<FileUpload>(null);
    const [metaData, setMetaData] = useState<any>({});

    const onTemplateSelect = (e: FileUploadSelectEvent) => {
        let _totalSize = totalSize;
        let files = e.files;

        for (let i = 0; i < files.length; i++) {
            _totalSize += files[i].size || 0;
        }

        setTotalSize(_totalSize);
    };

    const onTemplateUpload = (e: FileUploadUploadEvent) => {
        let _totalSize = 0;

        e.files.forEach((file) => {
            _totalSize += file.size || 0;
        });

        setTotalSize(_totalSize);
        toast.current?.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    };

    const onTemplateRemove = (file: File, callback: Function) => {
        setTotalSize(totalSize - file.size);
        callback();
    };

    const onTemplateClear = () => {
        setTotalSize(0);
    };

    const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize / MAX_FILE_SIZE;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }} onPaste={handlePaste}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                <div className="flex align-items-center gap-3 ml-auto">
                    <span>{formatedValue} / {MAX_FILE_SIZE_STR}</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };
    
    const itemTemplate = (inFile: object, props: ItemTemplateOptions) => {
        const file = inFile as File;
        return (
            <div className="flex align-items-center flex-wrap justify-space-around align-items-center gap-2">
                <div className="flex align-items-center justify-space-between align-items-center" style={{  }}>
                    {/* @ts-ignore */}
                    <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                    <span className="flex flex-column text-left ml-3">
                        <InputText value={metaData?.[file.name]?.displayName || file.name} onChange={(e) => setMetaData((prev:any) => ({...prev, [file.name] : {displayName: e.target.value}}))}/>
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2 justify-self-start" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };

    const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
        const clipboardItems = event.clipboardData.items;
        const items = Array.from(clipboardItems).filter(item => /^image\//.test(item.type));
        if (items.length === 0) {
            return;
        }
        const item = items[0];
        let blob: any = item.getAsFile();
        if (!blob) {
            return; // Ensure blob is not null
        }

        const newFileName = `Snapshot_${new Date().getTime()}.png`;
        const newFile:any = new File([blob], newFileName, {
            type: blob.type,  // Maintain the original MIME type
            lastModified: new Date().getTime()  // Optionally set the last modified time
        });

        const fileURL = URL.createObjectURL(newFile);
        newFile.objectURL = fileURL;

        const files: any = fileUploadRef.current?.getFiles()
        fileUploadRef.current?.setFiles([...files, newFile]);
    };

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column" onPaste={handlePaste}>
                {/* <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i> */}
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className=" flex flex-column gap-2 text-center" >
                    <div>Drag and Drop Files Here</div>
                    <div><kbd className="cv-key">Ctrl</kbd> + <kbd className="cv-key">V</kbd> screenshots in this window.</div>
                </span>
            </div>
        );
    };

    const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

    const handleUploadAPI = async (event: FileUploadHandlerEvent) => {
        if(!isLocal){
            toast?.current?.show({severity: 'success', summary:'Upload', detail: 'Simulated file upload.', life: 2500});
            fileUploadRef.current?.setFiles([]);
            return;
        }
        const { files } = event;
        
        console.log("FILES BEFORE", files);

        const formData = new FormData();
        Array.from(files).forEach((file:any) => {
            if((metaData?.[file.name]) && (metaData[file.name]?.displayName !== '')){
                file.displayName = metaData[file.name].displayName;
            }
            formData.append('files', file);
        });

        formData.append('metaData', JSON.stringify(metaData));

        try {
            const response = await axios.post(`${g_api.root}${g_api.uploadFiles(projectName)}`, formData, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
            if (response.status === 200) {
                uploadSuccess({response: response.data, metaData: {...metaData}})
                fileUploadRef.current?.setFiles([]);
                setMetaData({});
            } else {
                alert('File upload failed');
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    }

    return (
        <div>
            <Toast ref={toast}></Toast>

            <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
            <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
            <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

            <FileUpload ref={fileUploadRef} name="demo[]" uploadHandler={handleUploadAPI} multiple accept="*" maxFileSize={MAX_FILE_SIZE}
                onUpload={onTemplateUpload} onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
                headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                customUpload={true}
                chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />
        </div>
    )
}
