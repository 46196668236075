import React, { useState } from "react";
import { useCollection } from "./useCollection";
import atlasConfig from "../atlasConfig.json";
import { q_getZTInitial, q_getCapabilityDesc, q_getActivity, q_getNIST, q_getActivityMapping, q_getNistFamily, q_getCapability, q_getNISTNamesByIds } from "./queries/getZT";
import { GZT } from "../components/DataManagement/DataManagement";
import axios from "axios";
import { g_api } from "../globals/api";
import { isLocal } from "../components/utils/appUtils";
import { useApp } from "../components/RealmApp";
import { T_ExportRFI } from "../components/SurveyExportRFI/reducerSurveyExportRFI";

const { dataSourceName } = atlasConfig;

const errorMessageDemoNotSupported = 'Not supported in demo version.'

export function useAPIHandler() {
  const [loading, setLoading] = React.useState(true);
  const [apiError, setApiError] = useState(false);

  // Get a client object for the todo item collection
  const ztCollection = useCollection({
    cluster: dataSourceName,
    db: "zt",
    collection: "zt-controls",
  });

  const ztProfileCollection = useCollection({
    cluster: dataSourceName,
    db: "zt",
    collection: "zt-profiles",
  });

  const ztActivityCollection = useCollection({
    cluster: dataSourceName,
    db: "zt",
    collection: "zt-mpe-plan",
  });

  const nistCollection = useCollection({
    cluster: dataSourceName,
    db: "zt",
    collection: "nist800-53-controls",
  })

  const overlayCollection = useCollection({
    cluster: dataSourceName,
    db: "zt",
    collection: "overlays",
  })

  const ztacCollection = useCollection({
    cluster: dataSourceName,
    db: "zt",
    collection: "ztac-mappings",
  })

  const getActivity = async (aId: any, isUser = false) => {
    if (isLocal) return axios.get(`${g_api.root}/zt/activity/${aId}`, { withCredentials: true }).then(res => res.data);
    return ztCollection.aggregate(q_getActivity(aId, isUser));
  }

  const getCapabilityDesc = async (cId: string) => {
    if (isLocal) return axios.get(`${g_api.root}/zt/capabilityDesc/${cId}`, { withCredentials: true }).then(res => res.data);
    return ztCollection.aggregate(q_getCapabilityDesc(cId));
  }

  const getCapability = async (cId: string) => {
    if (isLocal) return axios.get(`${g_api.root}/zt/capability/${cId}`, { withCredentials: true }).then(res => res.data);
    return ztCollection.aggregate(q_getCapability(cId));
  }

  const getNistData = async (cId: string) => {
    if (isLocal) return axios.get(`${g_api.root}/nist/800-53/${cId}`, { withCredentials: true }).then(res => res.data);
    return nistCollection.aggregate(q_getNIST(cId));
  }

  const getNistNamesByIds = async (cIdsArray: string[]) => {
    if (isLocal) return axios.get(`${g_api.root}/nist/800-53/names`, { withCredentials: true, params: { cIds: cIdsArray.join(',') } }).then(res => res.data);
    return nistCollection.aggregate(q_getNISTNamesByIds(cIdsArray));
  }

  const getCurrentProjects = async () => {
    if (isLocal) return axios.get(`${g_api.root}/ztkm/allprojects`, { withCredentials: true }).then(res => res.data);
  }

  const getActivityMappings = async () => {
    return ztCollection.aggregate(q_getActivityMapping);
  }

  const getNistFamily = async () => {
    return nistCollection.aggregate(q_getNistFamily);
  }

  const getMPE = async () => {
    return ztActivityCollection.findOne();
  }

  const getZTProfiles = async () => {
    if (isLocal) return axios.get(`${g_api.root}${g_api.getProfileNames}`, { withCredentials: true }).then(res => res.data);
    return ztProfileCollection.aggregate([
      { $match: {} },
      {
        $project: {
          _id: 0,
          ztIds: 0,
        }
      }
    ]);
  }

  const getZTProfileData = async (profileName: string) => {
    if (isLocal) return axios.get(`${g_api.root}${g_api.getProfileData(profileName)}`, { withCredentials: true }).then(res => res.data);
    return ztProfileCollection.findOne(
      { name: profileName },
      { projection: { _id: 0 } }
    );
  }

  const updateActivity = async (data: any) => {
    return ztActivityCollection.updateOne(
      { aId: data.aId },
      {
        $set: { ...data }
      },
      { upsert: true }
    )
  }

  const postExportRFI = async (data: T_ExportRFI) => {
    if (isLocal) return axios.post(`${g_api.root}${g_api.postExportRFI}`, data, { withCredentials: true, responseType: 'blob' });
    return new Promise((_, reject) => {
      reject(new Error(errorMessageDemoNotSupported));
    });
  }

  const getPillarsImplemented = async (projectName: string, profileName: string) => {
    if (isLocal) {
      return axios.get(`${g_api.root}${g_api.getPillarsImplemented(projectName)}`, {
        withCredentials: true,
        params: {
          profile: profileName
        }
      });
    }
    return new Promise((_, reject) => {
      reject(new Error(errorMessageDemoNotSupported));
    });
  }

  const getActivitiesImplemented = async (projectName: string, profileName: string) => {
    if (isLocal) {
      return axios.get(`${g_api.root}${g_api.getReportActivitiesImplemented(projectName)}`, {
        withCredentials: true,
        params: {
          profile: profileName
        }
      });
    }
    return new Promise((_, reject) => {
      reject(new Error(errorMessageDemoNotSupported));
    });
  }

  const getReportOutcomesImplemented = async (projectName: string, profileName: string) => {
    if (isLocal) {
      return axios.get(`${g_api.root}${g_api.getReportOutcomesImplemented(projectName)}`, {
        withCredentials: true,
        params: {
          profile: profileName
        }
      });
    }
    return new Promise((_, reject) => {
      reject(new Error(errorMessageDemoNotSupported));
    });
  }

  const getReportActivityGaps = async (projectName: string, profileName: string) => {
    if (isLocal) {
      return axios.get(`${g_api.root}${g_api.getReportActivityGaps(projectName)}`, {
        withCredentials: true,
        params: {
          profile: profileName
        }
      });
    }
    return new Promise((_, reject) => {
      reject(new Error(errorMessageDemoNotSupported));
    });
  }

  const getOverlayContentByType = async (nistId: string, type: string) => {
    if (isLocal) {
      return axios.get(`${g_api.root}${g_api.getOverlayContentByTypeId}`, {
        withCredentials: true,
        params: {
          cId: nistId,
          type: type,
        }
      }).then(res => res.data);
    }
    return overlayCollection.findOne({ type, cId: nistId })
  }

  const getZTACMappings = async (acId: string) => {
    if (isLocal) {
      return axios.get(`${g_api.root}${g_api.getZTACMappings(acId)}`, {
        withCredentials: true,
      }).then(res => res.data);
    }
    return ztacCollection.find({ acId: acId }, { projection: { _id: 0 } })
  }

  const checkZTDataCache = async () => {
    if (GZT?.activities && GZT.activities.length > 0) return true;

    const activities = await axios.get(`${g_api.root}/zt/activities`, { withCredentials: true });
    const nistFamily = await axios.get(`${g_api.root}/nist/800-53/family`, { withCredentials: true });

    GZT.nistFamily = [...nistFamily.data];
    GZT.capabilities = activities.data.capabilities;
    GZT.activities = activities.data.activities;
    GZT.pillars = activities.data.pillars;
    return true;
  }

  const postZTKMDataModify = async (data: any) => {
    if (!data.project) {
      return Promise.reject(new Error("No Project Selected."));
    }
    if (isLocal) {
      return axios.post(`${g_api.root}${g_api.modifyStatuses(data.project)}`, data, { withCredentials: true }).then(res => res.data);
    }
    return new Promise((_, reject) => {
      reject(new Error(errorMessageDemoNotSupported));
    });
  }

  const getDataResponse = async (projectName: string, aId: string, type: 'activity' | 'ztac' = 'activity') => {
    return axios.get(`${g_api.root}${g_api.getDataResponse(projectName, aId, type)}`, { withCredentials: true }).then(res => res.data);
  }

  const postZTACDataModify = async (data: any) => {
    if (!data.project) {
      return Promise.reject(new Error("No Project Selected."));
    }
    if (isLocal) {
      return axios.post(`${g_api.root}${g_api.postZTACModify(data.project)}`, data, { withCredentials: true }).then(res => res.data);
    }
    return new Promise((_, reject) => {
      reject(new Error(errorMessageDemoNotSupported));
    });
  }

  const getEntireProject = async (projectName: string) => {
    if (!projectName || projectName === '') {
      return Promise.reject(new Error("No Project Selected."));
    }
    if (isLocal) {
      return axios.get(`${g_api.root}${g_api.getEntireProject(projectName)}`, { withCredentials: true }).then(res => res.data);
    }
    return new Promise((_, reject) => {
      reject(new Error(errorMessageDemoNotSupported));
    });
  }

  const getEntireZTDatabase = async () => {
    if (isLocal) {
      return axios.get(`${g_api.root}${g_api.getEntireZTDatabase}`, { withCredentials: true }).then(res => res.data);
    }
    return ztCollection.find({})
  }

  return {
    loading,
    apiError,
    getCapabilityDesc,
    getDataResponse,
    getMPE,
    getActivity,
    getNistData,
    updateActivity,
    getActivityMappings,
    getNistFamily,
    getCurrentProjects,
    getCapability,
    getZTProfiles,
    getZTProfileData,
    postExportRFI,
    getPillarsImplemented,
    getActivitiesImplemented,
    getReportOutcomesImplemented,
    getReportActivityGaps,
    getNistNamesByIds,
    getOverlayContentByType,
    getZTACMappings,
    checkZTDataCache,
    postZTKMDataModify,
    postZTACDataModify,
    getEntireProject,
    getEntireZTDatabase,

  };
}
