import React, { ReactNode, useState } from 'react';
import { Button } from 'primereact/button';

type T_Props = {
    children: ReactNode
}

const UseCaseShowButton: React.FC<T_Props> = ({ children }) => {
    const [showContent, setShowContext] = useState<boolean>(false);

    return (
        <>
            <Button label={`${showContent ? 'Hide' : 'Show'} Use Case`}
                className='align-self-center p-3'
                outlined severity='info' size='small'
                icon={`pi pi-chevron-${showContent ? 'up' : 'down'}`}
                onClick={() => setShowContext(prev => !prev)}
            />
            {showContent &&
                children
            }
        </>
    )
}

export default UseCaseShowButton;