import { CiFileOn } from "react-icons/ci";
import { useApp } from "../RealmApp";


const ChartArtifactsCollected = () => {
    const { activeProject }:any = useApp();
    const count = (activeProject?.files) ? String(activeProject.files.length) : '0';

    return (
        <div className="col-12 md:col-6 lg:col-3">
            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                <div className="flex justify-content-between mb-3">
                    <div>
                        <span className="block text-500 font-medium mb-3">Artifacts</span>
                        <div className="text-900 font-medium text-xl">{count}</div>
                    </div>
                    <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                        {/* <i className="pi pi-map-marker text-orange-500 text-xl"></i> */}
                        <CiFileOn className="text-blue-500"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChartArtifactsCollected;