import { useRef } from "react";
import { LocalAppProvider } from "./LocalApp";
import { AppProvider } from "./RealmApp";
import { isLocal } from "./utils/appUtils";

const ConditionalProvider = ({ appId, children }) => {
  // const toastRef = useRef(null);

  return isLocal ? (
    <LocalAppProvider appId={appId}>
      {/* <Toast ref={toastRef} /> */}
      {children}
    </LocalAppProvider>
  ) : (
    <AppProvider appId={appId}>
      {/* <Toast ref={toastRef} /> */}
      {children}
    </AppProvider>
  );
};

export default ConditionalProvider;
