
import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import useResponsive from '../../hooks/useResponsive';

import './SurveySidebar.css';
import { SelectButton } from 'primereact/selectbutton';
import { useApp } from '../RealmApp';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { getZTProfileDataCache, GZT } from '../DataManagement/DataManagement';
import { useApi } from '../ProviderAPIs';
import { InputText } from 'primereact/inputtext';
import { TableIconType } from '../ZTPage/ZTActivityTable';
import { Ripple } from 'primereact/ripple';
import { useAPIHandler } from '../../hooks/useAPIHandler';
import { sortArrayActivityIds } from '../ZTPage/reducerZTActivity';
import { error } from 'console';
import ZTSurveyActivityComponent from '../SurveyTool/ZTSurveyActivitiesComponent';

type T_ACtoZTMappingObj = {
    acId: string;
    mark: "P" | "S";
    ztId: string;
}

type phaseOption = {
    name: 0 | 1 | 2 | 3 | 4;
    value: 0 | 1 | 2 | 3 | 4;
}

const phasesOptions: phaseOption[] = [
    { name: 0, value: 0 },
    { name: 1, value: 1 },
    { name: 2, value: 2 },
    { name: 3, value: 3 },
    { name: 4, value: 4 },
]

const ztLevelOptions: any = [
    { name: 'Target', value: 'target' },
    { name: 'Adv', value: 'advanced' },
]

const markLookupTable = {
    'P': {
        name: 'Partial',
        severity: 'info',
    },
    'S': {
        name: 'Satisfies',
        severity: 'success',
    }
}

type T_SurveySidebarFilters = {
    profile: string;
    level: string[];
    phases: number[];
    search: string;
    filterCount: string;
}

export const defaultSurveyFilter: T_SurveySidebarFilters = {
    profile: 'ZT-All',
    level: ['target', 'advanced'],
    phases: [0, 1, 2, 3, 4],
    search: '',
    filterCount: '0/152',
}

export type T_SurveySidebarActions = {
    type: string;
    field?: string;
    payload?: any;
}

export function surveyFilterReducer(state: T_SurveySidebarFilters, action: T_SurveySidebarActions) {
    switch (action.type) {
        case 'level':
            return {
                ...state,
                level: action.payload,
            }
        case 'phase':
            return {
                ...state,
                phases: action.payload,
            }
        case 'search':
            return {
                ...state,
                search: action.payload,
            }
        case 'filterCount': {
            return {
                ...state,
                filterCount: action.payload,
            }
        }
        case 'reset':
            return {
                ...defaultSurveyFilter
            }
        case 'update':
            if (!action?.field) return state;
            return {
                ...state,
                [action.field]: action.payload,
            }
        default:
            return state;
    }
}

type T_ACSurveySidebarProps = {
    acId: string;
}

export default function ACSurveySidebar({ acId }: T_ACSurveySidebarProps) {
    const { ztProfiles, activeProject }: any = useApp();
    const [ztIds, setZtIds] = useState<T_ACtoZTMappingObj[]>([]);
    const [isLoaded, setIsLoaded] = useState(false);
    // const menuLeft = useRef<any>(null);
    const windowSize = useResponsive();
    const showPopup = windowSize !== 'lg';
    const [visible, setVisible] = useState(!showPopup);
    const { getZTACMappings, checkZTDataCache } = useAPIHandler();

    useEffect(() => {
        if (acId === '') return;
        checkZTDataCache()
            .then(res => setIsLoaded(true))
            .catch(error => console.log())
        getZTACMappings(acId)
            .then(data => {
                setZtIds(sortArrayActivityIds(data, 'ztId'));
            })
            .catch(error => {
                console.log(error);
            })
    }, [acId])

    // const optionProfiles = ztProfiles.map((x: any) => ({ name: x.name }));

    if (!showPopup && !visible) setVisible(true);

    // useEffect(() => {
    //     if (!activeProject?.profile) return;
    //     dispatch({ type: 'update', field: 'profile', payload: activeProject.profile })
    // }, [activeProject?.profile])

    // const handleProfileUpdate = async (e: DropdownChangeEvent) => {
    //     await getZTProfileDataCache(e.value.name, api.getZTProfileData);
    //     dispatch({ type: 'update', field: 'profile', payload: e.value.name })
    // };

    return (
        <>
            {showPopup &&
                <Button size='small' severity='secondary' icon="pi pi-cog"
                    className="mr-2 sb-survey-btn"
                    style={{ zIndex: 9 }}
                    onClick={() => setVisible(prev => !prev)}
                    aria-controls="popup_menu_left" aria-haspopup />
            }
            {visible && isLoaded &&
                <>
                    {(ztIds.length === 0) ?
                        <div className='text-center'>No Mappings.</div>
                        :
                        ztIds.map((ztIdObj) => {
                            const data = GZT.activities.find((x: any) => x.aId === ztIdObj.ztId);
                            if (!data) return;
                            return <ZTSurveyActivityComponent key={`ztac-at-${acId}-${ztIdObj.ztId}`} data={data} tag={ztIdObj.mark} />
                        })
                    }
                </>
            }
        </>
    )
}