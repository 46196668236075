import ACStatus from "./ACStatusButton";
import { CodeBlock, useZTACContext, ZTACBullet } from "./ZTAC_Component";



const ZTAC10 = () => {
    return (
        <></>
    )
};

ZTAC10.Title = function ZTAC1_Title() {
    return (
        <>
            <div className="ztac-title">10.0: Are unanticipated conditions immediately escalated for accommodation?</div>
            {/* <div className="ztac-subtitle">ML/AI</div> */}
        </>
    )
}

ZTAC10.Intro = function ZTAC10_Intro() {
    return (
        <>
            <div>
                <h2>Section A:</h2>
                <div className="ztac-subtitle">Rules cannot resolve due to lack of attributes and/or rule logic - attribute/rule</div>

                <h3>Acceptance Criteria:</h3>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="ac-001">A logic rule and associated attributes that produce an unknown state (has not been encountered or precomputed) are immediately “escalated” to a mediation or mitigation resolution process.</ZTACBullet>
                </ul>
            </div>

            <div>
                <h3>Outcome:</h3>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="ou-001">All logic rules and associated attributes (and every permutation of allowed values) that have not be previously computed are available to establish a repeatable deterministic outcome that informs Access Grant or Access Deny. This establishes a known outcome for each logic rule set that continuously completes the comprehensive set of all rule and attribute combinations, continuously maturing the known landscape of the ZT behavior model.</ZTACBullet>
                </ul>
            </div>

            <div>
                <h3>Conceptual Measures:</h3>
                <p>The system is able to identify:</p>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="cm-001">All rule-attribute sets (and associated values) that have both been pre-computed and not pre-computed.</ZTACBullet>
                    <ZTACBullet code="cm-002">The computational disposition outcome of all rules and attribute permutations identified as not pre-computed</ZTACBullet>
                    <ZTACBullet code="cm-003">All non-pre-computed sets have been presented to the remediation mitigation process.</ZTACBullet>
                    <ZTACBullet code="cm-004">All rule-attribute sets are computed and a complete known state of all deterministic outcomes are established.</ZTACBullet>
                </ul>
            </div >



            <div>
                <h2>Section B:</h2>
                <div className="ztac-subtitle">No policy exists for a behavioral request to a ZT service - policy decomposition to rules and attribute [permissive actions disallowed]</div>

                <h3>Acceptance Criteria:</h3>
                <p>A ZT request does not fit any existing rule, and the system responds with any or all of the following based on configuration state:</p>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="ac-001">Immediate identification and notification that there is a request that does not find an appropriate rule.</ZTACBullet>
                    <ZTACBullet code="ac-002">The system executes a “null rule – rule” that has a pre-determined disposition for behavioral response.</ZTACBullet>
                    <ZTACBullet code="ac-003">The system proposes a near-rule approximation based on rule - attributes near matches</ZTACBullet>
                    <ZTACBullet code="ac-004">The system provides the ability to dynamically load a new rule – attributes set to resolve the gap rule situation</ZTACBullet>
                </ul>
            </div>

            <div>
                <h3>Outcome:</h3>
                <p>Gaps in available logic rules are discovered and immediately sent for remediation. The following results are anticipated:</p>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="ou-001">Request is presented as addressable</ZTACBullet>
                    <ZTACBullet code="ou-002">Null-rule triggered event is associated, recorded and presented as part of the remediation information</ZTACBullet>
                    <ZTACBullet code="ou-003">Near rule-attribute sets are presented as part of the remediation information (may not be real-time-ish based on density and size considerations)</ZTACBullet>
                    <ZTACBullet code="ou-004">Proposed rule-attribute set is presented for consideration as part of remediation information</ZTACBullet>
                </ul>
            </div>

            <div>
                <h3>Conceptual Measures:</h3>
                <p>The system can identify:</p>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="cm-001">Requests that have no available rule-attribute set</ZTACBullet>
                    <ZTACBullet code="cm-002">Where the request fails against near-fit rules</ZTACBullet>
                    <ZTACBullet code="cm-003">
                        If the rule failed based on:
                        <ul>
                            <li>Logic rule gap</li>
                            <li>Attribute mapping gap</li>
                            <li>Attribute value gap</li>
                        </ul>
                    </ZTACBullet>
                    <ZTACBullet code="cm-004">Proposed rule generation is presented</ZTACBullet>
                    <ZTACBullet code="cm-005">The computational disposition outcome of the proposed rule is presented as part of the remediation information</ZTACBullet>
                    <ZTACBullet code="cm-006">The number of similar requests that have been presented that have failed since first occurrence.</ZTACBullet>
                </ul>
            </div >

        </>
    )
}

ZTAC10.UseCase = function ZTAC10_UseCase() {
    return (
        <div>
            <h3>Implementation Use Case: (example)</h3>
            <p>Scenario: Zero Trust Handling of Unanticipated Conditions in Access Control.</p>
            <ul>
                <li>
                    Define Policy Attributes:
                    <ul>
                        <li>Collaborate with IT, security, and compliance teams to define the necessary user, behavioral, and activity attributes for monitoring.</li>
                    </ul>
                </li>
                <li>
                    Develop Logic Rules:
                    <ul>
                        <li>
                            Develop logic rules that describe the policy in terms of attributes and allowed values. For example:
                            <CodeBlock style={{ margin: '1rem' }}>
                                IF User Role = 'Financial Analyst' AND <br />
                                Clearance Level = 'Confidential' AND <br />
                                Access Time = 'Business Hours' AND <br />
                                Access Location = 'Secure Office Network' AND <br />
                                Device Compliance = 'Up-to-date Security Patches' AND <br />
                                Device Type = 'Company-issued Laptop' THEN <br />
                                Access = 'Granted' ELSE <br />
                                Access = 'Denied'
                            </CodeBlock>
                        </li>
                    </ul>
                </li>
                <li>
                    Automate Data Collection:
                    <ul>
                        <li>Implement automated data collection mechanisms to gather information on user activities, access times, locations, device compliance, and transaction volumes.</li>
                    </ul>
                </li>
                <li>
                    Identify Unknown States:
                    <ul>
                        <li>Use automated tools to identify logic rules and associated attributes that produce unknown states. Escalate these to a mediation or mitigation resolution process.</li>
                    </ul>
                </li>
                <li>
                    Generate Reports:
                    <ul>
                        <li>Use automated tools to generate static and dynamic reports of each event and type, providing insight into typical and atypical behaviors.</li>
                    </ul>
                </li>
                <li>
                    Trend Tracking and Evolution:
                    <ul>
                        <li>Implement trend tracking mechanisms to monitor behavioral patterns over time and detect shifts based on rule adjustments.</li>
                    </ul>
                </li>
                <li>
                    Policy Recommendations:
                    <ul>
                        <li>Use analytic yields to recommend remediation approaches and formulate predictive digital policies.</li>
                    </ul>
                </li>
                <li>
                    Preemptive Defense Postures:
                    <ul>
                        <li>Implement preemptive defense mechanisms based on identified atypical behaviors and potential threats.</li>
                    </ul>
                </li>
            </ul>
            <br />
            <h4>Example Scenarios:</h4>
            <ul>
                <li>Unknown State Escalation:</li>
                <CodeBlock style={{ margin: '1rem' }}>
                    $User ID: 12345 <br />
                    Role: Financial Analyst <br />
                    Access Time: 10 AM <br />
                    Access Location: Secure Office Network <br />
                    Device Compliance: Up-to-date Security Patches <br />
                    Device Type: Company-issued Laptop <br />
                    Access Frequency: 5 times/day <br />
                    Access Duration: 30 minutes/session <br />
                    Data Accessed: Financial Reports <br />
                    Transaction Volume: Normal <br />
                    Outcome: Unknown State (Escalated for Mediation)
                </CodeBlock>
                <li>Policy Gap Handling:</li>
                <CodeBlock style={{ margin: '1rem' }}>
                    User ID: 12345 <br />
                    Role: Financial Analyst <br />
                    Access Time: 2 PM <br />
                    Access Location: Unrecognized IP Address <br />
                    Device Compliance: Up-to-date Security Patches <br />
                    Device Type: Company-issued Laptop <br />
                    Access Frequency: 1 time <br />
                    Access Duration: 5 minutes <br />
                    Data Accessed: Financial Reports <br />
                    Transaction Volume: Normal <br />
                    Outcome: Policy Gap (Null Rule Triggered, Escalated for Remediation)
                </CodeBlock>
            </ul>

            <h4>Validation and Testing:</h4>
            <ul>
                <li>
                    Validate Analytic Models:
                    <ul>
                        <li>Validate AI and ML models to ensure they accurately identify typical, unacceptable, and unanticipated behaviors.</li>
                    </ul>
                </li>
                <li>
                    Generate and Validate Reports:
                    <ul>
                        <li>Generate static and dynamic reports and validate that they provide accurate insights into behavioral patterns.</li>
                    </ul>
                </li>
                <li>
                    Monitor Trends:
                    <ul>
                        <li>Continuously monitor trends and validate that trend tracking mechanisms accurately depict behavioral shifts.</li>
                    </ul>
                </li>
                <li>
                    Policy Recommendations:
                    <ul>
                        <li>Validate that policy recommendations are based on accurate analytic yields and provide effective remediation approaches.</li>
                    </ul>
                </li>
                <li>
                    Preemptive Defense:
                    <ul>
                        <li>Test preemptive defense mechanisms to ensure they effectively mitigate identified threats based on atypical behaviors. By following these steps, the financial institution can ensure that unanticipated conditions are effectively handled, logic rules are escalated for mediation or mitigation, and policy gaps are identified and addressed. This ensures that access control policies are tightly controlled and monitored to prevent unauthorized access and potential security breaches, while also providing comprehensive insights into operational behaviors and trends.</li>
                    </ul>
                </li>
            </ul>
        </div >
    )
}

export default ZTAC10;