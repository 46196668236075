import { InputTextarea } from "primereact/inputtextarea";
import React, { Children, useContext, useReducer, useRef } from "react";
import { useApp } from "../RealmApp";
import { formatFileSize } from "../utils/miscUtils";
import { g_api } from "../../globals/api";
import UploadFile from "../UploadFile/UploadFile";
import LinkArtifactComponent from "../SurveyTool/LinkArtifactComponent";
import { Panel } from "primereact/panel";
import { RiQuestionAnswerLine } from "react-icons/ri";
import { CiFileOn } from "react-icons/ci";

import '../AcceptanceCriteria/ZTAC_Component.css';
import { Dropdown } from "primereact/dropdown";
import { badgeTemplateSurvey, dropDownIconSurvey, getStatusObject, surveyStatuses } from "../SurveyTool/ZTSurveyActivitiesComponent";
import { useZTACRootContext } from "../SurveyTool/SurveyToolACProvider";
import ACStatus from "../AcceptanceCriteria/ACStatusButton";
import { ZTACContext } from "../AcceptanceCriteria/ZTAC_Component";
import { Badge } from "primereact/badge";

type T_ZTAC_ComponentProps = {
    acId: string;
    children: React.ReactNode;
    onClick?: (acId: string) => void;
    acData: any;
}

type T_CodeBlockProps = {
    children: React.ReactNode;
    style?: any;
}

export const CodeBlock = ({ children, style }: T_CodeBlockProps) => {
    return (
        <div className="p-3 border-round" style={{ ...style, backgroundColor: '#211E2F', color: '#f9fafb', fontFamily: 'Consolas' }}>
            {children}
        </div>
    )
}

const ExportAcceptanceCriteriaComponent = ({ children, acId, acData }: T_ZTAC_ComponentProps) => {
    const { activeProject }: any = useApp();
    const { ztacAnalytics } = useZTACRootContext();
    const panelRef = useRef<any>(null);
    const state = acData.find((x: any) => x?.ztacId === acId) || {};

    const statusObj = ztacAnalytics.find((x: any) => x.ztacId === acId) || null;
    const status = getStatusObject(statusObj?.status);

    const childrenArray = React.Children.toArray(children);

    const header = (options: any) => {
        const className = `${options.className} justify-content-space-between gap-2 p-0`;

        return (
            <div className={className} style={{ backgroundColor: `var(--color-v5)` }}>
                <div className="flex flex-column" style={{ cursor: 'pointer', padding: '1rem', width: '100%' }}>
                    {childrenArray[0]}
                </div>
                {(status.status === 'Answered') &&
                    <div className="flex flex-column">
                        {(!statusObj.hasNote) &&
                            <RiQuestionAnswerLine color="red" />
                        }
                        {(!statusObj.hasFiles) &&
                            <CiFileOn color="red" />
                        }
                    </div>
                }
                <div className="flex flex-column justify-content-center align-items-center" style={{ marginRight: '1rem' }}>
                    {/* @ts-ignore */}
                    <Badge value={status.status} severity={status?.severity} style={{ textWrap: 'nowrap' }} />
                </div>
            </div>
        )
    }

    return (
        <ZTACContext.Provider value={{ state }}>
            <Panel ref={panelRef} headerTemplate={header} collapsed={false} className={`flex flex-column zt-inline-custom`} aria-roledescription={`ztac-ac-${acId}`}>
                <div className="h:full overflow-y-auto p-4 flex flex-column">
                    {childrenArray[1]}
                    <div className="flex flex-column justify-content-center text-center gap-3">
                        <div className="flex flex-column">
                            <h3>Response:</h3>
                            <p>{(!state?.note || state?.note === '') ? 'None' : state?.note}</p>
                        </div>
                        <div className="flex flex-column justify-content-center gap-2">
                            <h3>Artifacts:</h3>
                            {(state?.files && state.files.length === 0) &&
                                <div>None</div>
                            }
                            {state?.files && state.files.map((linkFile: any, i: any) => {
                                if (!activeProject?.files) return;
                                const file = activeProject.files.find((x: any) => x.backendName === linkFile);
                                if (!file) return;
                                const filename = file.backendName.split('/').pop();
                                return (
                                    <a href={`./files/${filename}`} key={`acf-${acId}-${i}-${file.filename}`} target="_blank"
                                        className="flex flex-row gap-3 p-button p-button-secondary p-button-outlined">
                                        {formatFileSize(file.size || 0)} {file?.meta?.displayName || file.filename}
                                    </a>
                                )
                            })}
                        </div>

                    </div>
                </div>
            </Panel>

        </ZTACContext.Provider>
    )
}

export default ExportAcceptanceCriteriaComponent;