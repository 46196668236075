import { T_FileObject } from "../SurveyTool/EditCapabilityPopup";

type T_DefaultReducerAction = {
    type: string;
    field?: string;
    payload?: any;
}

export type T_ACStatusData = {
    code: string;
    value: 'No' | 'Partial' | 'Yes';
}

type T_reducerZTAC = {
    status: string;
    maturity: string;
    criteria: T_ACStatusData[];
    files: T_FileObject[];
    updated: boolean;
    note: string;
}

export const emptyZTACReducer: T_reducerZTAC = {
    status: 'Incomplete',
    criteria: [],
    note: '',
    maturity: 'Incomplete',
    updated: false,
    files: [],
};

export default function reducerZTAC(state: T_reducerZTAC, action: T_DefaultReducerAction):T_reducerZTAC  {
    switch (action.type) {
        case 'init':
            return {
                ...emptyZTACReducer,
                ...action.payload,
            }
        case 'discard': {
            return {
                ...action.payload,
            }
        }
        case 'update':
            if (!action?.field) return state;
            return {
                ...state,
                [action.field]: action?.payload,
                updated: true,
            }
        case 'changeCriteria':
            const newCutcomes = state.criteria.filter((x:any) => x.code !== action.payload.code)
            newCutcomes.push(action.payload);
            return {
                ...state,
                criteria: [...newCutcomes],
                updated: true,
            }
        default:
            return state;
    }
}