import { DataTable } from "primereact/datatable";
import { useApp } from "../RealmApp";
import { Column } from "primereact/column";
import { linkedCapabilitiesTemplate, statusTemplate } from "../SurveyTool/ZTSurveyEditCapabilities";

type T_CapabilityObject = {
    name: string;
    editedBy: string;
    lastUpdated: Date;
    notes: string;
    status: string;
    ztcIds: string[];
}

const ChartTableCapabilities = () => {
    const { activeProject }: any = useApp();

    return (
        <div className="col-12">
            <div className="surface-0 shadow-2 border-1 border-50 border-round">
                <DataTable value={activeProject?.ztCapabilities} size="small"
                        className="border-50 border-round" sortField="name" sortOrder={1}
                        header="Status of Capabilities"
                    scrollable >
                    <Column field="name" header="Capability" sortable/>
                    <Column header="Status" body={statusTemplate} sortable/>
                    <Column header="Links" sortable sortField="links"
                        body={linkedCapabilitiesTemplate}
                        sortFunction={(e: any) => {
                            return e.data.sort((a: any, b: any) => e.order * (b.ztcIds.length - a.ztcIds.length))
                        }} />
                </DataTable>
            </div>
        </div>
    )
}

export default ChartTableCapabilities;