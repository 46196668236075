import React, { useRef, useState } from 'react';
import { FileUpload, FileUploadHandlerEvent } from 'primereact/fileupload';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import { g_api } from '../../globals/api';

type T_ImportNewProjectXlsxProps = {
  setData: (rfiData:any) => void;
}

const ImportNewProjectXlsx = ({setData}:T_ImportNewProjectXlsxProps) => {
  const fileUploadRef = useRef<FileUpload>(null);
  const toast = useRef<Toast>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const [responseData, setResponseData] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleUploadAPI = async (event: FileUploadHandlerEvent) => {
    const { files } = event;

    if (files && files.length > 0) {
      setUploading(true);
      const formData = new FormData();

      // Assuming only one file is uploaded
      const file = files[0];

      formData.append('excelFile', file);

      try {
        const response = await axios.post(`${g_api.root}${g_api.postImportNewProjectExcel}`, formData, {
          withCredentials: true,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.status === 200) {
          // setResponseData(response.data);
          setData(response.data);
          toast.current?.show({
            severity: 'success',
            summary: 'Upload Successful',
            detail: 'File uploaded and processed successfully.',
            life: 3000,
          });
          fileUploadRef.current?.clear();
        } else {
          setErrorMessage('File upload failed.');
          toast.current?.show({
            severity: 'error',
            summary: 'Upload Failed',
            detail: 'File upload failed.',
            life: 3000,
          });
        }
      } catch (error: any) {
        console.error('Error uploading file:', error);
        setErrorMessage(error.response?.data?.msg || 'An error occurred during file upload.');
        toast.current?.show({
          severity: 'error',
          summary: 'Upload Error',
          detail: errorMessage,
          life: 3000,
        });
      } finally {
        setUploading(false);
        fileUploadRef.current?.setFiles([]);
      }
    }
  };

  return (
    <>
      <FileUpload
        ref={fileUploadRef}
        name="excelFile"
        uploadHandler={handleUploadAPI}
        accept=".xlsx"
        customUpload
        chooseLabel='Import from RFI'
        chooseOptions={{ icon: (uploading) ? 'pi pi-spin pi-spinner' : 'pi pi-folder-open', iconOnly: false }}
        mode='basic'
        maxFileSize={10000000} // Adjust as needed (in bytes)
        auto={true}
        disabled={uploading}
      />

      {/* {uploading && <p>Uploading file...</p>} */}

      {/* {responseData && (
        <div>
          <h3>Data Received from Server:</h3>
          <pre>{JSON.stringify(responseData, null, 2)}</pre>
        </div>
      )} */}

      {/* {errorMessage && (
        <div>
          <h3>Error:</h3>
          <p>{errorMessage}</p>
        </div>
      )} */}
    </>
  );
};

export default ImportNewProjectXlsx;
