import { useState, useEffect } from 'react';

// Define the breakpoints for small, medium, and large screens
const breakpoints = {
    sm: 576,
    md: 768,
    lg: 992
};

const useResponsive = () => {
    const [size, setSize] = useState(getDeviceSize());

    useEffect(() => {
        const handleResize = () => {
            setSize(getDeviceSize());
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function getDeviceSize() {
        const width = window.innerWidth;
        if (width <= breakpoints.sm) {
            return 'sm';
        } else if (width <= breakpoints.md) {
            return 'md';
        } else {
            return 'lg';
        }
    }

    return size;
};

export default useResponsive;
