import React, { useState } from "react";
import * as Realm from "realm-web";
import { useApp } from "./RealmApp";
import {
  Container,
  TextField,
  Button,
  IconButton,
  Card,
  Typography,
  InputAdornment,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { MoreInfoDocsLink } from "./MoreInfo";
import { toggleBoolean } from "../utils";
import { useErrorAlert } from "../hooks/useErrorAlert";
import { validateEmail } from "./utils/userUtils";

export function WelcomePage() {
  const app = useApp();

  // Track whether the user is logging in or signing up for a new account
  const [isSignup, setIsSignup] = React.useState(false);
  const toggleIsSignup = () => {
    clearErrors();
    setIsSignup(toggleBoolean);
  };
  // Authentication errors
  const noErrors = {
    email: null,
    password: null,
    other: null,
  };
  const [error, setError] = React.useState(noErrors);
  const [inviteCode, setInviteCode] = useState('');
  const [inviteValid, setInviteValid] = useState(false);
  const [shakeCode, setShakeCode] = useState(false);

  const clearErrors = () => setError(noErrors);
  const NonAuthErrorAlert = useErrorAlert({
    error: error.other,
    clearError: () => {
      setError((prevError) => ({ ...prevError, other: null }));
    },
  });
  // Manage password visibility
  const [showPassword, setShowPassword] = React.useState(false);
  const toggleShowPassword = () => setShowPassword(toggleBoolean);

  const onFormSubmit = async ({ email, password }) => {
    clearErrors();
    try {
      if (isSignup) {
        await app.emailPasswordAuth.registerUser({ email, password });
      }
      await app.logIn(Realm.Credentials.emailPassword(email, password));
      console.log(app);
    } catch (err) {
      console.log(err);
      handleAuthenticationError(err, setError);
    }
  };

  const handleAnonLogin = async () => {
    const credentials = Realm.Credentials.anonymous();
    await app.logIn(credentials);
  }

  const validateInviteCode = async () => {
    clearErrors();
    const email = document.getElementById('input-email').value;
    if (!validateEmail(email)) {
      handleAuthenticationError(new Realm.MongoDBRealmError('', '', '', '', 'email invalid'), setError);
    }

    app.validateInvite(inviteCode, email)
      .then((data) => {
        if (data?.status === 'accept') {
          setInviteValid(true);
        } else {
          setInviteValid(false);
          setShakeCode(true);
          setTimeout(() => setShakeCode(false), 500);
        }
      })
      .catch(error => {
        console.log("Validation error: ", error)
      })
  }

  return (
    <Container maxWidth="sm" className="main-container">
      <Card className="auth-card" variant="outlined">
        <form
          className="auth-form"
          onSubmit={(e) => {
            e.preventDefault();
            const formData = new FormData(e.target);
            const { email, password } = Object.fromEntries(formData.entries());
            onFormSubmit({ email, password });
          }}
        >
          <Typography component="h2" variant="h4">
            {isSignup ? "Sign Up" : "Login"}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {isSignup
              ? <>
                <ol>
                  <li>Type in email/password</li>
                  <li>Type in invite code</li>
                  <li>Click Validate to confirm your invite code</li>
                  <li>Click "Create Account"</li>
                </ol>
              </>
              : <ul>
                <li>Login with email/password</li>
                <li>Continue as guest</li>
                <li>Sign up for an account with an invite code</li>
              </ul>}
          </Typography>
          <NonAuthErrorAlert />
          <TextField
            id="input-email"
            name="email"
            label="Email Address"
            variant="outlined"
            error={Boolean(error.email)}
            helperText={error.email ?? ""}
          />
          <TextField
            id="input-password"
            data-testid="input-password"
            type={showPassword ? "text" : "password"}
            name="password"
            label="Password"
            variant="outlined"
            error={Boolean(error.password)}
            helperText={error.password ?? ""}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleShowPassword}
                    onMouseDown={(e) => {
                      e.preventDefault();
                    }}
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {isSignup && <div className="flex gap-3">
            <TextField
              className={`flex flex-grow-1 ${shakeCode ? 'shake' : ''}`}
              id="input-invite-code"
              name="inviate-code"
              label="Invite Code"
              variant="outlined"
              error={!inviteValid}
              onChange={(e) => setInviteCode(e.target.value)}
              helperText={error.inviteCode ?? ""}
            />
            <Button variant="contained"
              color="primary"
              onClick={validateInviteCode}
            >Validate</Button>
          </div>}
          <Button
            id="submit-button"
            data-testid="submit-button"
            type="submit"
            variant="contained"
            color="primary"
            disabled={!inviteValid && isSignup}
          >
            {isSignup ? "Create Account" : "Log In"}
          </Button>
          <Button
            variant="contained"
            color="info"
            onClick={handleAnonLogin}
          >
            Continue As Guest
          </Button>
          <button
            id="toggle-auth-type-button"
            type="button"
            className="link-button"
            onClick={() => toggleIsSignup()}
          >
            {isSignup
              ? "Already have an account? Log In"
              : "Sign up for an account"}
          </button>
        </form>
      </Card>
      {/* <MoreInfoDocsLink /> */}
    </Container>
  );
}

function handleAuthenticationError(err, setError) {
  const handleUnknownError = () => {
    setError((prevError) => ({
      ...prevError,
      other: "Something went wrong. Try again in a little bit.",
    }));
    console.warn(
      "Something went wrong with a login or signup request. See the following error for details."
    );
    console.error(err);
  };
  if (err instanceof Realm.MongoDBRealmError) {
    const { error, statusCode } = err;
    const errorType = error || statusCode;
    switch (errorType) {
      case "invalid username":
      case "email invalid":
        setError((prevError) => ({
          ...prevError,
          email: "Invalid email address.",
        }));
        break;
      case "invalid username/password":
      case "invalid password":
      case 401:
        setError((prevError) => ({
          ...prevError,
          password: "Incorrect password.",
        }));
        break;
      case "name already in use":
      case 409:
        setError((prevError) => ({
          ...prevError,
          email: "Email is already registered.",
        }));
        break;
      case "password must be between 6 and 128 characters":
      case 400:
        setError((prevError) => ({
          ...prevError,
          password: "Password must be between 6 and 128 characters.",
        }));
        break;
      default:
        handleUnknownError();
        break;
    }
  } else {
    handleUnknownError();
  }
}
