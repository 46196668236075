import axios, { AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import { g_api } from "../globals/api";
import { useApp } from "../components/RealmApp";
import { toastError } from "../components/utils/toastUtils";


export default function useProjectsData() {
    const { toast } = useApp();
    const [projects, setProjects] = useState([]);
    const [fetchTrigger, setFetchTrigger] = useState<Boolean>(false); // State to trigger refetch

    const fetchData = useCallback(() => {
        axios.get(`${g_api.root}/ztkm/allprojects`, {
            withCredentials: true
        }).then((ResData: AxiosResponse) => {
            if (!ResData?.data?.projects) setProjects([]);
            const projectObject = ResData.data.projects.map((project: string) => {
                return { name: project, code: 'sd' }
            })
            projectObject.reverse();
            setProjects(projectObject);
        }).catch(error => {
            if(error?.code === 'ERR_NETWORK'){
                toastError(toast, "SSL Error with API Backend.", "SSL ERROR:")
            }
            console.log("Error fetching findall: ", error);
        })
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchTrigger, fetchData]);

    const refetch = () => {
        setFetchTrigger(prev => !prev); // Trigger refetch by toggling fetchTrigger
    };

    return { projects, refetch }
}