import React, { useState, useRef, useEffect, Ref, RefObject, MutableRefObject, ChangeEventHandler, ReactElement } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FaCircleInfo } from "react-icons/fa6";
import { FilterMatchMode } from 'primereact/api';
import { RxTarget } from "react-icons/rx";
import { PiTarget } from "react-icons/pi";
import { MdPeople } from "react-icons/md";

import './ZTPage.css';

import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { T_ZTActivityElement } from "./ZTAcitivtyPage";
import { isRegisteredUser } from "../utils/userUtils";

export type T_ZTActivityTable = {
    aId: string;
    cId: string;
    name: string;
    pillar: "1" | "2" | "3" | "4" | "5" | "6" | "7";
    isMPEBase: boolean;
    level: "target" | "advanced";
    capName: string;
}


export const IconLevelBody = (data:T_ZTActivityTable | T_ZTActivityElement) => {

    return (
      <div style={{ marginLeft: '0.5rem', display: 'flex', flexDirection: 'row' }}>
        {data?.level === 'target' && <RxTarget />}
        {data?.level === 'advanced' && <PiTarget />}
        {data?.isMPEBase && <MdPeople style={{ marginLeft: '0.25rem' }} />}
        {/* {data?.level === 'target' && 'T'}
        {data?.level === 'advanced' && 'A'}
        {data?.isMPEBase && 'M'} */}
      </div>
    )
  }

const CapabilityIconHelper = ({ cId, ztActions, tooltip }: any) => {
    const [hovered, setHovered] = useState(false);
    const [content, setContent] = useState(null);

    useEffect(() => {
        if (!hovered) return;
        let isMounted = true; // Track if the component is still mounted

        if (content) {
            tooltip.set(content);
            return;
        }
        ztActions.getCapabilityDesc(cId).then((data: any) => {
            if (isMounted) {
                const desc = data?.[0]?.description;
                setContent(desc);
                tooltip.set(desc);
            }
        })

        return () => {
            isMounted = false;
        };

    }, [hovered, content, cId, ztActions, tooltip])

    let timeoutId: any;

    const handleMouseEnter = () => {
        // Set a timeout to trigger the function after 150ms
        timeoutId = setTimeout(() => {
            setHovered(true);
        }, 100);
    };

    const handleMouseLeave = () => {
        // Clear the timeout if the mouse leaves before 150ms
        clearTimeout(timeoutId);
        setHovered(false);
    };

    return (
        <FaCircleInfo
            className="zt-tooltip"
            style={{ cursor: 'help', width: '2rem' }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        />
    )
}

export const scrollToRow = (index: number, ref: RefObject<DataTable<any>>) => {
    const tableElement = ref?.current?.getElement();
    const rowElement = tableElement?.querySelector(`.tr-pillar-${index}.start`);
    rowElement && rowElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

export const scrollToActivity = (aId: string, ref: RefObject<DataTable<any>>) => {
    const tableElement = ref?.current?.getElement();
    const rowElement = tableElement?.querySelector(`.act-${aId.replace(/\./g, '-')}`);
    rowElement && rowElement.scrollIntoView({ behavior: 'instant', block: 'nearest' });
};

export const TableIconType = (value:string) => {
    if (value.length == 0) { return 'pi pi-search' }
    return 'pi pi-times hover-curser';
}

type T_FilterMultiSelect = {
    name: 'MPE Baseline' | 'Target' | 'Advanced';
    code: 'MB' | 'TZ';
    icon: ReactElement;
}

const filterMultiSelect: T_FilterMultiSelect[] = [
    { name: 'MPE Baseline', code: 'MB', icon: <MdPeople /> },
    { name: 'Target', code: 'TZ', icon: <RxTarget /> },
    { name: 'Advanced', code: 'TZ', icon: <PiTarget /> }
];

export default function ZTActivityTable({ rows, selectedPillar, setSelectedActivity, selectedActivity, ztActions, tooltip, user }: any) {
    
    const dataTableRef = useRef<DataTable<any>>(null);
    const refMultiSelect = useRef(null);
    const isUser = isRegisteredUser(user);

    // const [selectedZT, setSelectedZT] = useState(null);
    const [searchInput, setSearchInput] = useState('');
    const [filterCount, setFilterCount] = useState(rows.length || 0);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        level: { value: null, matchMode: FilterMatchMode.IN }, // target or advanced
        isMPEBase: { value: null, matchMode: FilterMatchMode.EQUALS }, // true or false
    });
    const [selectActivityFilter, setSelectActivityFilter] = useState(filterMultiSelect); 

    useEffect(() => {
        scrollToRow(selectedPillar, dataTableRef);
    }, [selectedPillar])

    useEffect(() => {
        if (!selectedActivity?.aId) return;
        scrollToActivity(selectedActivity.aId, dataTableRef);
    }, [selectedActivity?.aId])

    useEffect(() => {
        const selectListArray = selectActivityFilter.map(item => item.name) || [];
        const selectList = new Set(selectListArray);
        let _filters:any = { ...filters };
        let levelArray:string[] = [];

        selectList.forEach(entry => {
            if (entry === 'MPE Baseline') {
                _filters.isMPEBase.value = true;
            } else {
                _filters.isMPEBase.value = null;
            }
            if (entry === 'Target' || entry === 'Advanced') levelArray.push(entry.toLowerCase());
        })

        _filters.level.value = levelArray;

        setFilters(_filters);

    }, [selectActivityFilter])


    const onGlobalFilterChange = (e:any) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setSearchInput(value);
    };

    const clearGlobalFilter = () => {
        let _filters:any = { ...filters }
        _filters.global.value = '';
        setFilters(_filters);
        setSearchInput('');
    }

    const multiSelectItemTemplate = (option:T_FilterMultiSelect) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {option.icon}
                <div style={{ marginLeft: '0.5rem' }}>{option.name}</div>
            </div>
        )
    }

    const customSelectedItemTemplate = (options:T_FilterMultiSelect) => {
        if (!options) return <i className="pi pi-filter" />
        return <>{options?.icon && options.icon}</>
    }

    // @ts-ignore
    const tableHeader = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <span className="p-input-icon-right" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', width: '100%' }}>
                    <i className={TableIconType(searchInput)} onClick={clearGlobalFilter} style={{ marginRight: '1rem' }} />
                    <InputText size="small"
                        value={searchInput}
                        style={{ width: '100%' }}
                        onChange={onGlobalFilterChange}
                        placeholder={`Search by ID/Name`} />
                </span>
                <MultiSelect value={selectActivityFilter}
                    onChange={(e) => setSelectActivityFilter(e.value)}
                    options={filterMultiSelect}
                    optionLabel="name"
                    selectedItemTemplate={customSelectedItemTemplate}
                    itemTemplate={multiSelectItemTemplate}
                    className="activity-multiselect" />
            </div>
        )
    }

// Capabilities -> Mitigations||| Build bank of ZT Capabilties in industry
    const headerTemplate = (data:T_ZTActivityTable) => {
        const isFirst = data.cId.slice(-1) === '1';

        return (
            <div className={`tr-capability tr-pillar-${data.pillar} ${(isFirst) ? 'start' : ''}`}>
                <CapabilityIconHelper cId={data.cId} ztActions={ztActions} tooltip={tooltip} />
                <div>
                    {`${data.cId} ${data.capName}`}
                </div>
            </div>
        )
    }

    const footerTemplate = () => {
        return (
            <>
                ZT Activites: {filterCount}/{rows.length}
            </>
        )
    }

    const activityHeader = ((data:T_ZTActivityTable) => {
        return (
            <div className={`tr-pillar-${data.pillar} act-${data.aId.replace(/\./g, '-')}`}>
                {`${data.aId} ${data.name}`}
            </div>
        )
    })

    return (
        <DataTable ref={dataTableRef} value={rows}
            globalFilterFields={['aId', 'name', 'capName']}
            filters={filters}
            tableStyle={{ maxWidth: '30rem' }}
            sortField="aId"
            onValueChange={(e) => { setFilterCount(e.length) }}
            size="small"
            className="zt-table-selection"
            selection={selectedActivity}
            onSelectionChange={(e) => {
                setSelectedActivity(e?.value);
            }}
            header={tableHeader}
            dataKey="aId"
            rowGroupHeaderTemplate={headerTemplate}
            rowGroupMode="subheader"
            groupRowsBy="cId"
            sortOrder={1}
            footer={footerTemplate}
        >
            <Column selectionMode="single" header={null}></Column>
            <Column field="aId" header={null} body={activityHeader}></Column>
            <Column field="level" body={IconLevelBody}></Column>
        </DataTable>
    );
}