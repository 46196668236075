import { useEffect } from "react";
import StackedBarChart from "../ChartComponents/StackedBarChart";
// import { createSwapy } from 'swapy'

// import './SurveyReport.css';
import ChartArtifactsCollected from "../ChartComponents/ChartArtifactsCollected";
import ChartCapabilitiesCollected from "../ChartComponents/ChartCapabailitiesCollected";
import ChartTableCapabilities from "../ChartComponents/ChartTableCapabilities";
import ChartPieImplementedActivities from "../ChartComponents/ChartPieImplementedActivities";
import ChartPieOutcomesTotal from "../ChartComponents/ChartPieOutcomesTotal";
import ChartCountUnaccountedActivities from "../ChartComponents/ChartCountUnaccountedActivities";

const SurveyReport = () => {

    return (
        <div style={{ width: '100%', height: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
            <div className="grid p-3" style={{ maxWidth: 'var(--width-survey)' }}>

                <ChartArtifactsCollected />
                <ChartCapabilitiesCollected />
                <ChartCountUnaccountedActivities />

                <ChartPieImplementedActivities />
                <ChartPieOutcomesTotal />

                <StackedBarChart />
                
                <ChartTableCapabilities />
            </div>
        </div>
    )
}

export default SurveyReport;