import { ActivityOutcomeStatus } from "../SurveyTool/ZTSurveyActivitiesComponent";
import { T_ACStatusData } from "./reducerZTAC";

type T_ACStatusProps = {
    code: string;
    criteria: T_ACStatusData[];
    dispatch?: React.Dispatch<any>;
}

const ACStatus = ({ code, criteria, dispatch }: T_ACStatusProps) => {
    
    const status = criteria?.find(x => x.code === code) || { code, value: 'No' };

    const handleUpdate = (newStatus:string) => {
        if(!dispatch) return;
        dispatch({ type: 'changeCriteria', payload: { ...status, value: newStatus } })
    }

    return (
        <ActivityOutcomeStatus status={status.value}
            updateStatus={handleUpdate} />
    )
}

export default ACStatus;