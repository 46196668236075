import { Dialog } from "primereact/dialog"
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { useReducer } from "react";
import reducerSurveyExportRFI, { emptyStateExportRFI } from "./reducerSurveyExportRFI";
import { useApp } from "../RealmApp";
import { getZTProfileDataCache } from "../DataManagement/DataManagement";
import { useApi } from "../ProviderAPIs";
import { Button } from "primereact/button";
import { AxiosResponse } from "axios";
import { TabPanel, TabView } from "primereact/tabview";
import { toastError } from "../utils/toastUtils";

type T_SurveyExportRFIProps = {
    show: boolean;
    onHide: () => void;
}

const exportTabIndex = [
    'project',
    'profile',
]

const SurveyExportRFI = ({ show, onHide }: T_SurveyExportRFIProps) => {
    const { ztProfiles, activeProject, toast }: any = useApp();
    const projectName = activeProject?.selection?.name;
    const [state, dispatch] = useReducer(reducerSurveyExportRFI, { ...emptyStateExportRFI, project: projectName });
    const optionProfiles = ztProfiles.map((x: any) => ({ name: x.name }));
    const api: any = useApi();

    const handleProfileUpdate = async (e: DropdownChangeEvent) => {
        await getZTProfileDataCache(e.value.name, api.getZTProfileData);
        dispatch({ type: 'update', field: 'profile', payload: e.value.name })
    };

    const handleExportRFI = () => {
        try {
            api.postExportRFI(state)
                .then((res: AxiosResponse<Blob>) => {
                    const contentDisposition = res.headers['content-disposition'];
                    let filename = 'output.xlsx'; // Fallback filename

                    if (contentDisposition) {
                        const filenameMatch = contentDisposition.match(/filename="(.+?)"/);
                        if (filenameMatch && filenameMatch.length > 1) {
                            filename = filenameMatch[1];
                        }
                    }

                    // Get the response as a blob
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;

                    // Suggest the filename for the download
                    link.setAttribute('download', filename);

                    // Append the link to the body
                    document.body.appendChild(link);

                    // Programmatically click the link to trigger the download
                    link.click();

                    // Remove the link from the document
                    link?.parentNode?.removeChild(link);
                })
                .catch((err: any) => {
                    err.response.data.text()
                        .then((errDataRaw: any) => {
                            const errData: any = JSON.parse(errDataRaw);
                            toastError(toast, errData?.msg || 'Unknown Error', errData?.error || 'Error');
                        })
                    console.log(err);
                });
        } catch (err: any) {
            toastError(toast, 'Unknown Error', 'Error');
        }
    }

    return (
        <Dialog header="Export" visible={show} onHide={onHide} contentClassName="flex flex-column gap-2 justify-content-center align-items-center w:[50px]">
            <TabView activeIndex={state.activeIndex} onTabChange={(e: any) => dispatch({
                type: 'exportType',
                payload: { project: projectName, type: exportTabIndex?.[e.index] || 'profile', index: e.index }
            })}>
                <TabPanel header="Project">
                    <h4>Export project data to Excel.</h4>
                </TabPanel>
                <TabPanel header="By Profile">
                    <h4>Export empty RFI Excel template based on profile.</h4>
                    <Dropdown value={{ name: state.profile }} onChange={handleProfileUpdate} options={optionProfiles} optionLabel="name"
                        placeholder="Filter by Profile" className="w-full" />
                </TabPanel>
            </TabView>
            <Button label='Export' icon='pi pi-file-export' onClick={handleExportRFI} />
        </Dialog>
    )
}

export default SurveyExportRFI;