import { Badge, BadgeProps } from "primereact/badge";
import { Inplace, InplaceContent, InplaceDisplay } from "primereact/inplace";
import { InputTextarea } from "primereact/inputtextarea";
import { Panel } from "primereact/panel";
import { SelectButton } from "primereact/selectbutton";
import { Dispatch, SetStateAction, useEffect, useLayoutEffect, useMemo, useReducer, useRef, useState } from "react";
import UploadFile from "../UploadFile/UploadFile";
import { Dropdown } from "primereact/dropdown";
import { CiFileOn } from "react-icons/ci";
import { RiQuestionAnswerLine } from "react-icons/ri";
import { toastError } from "../utils/toastUtils";
import { useApp } from "../RealmApp";
import useModifyZTKMData from "../../hooks/useModifyZTKMData";
import { closeContextIcon, openContextIcon } from "./ZTSurveyCapabilityComponent";
import reducerEditActivity, { emptyEditActivityReducer } from "./reducerEditActivity.tsx";
import { Button } from "primereact/button";
import './ZTSurveyActivitiesComponent.css';
import { SplitButton } from "primereact/splitbutton";
import { ToggleButton } from "primereact/togglebutton";
import { isLocal } from "../utils/appUtils";
import { formatFileSize } from "../utils/miscUtils";
import { g_api } from "../../globals/api";

import { CiBookmarkRemove, CiBookmarkPlus } from "react-icons/ci";
import { T_CapabilityOverview } from "./reducerEditCapability";
import { Card } from "primereact/card";
import LinkArtifactComponent from "./LinkArtifactComponent";

import demoData from '../demoData.json';
import { useAPIHandler } from "../../hooks/useAPIHandler";


export type T_FileObjectBackend = {
    destination: string;
    encoding: string;
    fieldname: string;
    filename: string;
    mimetype: string;
    originalname: string;
    path: string;
    size: number;
}

export type T_MaturityObject = {
    status: "Incomplete" | "Not Planned" | "Planning" | "Procured" | "In Progress" | "Not Integrated" | "Integrated" | "Inherited";
    code: string;
    severity: string;
    score: number;
}

export type T_StatusObject = {
    status: "Answered" | "Skipped" | "Incomplete" | "NA";
    severity: string;
    code: string;
}

export const rand = (low: number, high: number) => Math.floor(Math.random() * high) + low;

export const surveyStatuses: T_StatusObject[] = [
    { status: 'Answered', severity: 'success', code: 'cp' },
    { status: 'Skipped', severity: 'info', code: 'sk' },
    { status: 'Incomplete', severity: 'secondary', code: 'in' },
    { status: 'NA', severity: 'warning', code: 'na' },
]

const maturityRatings: T_MaturityObject[] = [
    { status: 'Incomplete', severity: 'danger', code: 'ie', score: 0 },
    { status: 'Not Planned', severity: 'danger', code: 'np', score: 0 },
    { status: 'Planning', severity: 'warning', code: 'pl', score: 1 },
    { status: 'Procured', severity: 'secondary', code: 'pr', score: 2 },
    { status: 'In Progress', severity: 'info', code: 'ip', score: 3 },
    { status: 'Not Integrated', severity: 'help', code: 'ni', score: 4 },
    { status: 'Integrated', severity: 'success', code: 'in', score: 5 },
    { status: 'Inherited', severity: 'success', code: 'in', score: 5 },
]

export const getStatusObject = (name: string): T_StatusObject => {
    return surveyStatuses.find((obj: T_StatusObject) => obj.status === name) || surveyStatuses[2];
}

export const getMaturityObject = (name: string): T_MaturityObject => {
    return maturityRatings.find((obj: T_MaturityObject) => obj.status === name) || maturityRatings[0];
}

// const scrollToActivity = (ref: any) => {
//     const tableElement = ref?.current?.getElement();
//     // const rowElement = tableElement?.querySelector(`.sId-${sId}`);
//     tableElement && tableElement.scrollIntoView({ behavior: 'instant', block: 'start' });
// };

type T_StatusDropwdown = {
    maturity: T_MaturityObject;
    onChange: (newStatus: T_MaturityObject) => void;
}

export const badgeTemplateSurvey = (option: T_StatusObject) => (
    // @ts-ignore
    <Badge value={option.status} severity={option.severity} className="" />
)

export const StatusDropdown = ({ maturity, onChange }: T_StatusDropwdown) => (
    <Dropdown value={maturity} onChange={(e) => onChange(e.value)}
        options={maturityRatings} optionLabel="name"
        placeholder="Maturity" panelClassName="survey-dropdown"
        dropdownIcon={dropDownIconSurvey} valueTemplate={badgeTemplateSurvey} itemTemplate={badgeTemplateSurvey}
        className=""
        style={{ borderRadius: '1rem', backgroundColor: `var(--color-badge-${maturity.severity})`, borderColor: 'transparent', width: 'fit-content', height: 'fit-content' }} />
)

export const dropDownIconSurvey = (option: any) => (
    <div className={`pi pi-chevron-down`} style={{ color: 'white' }}></div>
)

type T_ActivityContext = {
    aId: string;
    assessmentPlan: string[];
    cId: string;
    description: string;
    isMPEBase: boolean;
    level: string;
    m: number;
    mpeOutcomes: string[];
    name: string;
    nist: string[];
    outcomes: string[];
    phase: number;
    pillar: string;
    predecessor: string[];
    successor: string[];
    survey: {
        questions: {
            question: string;
            context: string;
        }[];
        artifacts: {
            artifact: string;
            context: string;
        }[];
    };
    type: string;
}

const emptyActivityContext: T_ActivityContext = {
    aId: '',
    assessmentPlan: [],
    cId: '',
    description: '',
    isMPEBase: false,
    level: '',
    m: 0,
    mpeOutcomes: [],
    name: '',
    nist: [],
    outcomes: [],
    phase: 0,
    pillar: '',
    predecessor: [],
    successor: [],
    survey: {
        questions: [],
        artifacts: [],
    },
    type: '',
};

const demoDataObject = { ...demoData };

type T_ActivityOutcomeStatusProps = {
    status: 'No' | 'Partial' | 'Yes';
    updateStatus: (status: string) => void;
}

const outcomeStatusLookup: any = {
    'No': 'secondary',
    'Partial': 'warning',
    'Yes': 'success',
}

export const ActivityOutcomeStatus = ({ status, updateStatus }: T_ActivityOutcomeStatusProps) => {

    const handleClick = () => {
        if (status === 'No') {
            updateStatus('Partial');
        } else if (status === 'Partial') {
            updateStatus('Yes');
        } else {
            updateStatus('No');
        }
    };

    return (
        <Button size="small" outlined
            onClick={handleClick}
            severity={outcomeStatusLookup?.[status] || 'secondary'}
            label={status}
            style={{ minWidth: '60px', padding: '0.25rem' }} />
    )
}

const ZTSurveyActivityComponent = ({ data, tag }: any) => {
    const { toast, activeProject, analytics, refreshProjectData, ztProfileIds }: any = useApp();
    const { getActivity, getDataResponse } = useAPIHandler();
    const statusObj = analytics.find((x: any) => x.ztId === data.aId) || null;
    const status = getStatusObject(statusObj?.status);
    const maturity = getMaturityObject(statusObj?.maturity);
    const projectName = activeProject?.selection?.name;

    const [state, dispatch] = useReducer(reducerEditActivity, emptyEditActivityReducer)
    const [activityContext, setActivityContext] = useState<T_ActivityContext>(emptyActivityContext);
    const [showContext, setShowContext] = useState<boolean>(false);
    const panelRef = useRef<any>(null);
    const originalActivityData = useRef(emptyEditActivityReducer); // Set original data so if a user discards their response, you can restore back to the original.
    const modifyResponse = useModifyZTKMData();
    const inScope = ztProfileIds.includes(data.aId);
    const statusInScope = (inScope || statusObj) ? status : getStatusObject('NA')

    const listCapabilities: any = useMemo(() => {
        if (!activityContext.aId || activityContext.aId === '') return [];
        const ztCapabilities = activeProject?.ztCapabilities?.filter((x: any) => x.ztcIds.includes(data.cId)) || [];
        if (ztCapabilities.length === 0) return [{ name: 'None' }];
        return ztCapabilities;
    }, [activityContext.aId])

    const saveActivityButtonSelection = [
        {
            label: 'Discard',
            icon: 'pi pi-times',
            command: () => {
                dispatch({ type: 'init', payload: { ...originalActivityData.current, updated: false } })
            }
        },
    ]

    const getActivityData = (command?: string) => {
        if (!projectName) return;
        if (!isLocal) {
            if (!demoDataObject?.data) return;
            let demoActivity: any = demoDataObject.data.find((x: any) => x.ztId === data.aId);
            if (!demoActivity) return;
            originalActivityData.current = { ...demoActivity };
            dispatch({ type: 'init', payload: demoActivity })
            return;
        }
        getDataResponse(projectName, data.aId, 'activity').then((data: any) => {
            if (command === 'fileOnly') data = { ...state, 'files': data?.files || [] }
            originalActivityData.current = { ...data };
            dispatch({ type: 'init', payload: data })
        })
    }
    
    const handleOpen = async () => {
        getActivityData();
        if (activityContext.aId !== '') return;
        try {
            getActivity(data.aId, true).then((res: any) => {
                setActivityContext(res[0]);
            })
        } catch (err) {
            console.log(err);
        }
    }

    const handleStatusChange = (obj: any) => {
        const response = {
            ztId: data.aId,
            status: obj.status,
        };
        modifyResponse([response])
            .then((res: any) => {
                refreshProjectData();
            })
            .catch((err) => {
                toastError(toast, err?.response?.data?.message || err?.message || 'Unknown Error', err?.code || 'Error');
            })
    }

    const header = (options: any) => {
        const className = `${options.className} justify-content-space-between gap-2 p-0`;
        const togglePanel = () => {
            if (options.collapsed) handleOpen();
            options.onTogglerClick()
        }
        return (
            <div className={className} style={{ backgroundColor: `var(--color-p${data.pillar})` }}>

                <div className="flex align-items-center" style={{ cursor: 'pointer', padding: '1rem', width: '100%' }} onClick={togglePanel}>
                    {tag &&
                        <div className={`at-ztac-mark ${tag}`} title="ZTAC either (P)artially maps to ZT Activitiy or (S)atisfies it.">{tag}</div>
                    }
                    <span className="font-bold">{data.aId}: {data.name}</span>
                </div>
                {(status.status === 'Answered') &&
                    <div className="flex flex-column">
                        {(!statusObj.hasNote) &&
                            <RiQuestionAnswerLine color="red" />
                        }
                        {(!statusObj.hasFiles) &&
                            <CiFileOn color="red" />
                        }
                    </div>
                }
                <div className="flex flex-column justify-content-center align-items-center">
                    <Dropdown value={statusInScope} onChange={(e) => handleStatusChange(e.value)} options={surveyStatuses} optionLabel="status" placeholder="Status" panelClassName="survey-dropdown" dropdownIcon={dropDownIconSurvey}
                        valueTemplate={badgeTemplateSurvey} itemTemplate={badgeTemplateSurvey} className="w-full" style={{ borderRadius: '1rem', backgroundColor: `var(--color-badge-${statusInScope.severity})`, borderColor: 'transparent' }} />
                    {(status.status === 'Answered') &&
                        <div className=" flex flex-row font-italic gap-1 align-items-center" style={{ fontSize: '0.75rem', textDecoration: 'italic' }}>
                            {/* @ts-ignore */}
                            <Badge value="" severity={maturity.severity}></Badge>
                            <div>{maturity.status}</div>
                        </div>
                    }
                </div>
                <div style={{ paddingRight: '1rem' }}>
                    {(inScope && status.status === 'NA') &&
                        <CiBookmarkRemove color='var(--color-badge-danger)' size={'1.5rem'} title="Profile/baseline deviation. This activity is removed from your baseline." />
                    }
                    {(!inScope && statusObj) &&
                        (status.status !== 'NA') &&
                        <CiBookmarkPlus color='var(--color-badge-success)' size={'1.5rem'} title="Profile/baseline deviation. This activity is added to your baseline." />
                    }
                </div>
            </div>
        )
    }

    const handleSaveResponse = () => {
        let statusUpdate = {};
        if (state.maturity !== 'Incomplete') statusUpdate = { status: 'Answered' };
        modifyResponse([{ ...state, ztId: data.aId, ...statusUpdate}])
            .then((res: any) => {
                refreshProjectData();
                getActivityData();
            })
            .catch((err) => {
                toastError(toast, err?.response?.data?.message || err?.message || 'Unknown Error', err?.code || 'Error');
            })
    }


    const handleUploadSuccess = (fileData: any) => {
        if (!fileData?.response?.files) return;

        const uploadFiles = fileData.response.files.map((file: any) => file.backendName);

        modifyResponse([{
            ztId: data.aId,
            files: uploadFiles,
        }])
            .then((res: any) => {
                refreshProjectData();
                getActivityData('fileOnly');
            })
            .catch((err) => {
                toastError(toast, err?.response?.data?.message || 'Unknown Error', err?.code || 'Error');
            })
    }

    const handleLinkArtifact = (e: any) => {
        const file = e?.value?.[0]?.backendName;
        if (!file) return;
        handleUploadSuccess({
            'response': {
                'files': e.value
            }
        });
    }

    const footer = () => (
        state.updated ?
            <SplitButton onClick={handleSaveResponse} className="act-save-popup" size="small" label={`Save Changes for ${data.aId}`} icon="pi pi-plus" model={saveActivityButtonSelection} severity="success" rounded />
            :
            <></>
    )

    return (
        <Panel ref={panelRef} footerTemplate={footer} headerTemplate={header} toggleable collapsed={true} className={`activity-component flex flex-column zt-inline-custom`} aria-roledescription={`sid-${data.aId}`}>
            <Inplace closable closeIcon={closeContextIcon} onOpen={() => setShowContext(true)} onClose={() => setShowContext(false)} className="survey-inplace">
                <InplaceDisplay>{openContextIcon}</InplaceDisplay>
                <InplaceContent>
                    <div>
                        <h3>Description:</h3>
                        {activityContext?.description}
                    </div>
                    <div className="m-4"></div>
                </InplaceContent>
            </Inplace>
            <div className="flex flex-column">
                <div className="flex flex-column justify-content-between">
                    <div className="activity-outcomes">
                        <h3>Outcomes: (Implemented)</h3>
                        <ol>
                            {activityContext?.outcomes?.map((outcome: string, index: number) => {
                                const outcomeNumber = index + 1;
                                const outcomeValue = state.outcomes.find((x: any) => x.index === outcomeNumber) || { index: outcomeNumber, value: 'No' };
                                return (
                                    <div key={`outm-${data.aId}-${index}-${projectName}`} className="flex gap-2 align-items-center outcome-toggle" style={{ marginBottom: '1rem' }}>
                                        <ActivityOutcomeStatus status={outcomeValue.value}
                                            updateStatus={(newStatus) => dispatch({ type: 'changeOutcome', payload: { ...outcomeValue, value: newStatus } })} />
                                        <li key={`${data.aId}-out-${index}`} style={{ marginLeft: '20px' }}>{outcome}</li>
                                    </div>
                                )
                            })}
                        </ol>
                    </div>
                    <div className="activity-questions">
                        <h3>Questions:</h3>
                        <ol>
                            {activityContext?.survey?.questions?.map((elm: any, index: number) => (
                                <div key={`${data.aId}-qu-${index}`}>
                                    <li>{elm.question}</li>
                                    {showContext && <div className="m-2 font-italic" style={{ color: '#4b5563bd' }}><strong>Context:</strong> {elm.context}</div>}
                                </div>
                            )
                            )}
                        </ol>
                    </div>
                </div>
                <div className="flex flex-column justify-content-center text-center">
                    <div className="flex flex-row justify-content-center align-items-center gap-3">
                        <h3>Response:</h3>
                        <StatusDropdown maturity={getMaturityObject(state.maturity)} onChange={(e) => dispatch({ type: 'update', field: 'maturity', payload: e.status })} />
                    </div>
                    <InputTextarea value={state.note} rows={8} onChange={(e) => dispatch({ type: 'update', field: 'note', payload: e.target.value })} />
                </div>
                <div className="flex flex-column">
                    <h4>Capabilities:</h4>
                    <div className="flex flex-row gap-2">
                        {listCapabilities.map((capabilityObject: T_CapabilityOverview) => (
                            <div className="p-card p-2"
                                key={`aclink-${data.aId}-${capabilityObject.name}`}>
                                {capabilityObject.name}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex flex-column justify-content-center gap-2">
                    <div className="activity-artifacts">
                        <h3>Artifacts:</h3>
                        <ol>
                            {activityContext?.survey?.artifacts?.map((elm: any, index: number) => (
                                <div key={`${data.aId}-art-${index}`}>
                                    <li>{elm.artifact}</li>
                                    {showContext && <div className="m-2 font-italic" style={{ color: '#4b5563bd' }}><strong>Context:</strong> {elm.context}</div>}
                                </div>
                            )
                            )}
                        </ol>
                    </div>
                    {state?.files && state.files.map((linkFile, i) => {
                        if (!activeProject?.files) return;
                        const file = activeProject.files.find((x: any) => x.backendName === linkFile);
                        if (!file) return;
                        return (
                            <div key={`f-${data.aId}-${i}-${file.filename}`}
                                className="flex flex-row gap-3 p-button p-button-secondary p-button-outlined"
                                onClick={() => window.open(`${g_api.root}${file.backendName}`, '_blank')}>
                                <i className="pi pi-file" />
                                <div>{formatFileSize(file.size || 0)}</div>
                                <div>{file?.meta?.displayName || file.filename}</div>
                            </div>
                        )
                    })}
                    <LinkArtifactComponent onChange={handleLinkArtifact} />
                    <UploadFile projectName={projectName} uploadSuccess={handleUploadSuccess} />
                </div>
            </div>
        </Panel>
    )
}

export default ZTSurveyActivityComponent;