import { WelcomePage } from "./WelcomePage";
import { ThemeProvider } from "./Theme";
import atlasConfig from "../atlasConfig.json";
import "./App.css";
import 'primeicons/primeicons.css';
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
// import "primeflex/themes/primeone-dark.css";
import { isLocal } from "./utils/appUtils";
import NavigateZT from "./NavigateZT/NavigateZT";
import ConditionalProvider from "./ConditionalProvider";
import { BrowserRouter } from "react-router-dom";
import RouteLinks from "./RouteLinks/RouteLinks";
import { ConfirmDialog } from "primereact/confirmdialog";
import { ApiProvider } from "./ProviderAPIs";
import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";
import { useApp } from "./RealmApp";
const { appId } = atlasConfig;

export default function ProvidedApp() {
  return (
    <ErrorBoundary >
      <ThemeProvider>
        <ConditionalProvider appId={appId}>
          <ApiProvider>
            <App />
          </ApiProvider>
        </ConditionalProvider>
      </ThemeProvider>
    </ErrorBoundary>
  )
}

function App() {
  const { currentUser, logOut, isLocalApp, criticalFailure } = useApp();
  // const [currentPage, setCurrentPage] = useState('activities');
  const basename = (isLocal) ? '' : '/ztkmweb';

  return (
    <div className="App">
      {!currentUser && <WelcomePage />}
      {currentUser &&
        <BrowserRouter basename={basename}>
          <div className='app-header'>
            <NavigateZT />
          </div>
          {(criticalFailure) ?
            <div style={{ padding: '2rem' }}>
              <h3>Error fetching data.</h3>
              <p>Try visiting the API Backend and appoving the SSL cert.</p>
              <a href='https://api.ztkm.local/test'>https://api.ztkm.local/test</a>
            </div>
            :
            <div className="app-body" >
              <RouteLinks />
            </div>
          }
        </BrowserRouter>
      }
      <ConfirmDialog />
    </div>
  );
}
