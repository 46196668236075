import axios from "axios";
import { g_api } from "../globals/api";

export const getZTProfiles = async () => {
    return axios.get(`${g_api.root}${g_api.getProfileNames}`, { withCredentials: true }).then(res => res.data);
}

export const getZTProfileData = async (profileName: string) => {
    return axios.get(`${g_api.root}${g_api.getProfileData(profileName)}`, { withCredentials: true }).then(res => res.data);
}
