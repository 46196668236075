import { GZT } from "../DataManagement/DataManagement";
import '../SurveyTool/EditCapabilityPopup.css';
import { formatFileSize } from "../utils/miscUtils";
import { g_api } from "../../globals/api";
import { getMaturityObject } from "./ExportActivityComponent";
import { Panel } from "primereact/panel";
import { Badge } from "primereact/badge";

export type T_FileObject = {
    filename: string;
    tags: string[];
    url: string;
    notes: string;
    size: number;
    meta: any;
    backendName: string;
    createdBy: string;
    submitDate: Date;
}

type T_CapabilityObject = {
    name: string;
    ztcIds: string[];
    status: string;
    notes: string;
    files: T_FileObject[],
    hasChanged: boolean;
    lastUpdated: Date;
}

const ExportCapabilityComponent = ({ state, activeProject }: any) => {
    const title = state.name;
    const maturity = getMaturityObject(state.status);

    const header = (options: any) => {
        const className = `${options.className} justify-content-space-between gap-2 p-0`;

        return (
            <div className={className} style={{ backgroundColor: `var(--color-v5)` }}>
                <div className="flex align-items-center" style={{ cursor: 'pointer', padding: '1rem', width: '100%' }}>
                    <span className="font-bold">{title}</span>
                </div>
                <div className="flex flex-column justify-content-center align-items-center" style={{ marginRight: '1rem' }}>
                    {/* @ts-ignore */}
                    <Badge value={maturity?.status} severity={maturity?.severity} style={{ textWrap: 'nowrap' }} />
                </div>
            </div>
        )
    }

    return (
        // <Dialog
        //     visible={visible} onHide={onClose} closeOnEscape showHeader={false}
        //     className="survey-ae-capabilty-dialog survey-activity-content flex"
        //     contentClassName="flex flex-column" contentStyle={{ padding: 0, height: '100%' }}
        //     style={{ width: '50vw', height: '100%' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}
        // >
        <Panel headerTemplate={header} className={`flex flex-column zt-inline-custom`} aria-roledescription={`rcid-${state.name}`}>
            <div className="overflow-y-auto p-3">
                <div className="flex flex-column justify-content-center align-items-center gap-1">
                    <h3>Response:</h3>
                    {(state.notes === '') ?
                        <p>None</p>
                        :
                        <p>{state.notes}</p>
                    }
                </div>


                <div className="flex flex-column justify-content-center align-content-center w-full text-center flex-wrap">
                    <h3>Linked Capabilities:</h3>
                    <div className="link-capabilities-dropdown flex flex-column gap-1">
                        {state?.ztcIds && state.ztcIds.map((ztId: string) => {
                            const capabilityObject = GZT.capabilities.find((obj: any) => obj.cId === ztId);
                            return (
                                <div key={`${state?.name}-edit-${ztId}`} className="flex flex-row gap-2 text-left justify-content-between align-items-center">
                                    <div>{ztId} {capabilityObject?.name}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="flex flex-column justify-content-center align-items-center w-full text-center gap-2">
                    <h3>Artifacts:</h3>
                    {(state?.files && state.files.length === 0) &&
                        <div>None</div>
                    }
                    {state.files.map((linkFile: any, i: any) => {
                        if (!activeProject?.files) return;
                        const file = activeProject.files.find((x: any) => x.backendName === linkFile);
                        if (!file) return;
                        const filename = file.backendName.split('/').pop();
                        return (
                            <a href={`./files/${filename}`} key={`f-${state.name}-${i}-${file.filename}`} target="_blank"
                                className="flex flex-row gap-3 p-button p-button-secondary p-button-outlined">
                                {formatFileSize(file.size || 0)} {file?.meta?.displayName || file.filename}
                            </a>
                        )
                    })}

                </div>
            </div>
        </Panel>
    )
}

export default ExportCapabilityComponent;