
export type T_ActivityReducer = {
    mpeOutcomes: string;
    assessmentPlan: string;
    showNist: boolean;
    displaySplit: boolean;
    selectedNIST: string;
    isLoadingNist: boolean;
    contentNist: any;
    pvSelected: string;
    currentActivity: string;
}

export const emptyActivtyReducer: T_ActivityReducer = {
    mpeOutcomes: '',
    assessmentPlan: '',
    showNist: false,
    displaySplit: false,
    selectedNIST: '',
    isLoadingNist: false,
    contentNist: {},
    pvSelected: '',
    currentActivity: '',
};

function toMarkup(data: string[]): string {
    if (data.length === 0) return "";
    return data.map(item => `# ${item}`).join('\n');
}

export function simpleMarkupToArray(markup: string): string[] {
    if (markup.length === 0) return []
    if (!markup.includes('#')) return [markup]
    return markup.split('\n').map(item => item.replace('# ', ''));
}

const parseNISTControlString = (str: any) => {
    // Extract the alphabetic part, the numeric part, and the numeric part in parentheses (if any)
    const match = str.match(/^([A-Z]+)-(\d+)(\((\d+)\))?$/);
    if (match) {
        const [, alpha, num, , parenNum] = match;
        return [alpha, parseInt(num, 10), parenNum ? parseInt(parenNum, 10) : 0];
    }
    return [str, 0, 0];
};

export function sortArrayNistControls(controlsObject: any): string[] {
    const array: string[] = controlsObject.map((obj: any) => obj.cId);
    return array.sort((a, b) => {
        const [alphaA, numA, parenNumA] = parseNISTControlString(a);
        const [alphaB, numB, parenNumB] = parseNISTControlString(b);

        // Compare alphabetic parts
        if (alphaA < alphaB) return -1;
        if (alphaA > alphaB) return 1;

        // Compare numeric parts
        if (numA < numB) return -1;
        if (numA > numB) return 1;

        // Compare numeric parts in parentheses
        if (parenNumA < parenNumB) return -1;
        if (parenNumA > parenNumB) return 1;

        return 0;
    });
}

export function nistControlsSortGroup(controlsObject:any){
    const sorted = sortArrayNistControls(controlsObject);
    let grouped:any = {};
    sorted.forEach((str:string) => {
        let prefix = str.substring(0,2);

        if(!grouped[prefix]){
            grouped[prefix] = [];
        }

        grouped[prefix].push(str);
    })
    return Object.values(grouped);
}

function versionAsNumbers(v:any) {
    return v.split(".").map(Number);
}

export function sortArrayActivityIds(activityObject: any, fieldName:string) {
    // Sorting the array
    return activityObject.sort((a:any, b:any) => {
        const aParts = versionAsNumbers(a[fieldName]);
        const bParts = versionAsNumbers(b[fieldName]);

        // Compare each part until we find a difference
        for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
            // Consider missing parts as 0 (e.g., "1.2" vs. "1.2.3")
            const aPart = aParts[i] || 0;
            const bPart = bParts[i] || 0;

            if (aPart !== bPart) {
                return aPart - bPart;
            }
        }

        // If all parts are equal, consider them the same
        return 0;
    });
}  

export default function activityReducer(state: T_ActivityReducer, action: any): T_ActivityReducer {
    switch (action.type) {
        case 'init':
            return {
                ...state,
                showNist: false,
                mpeOutcomes: toMarkup(action.payload?.mpeOutcomes || []),
                assessmentPlan: toMarkup(action.payload?.assessmentPlan || []),
                currentActivity: action.activity,
            }
        case 'update':
            return {
                ...state,
                [action.field]: action.payload,
            }
        case 'resetDisplay':
            return {
                ...state,
                showNist: false,
                displaySplit: false,
            }
        case 'changePV':
            return {
                ...state,
                pvSelected: action.payload,
            }
        case 'selectNist':
            return {
                ...state,
                showNist: true,
                selectedNIST: action.payload,
                isLoadingNist: true,
                pvSelected: state.currentActivity,
            }
        case 'setNistContent':
            return {
                ...state,
                isLoadingNist: false,
                contentNist: { ...action.payload },
            }

        default:
            return state;
    }
}