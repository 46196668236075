import { InputTextarea } from "primereact/inputtextarea";
import UseCaseShowButton from "./UseCaseShowButton";
import React, { Children, useContext, useReducer, useRef } from "react";
import reducerZTAC, { emptyZTACReducer } from "./reducerZTAC";
import { useApp } from "../RealmApp";
import { formatFileSize } from "../utils/miscUtils";
import { g_api } from "../../globals/api";
import UploadFile from "../UploadFile/UploadFile";
import LinkArtifactComponent from "../SurveyTool/LinkArtifactComponent";
import ACStatus from "./ACStatusButton";
import { Panel } from "primereact/panel";
import { RiQuestionAnswerLine } from "react-icons/ri";
import { CiFileOn } from "react-icons/ci";

import './ZTAC_Component.css';
import { Dropdown } from "primereact/dropdown";
import { badgeTemplateSurvey, dropDownIconSurvey, getStatusObject, surveyStatuses } from "../SurveyTool/ZTSurveyActivitiesComponent";
import { SplitButton } from "primereact/splitbutton";
import { useAPIHandler } from "../../hooks/useAPIHandler";
import { toastError } from "../utils/toastUtils";
import useModifyZTACData from "../../hooks/useModifyZTACData";
import { isLocal } from "../utils/appUtils";
import { useZTACRootContext } from "../SurveyTool/SurveyToolACProvider";

type T_ZTAC_ComponentProps = {
    acId: string;
    children: React.ReactNode;
    onClick: (acId: string) => void;
}

type T_ZTACContext = {
    state: any;
    dispatch?: React.Dispatch<any>;
}

type T_ZTACBulletProps = {
    children: React.ReactNode;
    code: string;
}

export const ZTACContext = React.createContext<T_ZTACContext | undefined>(undefined);

export const ZTACBullet = ({ code, children }: T_ZTACBulletProps) => {
    const { state, dispatch } = useZTACContext();

    return (
        <div className="flex flex-row gap-5 align-items-center">
            <ACStatus code={code} dispatch={dispatch} criteria={state.criteria} />
            <li>{children}</li>
        </div>
    )
}

export function useZTACContext() {
    const context = useContext(ZTACContext);
    if (!context) {
        throw new Error('useZTACContext must be used within a ZTACComponent');
    }
    return context;
}

type T_CodeBlockProps = {
    children: React.ReactNode;
    style?: any;
}

export const CodeBlock = ({ children, style }: T_CodeBlockProps) => {
    return (
        <div className="p-3 border-round" style={{ ...style, backgroundColor: '#211E2F', color: '#f9fafb', fontFamily: 'Consolas' }}>
            {children}
        </div>
    )
}

const ZTAC_Component = ({ children, acId, onClick }: T_ZTAC_ComponentProps) => {
    const { activeProject, toast, refreshProjectData }: any = useApp();
    const { refetchZTACAnalytics, ztacAnalytics } = useZTACRootContext();
    const [state, dispatch] = useReducer(reducerZTAC, emptyZTACReducer);
    const projectName = activeProject?.selection?.name;
    const panelRef = useRef<any>(null);
    const originalZTACData = useRef(emptyZTACReducer); // Set original data so if a user discards their response, you can restore back to the original.
    const modifyZTACData = useModifyZTACData();
    const { getDataResponse } = useAPIHandler();

    const statusObj = ztacAnalytics.find((x: any) => x.ztacId === acId) || null;
    const status = getStatusObject(statusObj?.status);

    const childrenArray = React.Children.toArray(children);

    const handleUploadSuccess = (fileData: any) => {
        if (!fileData?.response?.files) return;

        const uploadFiles = fileData.response.files.map((file: any) => file.backendName);

        modifyZTACData([{
            ztacId: acId,
            files: uploadFiles,
        }])
            .then((res: any) => {
                refreshProjectData();
                getZTACData('fileOnly');
            })
            .catch((err) => {
                toastError(toast, err?.response?.data?.message || 'Unknown Error', err?.code || 'Error');
            })
    }

    const handleLinkArtifact = (e: any) => {
        const file = e?.value?.[0]?.backendName;
        if (!file) return;
        handleUploadSuccess({
            'response': {
                'files': e.value
            }
        });
    }

    const getZTACData = (command?: string) => {
        if (!projectName) return;
        // if (!isLocal) {
        //     if (!demoDataObject?.data) return;
        //     let demoActivity: any = demoDataObject.data.find((x: any) => x.ztId === data.aId);
        //     if (!demoActivity) return;
        //     originalZTACData.current = { ...demoActivity };
        //     dispatch({ type: 'init', payload: demoActivity })
        //     return;`
        // }
        getDataResponse(projectName, acId, 'ztac').then((data: any) => {
            if (command === 'fileOnly') data = { ...state, 'files': data?.files || [] }
            originalZTACData.current = { ...data };
            dispatch({ type: 'init', payload: data })
        })
    }

    const handleOpen = async () => {
        onClick(acId);
        getZTACData();
        // getActivityData();
        // if (activityContext.aId !== '') return;
        // try {
        //     ztActions.getActivity(data.aId, true).then((res: any) => {
        //         setActivityContext(res[0]);
        //     })
        // } catch (err) {
        //     console.log(err);
        // }
    }

    const handleStatusChange = (obj: any) => {
        const response = {
            ztacId: acId,
            status: obj.status,
        };
        modifyZTACData([response])
            .then((res: any) => {
                refetchZTACAnalytics();
            })
            .catch((err) => {
                toastError(toast, err?.response?.data?.message || err?.message || 'Unknown Error', err?.code || 'Error');
            })
    }

    const header = (options: any) => {
        const className = `${options.className} justify-content-space-between gap-2 p-0`;
        const togglePanel = () => {
            if (options.collapsed) handleOpen();
            options.onTogglerClick()
        }
        return (
            <div className={className} style={{ backgroundColor: `var(--color-v5)` }}>
                <div className="flex flex-column" style={{ cursor: 'pointer', padding: '1rem', width: '100%' }} onClick={togglePanel}>
                    {childrenArray[0]}
                </div>
                {(status.status === 'Answered') &&
                    <div className="flex flex-column">
                        {(!statusObj.hasNote) &&
                            <RiQuestionAnswerLine color="red" />
                        }
                        {(!statusObj.hasFiles) &&
                            <CiFileOn color="red" />
                        }
                    </div>
                }
                <div className="flex flex-column justify-content-center align-items-center" style={{ marginRight: '1rem' }}>
                    <Dropdown value={status} onChange={(e) => handleStatusChange(e.value)} options={surveyStatuses} optionLabel="status" placeholder="Status" panelClassName="survey-dropdown" dropdownIcon={dropDownIconSurvey}
                        valueTemplate={badgeTemplateSurvey} itemTemplate={badgeTemplateSurvey} className="w-full" style={{ borderRadius: '1rem', backgroundColor: `var(--color-badge-${status.severity})`, borderColor: 'transparent' }} />
                    {/* {(status.status === 'Answered') &&
                        <div className=" flex flex-row font-italic gap-1 align-items-center" style={{ fontSize: '0.75rem', textDecoration: 'italic' }}> */}
                    {/* @ts-ignore */}
                    {/* <Badge value="" severity={maturity.severity}></Badge>
                            <div>{maturity.status}</div>
                        </div>
                    } */}
                </div>
            </div>
        )
    }

    const handleSaveResponse = () => {
        let statusUpdate = {};
        if (state.maturity !== 'Incomplete') statusUpdate = { status: 'Answered' };
        modifyZTACData([{ ...state, ztacId: acId, ...statusUpdate }])
            .then((res: any) => {
                refetchZTACAnalytics();
                getZTACData();
            })
            .catch((err) => {
                toastError(toast, err?.response?.data?.message || err?.message || 'Unknown Error', err?.code || 'Error');
            })
    }

    const saveActivityButtonSelection = [
        {
            label: 'Discard',
            icon: 'pi pi-times',
            command: () => {
                dispatch({ type: 'init', payload: { ...originalZTACData.current, updated: false } })
            }
        },
    ]

    const footer = () => {
        return (
            <>
                {state.updated &&
                    <SplitButton onClick={handleSaveResponse}
                        className="act-save-popup" size="small" rounded
                        label={`Save Changes for ZTAC ${acId}`}
                        icon="pi pi-plus"
                        model={saveActivityButtonSelection}
                        severity="success"
                        style={{ zIndex: 11, position: 'initial' }} />
                }
            </>
        )
    }

    return (
        <ZTACContext.Provider value={{ state, dispatch }}>
            <Panel ref={panelRef} headerTemplate={header} footerTemplate={footer} toggleable collapsed={true} className={`activity-component flex flex-column zt-inline-custom`} aria-roledescription={`ztac-ac-${acId}`}>
                <div className="h:full overflow-y-auto p-4 flex flex-column">
                    {childrenArray[1]}
                    <div className="flex flex-column justify-content-center text-center gap-3">
                        <div className="flex flex-column">
                            <h3>Response:</h3>
                            <InputTextarea value={state.note} rows={8} onChange={(e) => dispatch({ type: 'update', field: 'note', payload: e.target.value })} />
                        </div>
                        <div className="flex flex-column justify-content-center gap-2">
                            <h3>Artifacts:</h3>
                            {state?.files && state.files.map((linkFile, i) => {
                                if (!activeProject?.files) return;
                                const file = activeProject.files.find((x: any) => x.backendName === linkFile);
                                if (!file) return;
                                return (
                                    <div key={`f-${acId}-${i}-${file.filename}`}
                                        className="flex flex-row gap-3 p-button p-button-secondary p-button-outlined"
                                        onClick={() => window.open(`${g_api.root}${file.backendName}`, '_blank')}>
                                        <i className="pi pi-file" />
                                        <div>{formatFileSize(file.size || 0)}</div>
                                        <div>{file?.meta?.displayName || file.filename}</div>
                                    </div>
                                )
                            })}
                            <LinkArtifactComponent onChange={handleLinkArtifact} />
                            <UploadFile projectName={projectName} uploadSuccess={handleUploadSuccess} />
                        </div>

                    </div>
                    <br />
                    <UseCaseShowButton>
                        {childrenArray[2]}
                    </UseCaseShowButton>
                </div>
            </Panel>

        </ZTACContext.Provider>
    )
}

export default ZTAC_Component;