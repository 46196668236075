
export const isRegisteredUser = (user: any) => {
    if (!user?.providerType) return false;
    if (user.providerType === 'anon-user') return false;
    return true;
}

export function validateEmail(email: string) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}





export default function userUtils() {
    return null;
};

