
// export const isLocal = !window.location.hostname.includes('github');

export const isLocal = false;

// export const isLocal = !window.location.hostname.includes('github') && false;


export default function appUtils(){
    return null;
}