import { ZTACBullet } from "./ZTAC_Component";

const ZTAC1 = () => {
    return (
        <></>
    )
};

ZTAC1.Title = function ZTAC1_Title() {
    return (
        <>
            <div className="ztac-title">1.0: Are you are who you say you are?</div>
            <div className="ztac-subtitle">continuous authentication and credentialing</div>
        </>
    )
}

ZTAC1.Intro = function ZTAC1_Intro() {

    return (
        <>
            <div>
                <h3>Acceptance Criteria:</h3>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="ac-001">Every Actor in the system has a positively affirmed identity and is non-repudiable.</ZTACBullet>
                </ul>
            </div >

            <div>
                <h3>Outcome:</h3>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="ou-001">A full and complete accounting of every Actor in the system and a complete repudiation disposition is overtly available.</ZTACBullet>
                </ul>
            </div>

            <div>
                <h3>Conceptual Measures:</h3>
                <div>For each actor:</div>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="cm-001">An affirmed identity that is unique</ZTACBullet>
                    <ZTACBullet code="cm-002">All associated role(s) for each actor, discretely identified</ZTACBullet>
                    <ZTACBullet code="cm-003">The authenticating information for the actor-role pair that is certifiable</ZTACBullet>
                </ul>
            </div>
        </>
    )
}

ZTAC1.UseCase = function ZTAC1_UseCase() {
    return (
        <div>
            <h3>Implementation Use Case: (example)</h3>
            <p>Scenario: Employees (Actors) need to access an internal resource (Resource) to perform various tasks such as checking emails, accessing internal documents, and using collaboration tools. The system must continuously authenticate and credential each Actor to ensure they are who they say they are.</p>
            <ul>
                <li>
                    Initial Identity Verification:
                    <ul>
                        <li>The employee logs into the internal resource using their CAC and PIN.</li>
                    </ul>
                </li>
                <li>
                    Continuous Authentication:
                    <ul>
                        <li>Throughout the session, the system continuously monitors the employee's behavior using behavioral biometrics (e.g., typing patterns, mouse movements).</li>
                        <li>Context-aware authentication checks are performed, such as verifying the device being used and the network location.</li>
                        <li>If anomalies are detected, the system prompts for re-authentication or additional verification steps.</li>
                    </ul>
                </li>
                <li>
                    Role Validation:
                    <ul>
                        <li>The system queries the enterprise's role management system to validate the employee's roles.</li>
                        <li>Each role is discreetly identified and mapped to specific permissions within the corporate intranet.</li>
                        <li>The system ensures that the employee's roles are up-to-date and authorized.</li>
                    </ul>
                </li>
                <li>
                    Certifiable Authentication Information:
                    <ul>
                        <li>The system logs all authentication events, including initial login, continuous authentication checks, and any re-authentication prompts.</li>
                        <li>Each log entry includes the Actor's unique identity, the roles assumed, and the authentication methods used.</li>
                        <li>Non-repudiation measures ensure that the logs are tamper-proof and can be audited.</li>
                    </ul>
                </li>
                <li>
                    Full Accounting and Repudiation Disposition:
                    <ul>
                        <li>The system maintains a full and complete accounting of every Actor in the system.</li>
                        <li>A dashboard provides an overview of all active sessions, including the unique identity of each Actor, their roles, and the authentication methods used.</li>
                        <li>The system generates reports that provide a complete repudiation disposition, ensuring that every action taken by each Actor is traceable and non-repudiable.</li>
                    </ul>
                </li>
            </ul>
        </div>
    )
}

export default ZTAC1;