
import React, { useState, useEffect, useRef } from 'react';
import { Chart } from 'primereact/chart';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useApp } from '../RealmApp';
import { isLocal } from '../utils/appUtils';
import { useAPIHandler } from '../../hooks/useAPIHandler';
import { Button } from 'primereact/button';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const fillChartData = (data: Array<number>) => {
    const documentStyle = getComputedStyle(document.documentElement);
    return {
        labels: ['Implemented', 'Available'],
        datasets: [
            {
                data: data,
                backgroundColor: [
                    documentStyle.getPropertyValue('--green-500'),
                    documentStyle.getPropertyValue('--blue-500'),
                ],
                hoverBackgroundColor: [
                    documentStyle.getPropertyValue('--green-400'),
                    documentStyle.getPropertyValue('--blue-400'),
                ]
            }
        ]
    }
}

const fillOptions = (title: string) => {
    return {
        plugins: {
            title: {
                display: true, // Show the title
                text: title, // Title text
                font: {
                    size: 12 // Font size of the title
                }
            },
            legend: {
                labels: {
                    usePointStyle: true
                }
            },
            datalabels: {
                formatter: (value: any, ctx: any) => {
                    let sum = 0;
                    const dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.forEach((data: any) => {
                        sum += data;
                    });
                    const percentage = ((value / sum) * 100);
                    if (percentage < 6) return '';
                    return percentage.toFixed(0) + "%";
                },
                color: '#fff',  // Color of the text
            },
        }
    };
}

export default function ChartPieImplementedActivities() {
    const [chartData, setChartData] = useState(fillChartData([]));
    const [chartOptions, setChartOptions] = useState({});
    const { activeProject }: any = useApp();
    const { getActivitiesImplemented } = useAPIHandler();
    const projectName = activeProject?.selection?.name;

    useEffect(() => {
        if (!projectName) return;
        const title = (isLocal) ? 'Activities - Implemented' : '*Activities - Implemented';
        getActivitiesImplemented(projectName, activeProject?.profile)
            .then((res: any) => {
                const data = res?.data?.data || [60, 9];
                setChartData(
                    fillChartData(data)
                )
            })
            .catch((error) => {
                if (!isLocal) setChartData(fillChartData([60, 9]))
            })

        setChartOptions(fillOptions(title));
    }, [projectName]);

    return (
        <div className='col-6 md:col-4 lg:col-4'>
            <div className="surface-0 shadow-2 p-2 border-1 border-50 border-round">
                <Chart id={`chart-${projectName}-act-impl`} key={`chart-${projectName}-act-impl`} type="pie" data={chartData} options={chartOptions} />
            </div>
        </div>
    )
}
