import React, { useContext, useRef, useState, useEffect } from "react";
import * as Realm from "realm-web";
import atlasConfig from "../atlasConfig.json";
import { isLocal } from "./utils/appUtils";
import { LocalAppContext } from "./LocalApp";
import { Toast } from 'primereact/toast';
import { useAPIHandler } from "../hooks/useAPIHandler";
import { useApi } from "./ProviderAPIs";
import demoData from './demoData.json'
import demoAnalytics from './demoAnalytics.json';
import demoztIds from './demoztIds.json';
import { GZT } from "./DataManagement/DataManagement";

const { baseUrl } = atlasConfig;

function createApp(id) {
  return new Realm.App({ id, baseUrl, type: 'realm', isLocalApp: false });
}

const AppContext = React.createContext(null);

export function AppProvider({ appId, children }) {
  // Store Realm.App in React state. If appId changes, all children will rerender and use the new App.
  const [app, setApp] = React.useState(createApp(appId));
  const toast = useRef(null);
  const [analytics, setAnalytics] = useState(demoAnalytics);
  const [activeProject, setActiveProject] = useState(demoData);
  const [ztProfiles, setZTProfiles] = useState([{name: 'ZT-All'}, {name: "MPE Baseline"}]);
  const [ztProfileIds, setZTProfileIds] = useState(demoztIds);
  const [criticalFailure, setCriticalFailure] = useState(false);

  
  React.useEffect(() => {
    setApp(createApp(appId));
  }, [appId]);

  useEffect(() => {
    GZT.profiles.push({
      name: 'MPE Baseline',
      ztIds: demoztIds,
    })
    // getZTProfiles().then((data) => {
    //   setZTProfiles(data);
    // })
    //   .catch(err => {
    //     toast.current.show({ severity: 'error', summary: 'API Error', detail: 'Cannot load critical project data. Try refreshing.', life: 3000 });
    //   });
  }, []);

  const getProject = async (projectObj) => {
    return Promise.resolve({})
    // getAnalytics(projectObj.name);
    // getProjectOverview(projectObj.name).then((data) => {
    //   if (!data?.name) return;
    //   setActiveProject({
    //     ...data,
    //     selection: projectObj,
    //   });
    //   getZTProfileDataCache(data?.profile?.name || 'ZT-All').then(data => {
    //     setZTProfileIds(data);
    //   })
    // })
  }

  const getAnalytics = async (projectName) => {
    return Promise.resolve({})
    // return getProjectAnalytics(projectName).then((data) => {
    //   if (!data) return;
    //   setAnalytics([
    //     ...data?.data || {}
    //   ])
    // });
  }

  const refreshAnalytics = async () => {
    if (!activeProject?.selection) return;
    // return getAnalytics(activeProject.selection.name);
  }

  const refreshProjectData = async () => {
    if (!activeProject?.selection) return;
    return Promise.resolve({ name: 'demoProject' })
    // return getProject(activeProject.selection);
  }

  // Store the app's current user in state and wrap the built-in auth functions to modify this state
  const [currentUser, setCurrentUser] = React.useState(app.currentUser);
  // Wrap the base logIn function to save the logged in user in state
  const logIn = React.useCallback(
    async (credentials) => {
      await app.logIn(credentials);
      setCurrentUser(app.currentUser);
    },
    [app]
  );

  const validateInvite = async (code, email) => {
    if (!app.currentUser) {
      const credentials = Realm.Credentials.anonymous();
      await app.logIn(credentials);
    }
    return app.currentUser.callFunction("validateInvite", code, email);
  }
  // Wrap the current user's logOut function to remove the logged out user from state
  const logOut = React.useCallback(async () => {
    try {
      app.users.forEach(async (user) => {
        await user?.logOut();
        await app.removeUser(user);
      })
    } catch (err) {
      console.error(err);
    }
    // In this App there will only be one logged in user at a time, so
    // the new current user will be null. If you add support for
    // multiple simultaneous user logins, this updates to another logged
    // in account if one exists.
    setCurrentUser(null);
  }, [app]);

  // Override the App's currentUser & logIn properties + include the app-level logout function
  const appContext = React.useMemo(() => {
    return {
      ...app, 
      currentUser, 
      logIn, 
      logOut, 
      validateInvite, 
      toast, 
      activeProject,
      getProject,
      refreshProjectData,
      analytics,
      refreshAnalytics,
      ztProfiles,
      ztProfileIds,
      criticalFailure,
    };
  }, [app, currentUser, logIn, logOut]);

  return (
    <AppContext.Provider value={appContext}>
      <Toast ref={toast} />
      {children}
    </AppContext.Provider>
  );
}


export function useApp() {
  // const isLocalApp = isLocal & false;
  // const isLocalApp = isLocal & false;
  const app = useContext(isLocal ? LocalAppContext : AppContext);

  if (!app) {
    throw new Error(
      `No App Services App found. Make sure to call useApp() inside of a <AppProvider />.`
    );
  }
  return app;
}
