
import React, { useState, useRef, lazy } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import { Ripple } from 'primereact/ripple';
import { StyleClass } from 'primereact/styleclass';
import useResponsive from '../../hooks/useResponsive';

import './InformationalSidebar.css'

// Lazy loaded components
export const DashboardLazy = () => {return <>Homepage</>};
const IDAFFrameworkLazy = lazy(() => import('../InformationalZT/IDAFFramework'));
const IDAFAcceptanceCriteriaLazy = lazy(() => import('../InformationalZT/IDAFAcceptanceCriteria'));
const ThresholdGuidanceInformationalLazy = lazy(() => import('../InformationalZT/ThresholdGuidanceInformational'))


type T_InformationalSidebarProps = {
    setContent: (content: JSX.Element) => void;
}

export default function InformationalSidebar({setContent}:T_InformationalSidebarProps) {
    const [visible, setVisible] = useState<boolean>(true);
    const btnRef1 = useRef<any>(null);
    const btnRef2 = useRef<any>(null);
    const btnRef3 = useRef<any>(null);
    const btnRef4 = useRef<any>(null);
    const windowSize = useResponsive();
    const showPopup = windowSize !== 'lg';

    if (!showPopup && !visible) setVisible(true);

    return (
        <>
            {showPopup &&
                <Button size='small' severity='secondary' icon="pi pi-cog"
                    className="mr-2 sb-survey-btn"
                    style={{zIndex: 9}}
                    onClick={() => setVisible(prev => !prev)}
                    aria-controls="popup_menu_left" aria-haspopup />
            }
            {visible &&
                <div className="custom-sidebar surface-section h-screen block flex-shrink-0 absolute lg:static md:static left-0 top-0 z-1 border-right-1 surface-border select-none" style={{ width: '280px' }}>
                    <div className="flex flex-column h-full">
                        <div className="overflow-y-auto">
                            <ul className="list-none p-3 m-0">
                                <li>
                                    <StyleClass nodeRef={btnRef1} selector="@next" enterClassName="hidden" enterActiveClassName="slidedown" leaveToClassName="hidden" leaveActiveClassName="slideup">
                                        <div ref={btnRef1} className="p-ripple p-3 flex align-items-center justify-content-between text-600 cursor-pointer">
                                            <span className="font-medium">IDAF</span>
                                            <i className="pi pi-chevron-down"></i>
                                            <Ripple />
                                        </div>
                                    </StyleClass>
                                    <ul className="list-none p-0 m-0 overflow-hidden">
                                        <li>
                                            <div className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full"
                                                  onClick={() => setContent(<IDAFFrameworkLazy />)}>
                                                <i className="pi pi-home mr-2"></i>
                                                <span className="font-medium">Framework</span>
                                                <Ripple />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full"
                                                  onClick={() => setContent(<IDAFAcceptanceCriteriaLazy />)}>
                                                <i className="pi pi-bookmark mr-2"></i>
                                                <span className="font-medium">Acceptance Criteria</span>
                                                <Ripple />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full"
                                                  onClick={() => setContent(<ThresholdGuidanceInformationalLazy />)}>
                                                <i className="pi pi-bookmark mr-2"></i>
                                                <span className="font-medium">MPE Threshold Guidance</span>
                                                <Ripple />
                                            </div>
                                        </li>
                                        {/* <li>
                                            <StyleClass nodeRef={btnRef2} selector="@next" enterClassName="hidden" enterActiveClassName="slidedown" leaveToClassName="hidden" leaveActiveClassName="slideup">
                                                <a ref={btnRef2} className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                                                    <i className="pi pi-chart-line mr-2"></i>
                                                    <span className="font-medium">Reports</span>
                                                    <i className="pi pi-chevron-down ml-auto mr-1"></i>
                                                    <Ripple />
                                                </a>
                                            </StyleClass>
                                            <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                                <li>
                                                    <StyleClass nodeRef={btnRef3} selector="@next" enterClassName="hidden" enterActiveClassName="slidedown" leaveToClassName="hidden" leaveActiveClassName="slideup">
                                                        <a ref={btnRef3} className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                                                            <i className="pi pi-chart-line mr-2"></i>
                                                            <span className="font-medium">Revenue</span>
                                                            <i className="pi pi-chevron-down ml-auto mr-1"></i>
                                                            <Ripple />
                                                        </a>
                                                    </StyleClass>
                                                    <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                                        <li>
                                                            <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                                                                <i className="pi pi-table mr-2"></i>
                                                                <span className="font-medium">View</span>
                                                                <Ripple />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                                                                <i className="pi pi-search mr-2"></i>
                                                                <span className="font-medium">Search</span>
                                                                <Ripple />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                                                        <i className="pi pi-chart-line mr-2"></i>
                                                        <span className="font-medium">Expenses</span>
                                                        <Ripple />
                                                    </a>
                                                </li>
                                            </ul>
                                        </li> */}
                                        {/* <li>
                                            <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                                                <i className="pi pi-users mr-2"></i>
                                                <span className="font-medium">Team</span>
                                                <Ripple />
                                            </a>
                                        </li>
                                        <li>
                                            <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                                                <i className="pi pi-comments mr-2"></i>
                                                <span className="font-medium">Messages</span>
                                                <span className="inline-flex align-items-center justify-content-center ml-auto bg-blue-500 text-0 border-circle" style={{ minWidth: '1.5rem', height: '1.5rem' }}>
                                                    3
                                                </span>
                                                <Ripple />
                                            </a>
                                        </li>
                                        <li>
                                            <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                                                <i className="pi pi-calendar mr-2"></i>
                                                <span className="font-medium">Calendar</span>
                                                <Ripple />
                                            </a>
                                        </li>
                                        <li>
                                            <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                                                <i className="pi pi-cog mr-2"></i>
                                                <span className="font-medium">Settings</span>
                                                <Ripple />
                                            </a>
                                        </li> */}
                                    </ul>
                                </li>
                            </ul>
                            <ul className="list-none p-3 m-0">
                                <li>
                                    <StyleClass nodeRef={btnRef4} selector="@next" enterClassName="hidden" enterActiveClassName="slidedown" leaveToClassName="hidden" leaveActiveClassName="slideup">
                                        <div ref={btnRef4} className="p-ripple p-3 flex align-items-center justify-content-between text-600 cursor-pointer">
                                            <span className="font-medium">DOCUMENTATION</span>
                                            <i className="pi pi-chevron-down"></i>
                                            <Ripple />
                                        </div>
                                    </StyleClass>
                                    <ul className="list-none p-0 m-0 overflow-hidden">
                                        <li>
                                            <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                                                <i className="pi pi-folder mr-2"></i>
                                                <span className="font-medium">Zero Trust</span>
                                                <Ripple />
                                            </a>
                                        </li>
                                        <li>
                                            <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                                                <i className="pi pi-chart-bar mr-2"></i>
                                                <span className="font-medium">NIST 800</span>
                                                <Ripple />
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                                                <i className="pi pi-cog mr-2"></i>
                                                <span className="font-medium">Settings</span>
                                                <Ripple />
                                            </a>
                                        </li> */}
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        {showPopup && <div className="mt-auto flex justify-content-center p-2">
                            <span>
                                <Button type="button" onClick={() => setVisible(false)} icon="pi pi-times" rounded outlined severity='secondary' className="h-2rem w-2rem"></Button>
                            </span>
                        </div>
                        }
                    </div>
                </div>
            }
        </>
    )
}
