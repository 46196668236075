import { useEffect, useState } from "react";
import { useApp } from "../RealmApp";
import { GrDocumentMissing } from "react-icons/gr";
import { useAPIHandler } from "../../hooks/useAPIHandler";
import { isLocal } from "../utils/appUtils";


const ChartCountUnaccountedActivities = () => {
    const { activeProject }:any = useApp();
    const [gaps, setGaps] = useState<string>('Unknown');
    const projectName = activeProject?.selection?.name;

    const { getReportActivityGaps } = useAPIHandler();

    useEffect(() => {
        if(!projectName) return;
        getReportActivityGaps(projectName, activeProject?.profile)
            .then((res: any) => {
                const data = res?.data?.activityGaps || 'Error';
                setGaps(data);
            })
            .catch((error) => {
                if (!isLocal) setGaps('7 (demo)')
            })

    },[projectName])

    return (
        <div className="col-12 md:col-6 lg:col-3" title="These are activities without a plan. If activities are marked as Incomplete or Not Planned then they show up here. Marking activities as NA will not show up here.">
            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                <div className="flex justify-content-between mb-3">
                    <div>
                        <span className="block text-500 font-medium mb-3">{(isLocal) ? 'Activity Gaps' : '*Activity Gaps'}</span>
                        <div className="text-900 font-medium text-xl">{gaps}</div>
                    </div>
                    <div className="flex align-items-center justify-content-center bg-red-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                        <GrDocumentMissing className="text-red-300"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChartCountUnaccountedActivities;