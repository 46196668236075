import { useEffect, useMemo, useReducer, useRef, useState } from "react"

import '../SurveyTool/SurveyTool.css';
import { useZT } from "../../hooks/useZTfw";
import { LinearProgress } from "@mui/material";
import { useShowLoader } from "../../hooks/util-hooks";

import { useApp } from "../RealmApp";
import { getIdsFromProfileName, GZT } from "../DataManagement/DataManagement";
import ZTSurveyActivityComponent from "../SurveyTool/ZTSurveyActivitiesComponent";
import ZTSurveyCapabilityComponent from "../SurveyTool/ZTSurveyCapabilityComponent";
import ExportActivityComponent from "./ExportActivityComponent";


export default function ExportActivities({activeProject}:any) {
    const { loading, apiError, ztfw, ...ztActions }: any = useZT();
    const showLoader = useShowLoader(loading, 200);
    const activitiesShown = useRef(new Set());
    const { analytics }: any = useApp();
    const projectName = activeProject?.selection?.name;

    const capabilitiesShown = new Set();

    let filteredActivities = [];
    if (ztfw?.activities) {
        const ztIds = getIdsFromProfileName(activeProject.profile);
        filteredActivities = ztfw?.activities?.filter((elm: any) => {
            if (analytics.find((x: any) => (x.ztId === elm.aId) && (x.status !== 'NA'))) return true;
            if (ztIds && !ztIds.includes(elm.aId)) return false

            activitiesShown.current.add(elm.aId);
            return true;
        });
    }

    if (loading) {
        return showLoader ? <LinearProgress /> : null;
    }

    return (
        <div className="container-survey" style={{ zIndex: 7 }}>
            <div className="container-survey-content">
                <div className="survey-activity-content flex flex-column overflow-y-auto">
                    {filteredActivities?.map((elm: any, index: number) => {
                        const showCapability = !capabilitiesShown.has(elm.cId);
                        if (showCapability) capabilitiesShown.add(elm.cId);
                        const keyValue = `${elm.aId}-ap-${index}-${projectName}`
                        const state = activeProject.data.find((x:any) => x.ztId === elm.aId);
                        const context = GZT.entire.find((x:any) => x.aId === elm.aId);
                        return (
                            <div key={keyValue}>
                                {(showCapability) &&
                                    <ZTSurveyCapabilityComponent data={ztfw?.capabilities?.find((x: any) => x.cId === elm.cId) || {}} isView={false} />
                                }
                                <ExportActivityComponent data={elm} state={state} context={context}/>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}