import { postModifyCapabilities } from "../apiCalls/apiZTKM";
import { useApp } from "../components/RealmApp";
import { T_CapabilityOverview } from "../components/SurveyTool/reducerEditCapability";

const useModifyCapabilityLink = () => {
    const { activeProject }: any = useApp();

    const handleClick = async (capabilityName: string, cId: string, type: 'add' | 'delete') => {
        const project = activeProject?.selection?.name;
        const capability: T_CapabilityOverview = activeProject?.ztCapabilities?.find((x: any) => x.name === capabilityName);
        if (type === 'add') {
            capability.ztcIds.push(cId);
        } else if (type === 'delete') {
            capability.ztcIds = capability.ztcIds.filter(id => id !== cId);
        }

        return postModifyCapabilities({
            project,
            capabilities: [{
                ...capability,
            }]
        }, 'update');
    }
    return handleClick;
}

export default useModifyCapabilityLink;