import { Button } from "primereact/button"
import { CSSProperties, MouseEventHandler, useCallback, useEffect, useMemo, useState } from "react";
import './Nist.css';
import { Badge } from "primereact/badge";
import { GZT } from "../DataManagement/DataManagement";
import { Panel } from "primereact/panel";
import { useShowLoader, useTimeout } from "../../hooks/util-hooks";
import { LinearProgress } from "@mui/material";
import { sortArrayActivityIds } from "./reducerZTActivity";
import { useAPIHandler } from "../../hooks/useAPIHandler";
import { Fieldset } from "primereact/fieldset";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";

type T_NistButton = {
    NistId: string;
    notification?: any;
    onClick?: MouseEventHandler;
}

type T_EnhanceElement = {
    enhanceObj: {
        name: string;
        cId: string;
    };
    onClick?: MouseEventHandler;
}

export type T_PVObject = {
    ztId: string;
    pv: string
}

type T_PVButton = {
    pvObject: T_PVObject;
    isFocus?: boolean;
    onClick?: MouseEventHandler;
}

type T_NistGroup = {
    nistGroup: string[];
    content: any;
    aDispatch: any;
}


type T_ParseNistDiscussion = {
    discussion: string;
    cId: string;
}

type T_LongTextReadMore = {
    text: string;
    threshold: number;
}

function LongTextReadMore({ text, threshold }: T_LongTextReadMore) {
    const [isExpanded, setIsExpanded] = useState(false);
    if (text.length <= threshold) return HighlistPVs(text)

    return (
        <>
            <span>
                {isExpanded ? text : `${text.substring(0, threshold)}...`}
            </span>
            <span onClick={() => setIsExpanded((prev: boolean) => !prev)} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                {(isExpanded) ? 'Read less' : 'Read more'}
            </span>
        </>
    )
}

const r_nistText = {
    parent: /(?:^|\n)\s*[a-z]\. .*?(?=\n\s*[a-z]\.|\n\s*$)/gs,
    sub: /(?:^|\s)*\([a-z]\).*?(?=\s\([a-z]\)|\s*$)/gs,
}

const HighlistPVs = (text: string) => {
    const pattern = /\[[^\]]+\]/g;

    // Split text into parts, keeping the delimiters (brackets)
    const parts = text.split(pattern);
    const matches = text.match(pattern) || [];

    return (
        <div>
            {parts.map((part, index) => (
                <span key={index}>
                    {part}
                    {index < matches.length && (
                        <span className="nist-pv-highlight">{matches[index]}</span>
                    )}
                </span>
            ))}
        </div>
    );
}

export function PVButton({ pvObject, onClick, isFocus }: T_PVButton) {
    return (
        <Button
            size="small"
            severity='secondary'
            outlined={!isFocus}
            disabled={pvObject.pv.length === 0}
            onClick={onClick}
            style={{ overflow: 'visible' }}
            label={pvObject.ztId}>
        </Button>
    )
}

export function NistGroup({ nistGroup, aDispatch, content }: T_NistGroup) {
    const fmId = nistGroup[0].substring(0, 2);
    const famName = GZT.nistFamily.filter((obj: any) => obj.fId === fmId)?.[0]?.name || "Unknown";

    return (
        <div className="nist-group-container text-center">
            <div className="family-name">{famName}</div>
            <div className="nist-buttons">
                {nistGroup.map((nistId: string) => (
                    <NistButton key={'btn-nist-' + nistId}
                        NistId={nistId}
                        onClick={() => aDispatch({ type: 'selectNist', payload: nistId })}
                        notification={content.nist.filter((obj: any) => obj.cId === nistId)} />
                ))}
            </div>
        </div>
    )
}

export function NistButton({ NistId, onClick, notification = null }: T_NistButton) {
    const isNotification = notification?.[0]?.pv;
    return (
        <Button className="p-overlay-badge"
            size="small"
            severity="secondary"
            outlined
            onClick={onClick}
            style={{ overflow: 'visible' }}
            label={NistId}>
            {isNotification &&
                <Badge severity="info" />
            }
        </Button>
    )
}

export function EnhancementElement({ enhanceObj, onClick }: T_EnhanceElement) {
    return (
        <div onClick={onClick} className="btn-nist-enhance">
            {enhanceObj.cId}: {enhanceObj.name.split('|').slice(-1)}
        </div>
    )
}

function ParseNistBullets({ discussion, cId }: T_ParseNistDiscussion) {
    const s_pad = '0.5rem';
    const r_key = (/\(/.test(cId)) ? 'sub' : 'parent';
    discussion = discussion + '\n';
    const items: any = discussion.match(r_nistText[r_key]);

    if (!items) return <LongTextReadMore text={discussion} threshold={350} />

    return (
        <ol type="a">
            {items.map((item: string) => {
                const parts = item.split(/(?=\d\. )/); // Split into main item and sub-items
                return (
                    <li key={parts[0].trim().slice(0, 2) + 'li' + cId} style={{ marginBottom: s_pad }}>
                        {HighlistPVs(parts[0].trim().slice(3).trim())}
                        {parts.length > 1 && (
                            <ol style={{ marginTop: s_pad }}>
                                {parts.slice(1).map(subItem => (
                                    <li key={subItem.trim().slice(0, 2)} style={{ marginBottom: s_pad }}>
                                        {HighlistPVs(subItem.trim().slice(3).trim())}
                                    </li>
                                ))}
                            </ol>
                        )}
                    </li>
                );
            })}
        </ol>
    );
}

type NISTPanelProps = {
    selectedNIST: string;
    pvSelected?: string;
    onExit?: () => void | null;
    style?: CSSProperties;
    className?: string;
}

export const FieldsetOverlay = ({ children, header, type }: any) => {
    return (
        <Fieldset legend={header} className={`overlay-fieldset ovt-${type}`}>
            {children}
        </Fieldset>
    );
};

const checkFilterType = (data: any, type: string) => {
    const value = data.filter((obj: any) => obj.type === type);
    if (value.length > 0) return value[0];
    return false;
}

type T_ApplicabilityTableValues = {
    values: {
        zt: boolean;
        intel: boolean;
        classified: boolean;
        cnss: boolean;
    }
}

const ApplicabilityTableComponent = ({ values }: T_ApplicabilityTableValues) => (
    <div className="flex flex-column">
        <div className="act-header">Overlay: Applicability</div>
        <table className="applicability-table">
            <thead>
            </thead>
            <tbody>
                <tr>
                    <td>CNSS-Moderate:</td>
                    <td>ZT:</td>
                    <td>Intel (INT-B):</td>
                    <td>Classified:</td>
                </tr>
                <tr style={{ height: '20px' }}>
                    <td>{values.cnss ? <i className="pi pi-check" /> : ' '}</td>
                    <td>{values.zt ? <i className="pi pi-check" /> : ''}</td>
                    <td>{values.intel ? <i className="pi pi-check" /> : ''}</td>
                    <td>{values.classified ? <i className="pi pi-check" /> : ''}</td>
                </tr>
            </tbody>
        </table>
    </div>
)
type T_OverlaysTextProps = {
    type: 'classified' | 'intel' | 'cnss';
    header: string;
}

export const NISTPanel = ({ selectedNIST, pvSelected, onExit, style, className }: NISTPanelProps) => {
    const [content, setContent] = useState<any>(null);
    const [cId, setCId] = useState(selectedNIST);
    const [pvFocus, setPVFocus] = useState(pvSelected);
    const { getNistData, getOverlayContentByType } = useAPIHandler();

    const applicabilityTable = useMemo(() => {
        let values = {
            zt: false,
            intel: false,
            classified: false,
            cnss: false,
        };
        if (!content?.overlays) return values;
        if (checkFilterType(content.overlays, 'zt')) values.zt = true;
        const intelValue: any = checkFilterType(content.overlays, 'intel');
        if (intelValue && intelValue.impact.moderate) values.intel = true;
        if (checkFilterType(content.overlays, 'classified')) values.classified = true;
        return values;
    }, [content])

    const defaultStyle: CSSProperties = {

    }

    const mergedStyle = { ...defaultStyle, ...style }

    useEffect(() => {
        setCId(selectedNIST);
    }, [selectedNIST])

    useEffect(() => {
        if (!selectedNIST || selectedNIST === '') return;
        if (GZT.nist?.[cId]) {
            setContent(GZT.nist[cId])
            return;
        }
        getNistData(cId).then((data: any) => {
            const sortedEnhancements = data[0].enhancements.sort((a: any, b: any) => {
                const numA = parseInt(a.cId.match(/\((\d+)\)/)[1]);
                const numB = parseInt(b.cId.match(/\((\d+)\)/)[1]);
                return numA - numB;
            })
            const nistData = {
                ...data[0],
                enhancements: sortedEnhancements
            }
            GZT.nist[cId] = { ...nistData };
            setContent(GZT.nist[cId]);
        })

    }, [cId])

    const OverlayZTText = ({ children }: any) => {
        const [loading, setLodaing] = useState(true);
        const ztIds = content.overlays.filter((overlayObj: any) => overlayObj.type === 'zt');
        const txt = (content) ? ztIds.filter((pvObject: T_PVObject) => pvObject.ztId === pvFocus)?.[0]?.pv || "None\n" : 'None\n';

        useTimeout(() => {
            setLodaing(false);
        }, [100])

        if (ztIds.length === 0) return <></>;

        return (
            <FieldsetOverlay header="Zero Trust" type='zt'>
                {(loading) ? <LinearProgress /> :
                    <>
                        {txt.split('\n').map((obj: string, index: number) => (
                            <div key={`pvi-${cId}-${pvFocus}-${index}`}>{obj}</div>
                        ))}
                    </>}
                <br />
                {children}
                <div className="nist-buttons">
                    {sortArrayActivityIds(ztIds, 'ztId').map((pvObject: T_PVObject, index: number) => (
                        <PVButton key={`pvb-${cId}-${pvFocus}-${index}`} pvObject={pvObject} isFocus={pvFocus === pvObject.ztId} onClick={() => setPVFocus(pvObject.ztId)} />
                    ))}
                </div>
            </FieldsetOverlay >
        )
    }

    const OverlaysText = ({ type, header }: T_OverlaysTextProps) => {
        const [showMore, setShowMore] = useState(false);
        const [moreContent, setMoreContent]: any = useState({});
        const overlay = content.overlays.filter((overlayObj: any) => overlayObj.type === type)?.[0] || {};

        useEffect(() => {
            if (!showMore || Object.keys(moreContent).length > 0) return;
            getOverlayContentByType(cId, type)
                .then((data: any) => {
                    setMoreContent(data);
                })
                .catch((error) => {
                    console.log(error);
                })
        }, [showMore, cId])

        if (Object.keys(overlay).length === 0) return <></>;

        const txt = overlay?.pv || 'None';

        const Header = () => (
            <div className="flex flex-row justify-content-center align-items-center gap-1">
                <span>{header}:</span>
                <i className={`pi pi-chevron-${showMore ? 'up' : 'down'}`} onClick={() => setShowMore(prev => !prev)} style={{ cursor: 'pointer' }} />
            </div>
        )

        return (
            <FieldsetOverlay header={<Header />} type={type}>
                {showMore ?
                    <div key={`ovl-class-${cId}`} className="flex flex-column">
                        <p><strong>Supplemental: </strong>{moreContent?.supplemental}</p>
                        <p><strong>Parameter Value: </strong>{moreContent?.pv}</p>
                        <p><strong>Justification: </strong>{moreContent?.justification}</p>
                        <p><strong>Regulatory: </strong>{moreContent?.regulation}</p>
                        {moreContent?.['control-extension'] &&
                            <p><strong>Control Extension: </strong>{moreContent['control-extension']}</p>
                        }
                    </div>
                    :
                    txt.split('\n').map((obj: string, index: number) => (
                        <div key={`pvi-${cId}-${pvFocus}-${index}`}>{obj}</div>
                    ))
                }
            </FieldsetOverlay>
        )
    }

    const OverlayThresholdText = () => {
        // const txt = (content) ? content.pv.filter((pvObject: T_PVObject) => pvObject.ztId === pvFocus)?.[0]?.pv || "None\n" : 'None\n';
        const txt = 'Coming soon...'

        return (
            <FieldsetOverlay header="CNSS - Moderate" type='cnss'>
                {txt.split('\n').map((obj: string, index: number) => (
                    <div key={`pvi-${cId}-${pvFocus}-${index}`}>{obj}</div>
                ))}
            </FieldsetOverlay>
        )
    }

    const handleExit = () => {
        if (onExit) {
            onExit();
        }
    }

    const NISTHeader: any = (data: any) => {
        if (!content) return <>Loading NIST...</>
        const names = content.name.split('|').map((name: string) => name.trim());

        return (
            <>
                <Button onClick={handleExit} size="small" outlined severity="secondary" icon="pi pi-chevron-left" />
                <div>
                    {(content.isEM) ?
                        <>
                            <div>
                                {content.cId}: {names[1]}
                            </div>
                            <div className={'nist-control-link font-normal font-italic'}
                                onClick={() => setCId(content.control)}
                            >
                                {content.control}: {names[0]}
                            </div>
                        </>
                        :
                        <div>{content.cId}: {content.name}</div>
                    }
                    <div className="font-normal font-italic">{content.fId}: {content.fName}</div>
                </div>
            </>
        )
    }

    if (!content) return (<>Loading NIST...</>)

    return (
        <Panel header={NISTHeader} className={`nist-panel ${(className) ? className : ''}`} style={mergedStyle}>
            {(!content) ? <></> :
                <>
                    <ApplicabilityTableComponent values={applicabilityTable} />
                    <div className="flex flex-column gap-2">
                        <div className="act-header">Overlay: Parameter Value(s):</div>
                        <OverlaysText header="Classified" type="classified" />
                        <OverlaysText header="Intel" type="intel" />
                        <OverlayThresholdText />
                        <OverlayZTText>

                        </OverlayZTText>
                    </div>
                    <br />
                    <div className="grid">
                        <div className="col-12">
                            <div className="act-header">Control:</div>
                            <ParseNistBullets discussion={content.text} cId={content.cId} />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <div className="act-header">Discussion:</div>
                            <ParseNistBullets discussion={content.discussion} cId={content.cId} />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <div className="act-header">Related:</div>
                            {content.related.length === 0 ? 'None' :
                                <div className="nist-buttons">
                                    {content.related.map((nistId: string) => (
                                        <NistButton key={'btn-nist-' + nistId} NistId={nistId} onClick={() => setCId(nistId)} />
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                    {!content.isEM &&
                        <div className="grid">
                            <div className="col-12">
                                <div className="act-header">Enhancements:</div>
                                {content.enhancements.length === 0 ? 'None' :
                                    <div>
                                        {
                                            content.enhancements.map((eObj: any) => (
                                                <EnhancementElement key={'btn-ehn-' + eObj.cId} enhanceObj={eObj} onClick={() => setCId(eObj.cId)} />
                                            ))
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </>
            }
        </Panel>
    )
}

export default function Nist() {
    return (
        <>
        </>
    )
}