import { Badge } from "primereact/badge";
import { Panel } from "primereact/panel";
import { useMemo } from "react";
import { Dropdown } from "primereact/dropdown";
import { CiFileOn } from "react-icons/ci";
import { RiQuestionAnswerLine } from "react-icons/ri";
import { useApp } from "../RealmApp";

import { Button } from "primereact/button";
import '../SurveyTool/ZTSurveyActivitiesComponent.css';
import { formatFileSize } from "../utils/miscUtils";

import { CiBookmarkRemove, CiBookmarkPlus } from "react-icons/ci";

import { T_CapabilityOverview } from "../SurveyTool/reducerEditCapability";


export type T_FileObjectBackend = {
    destination: string;
    encoding: string;
    fieldname: string;
    filename: string;
    mimetype: string;
    originalname: string;
    path: string;
    size: number;
}

export type T_MaturityObject = {
    status: "Incomplete" | "Not Planned" | "Planning" | "Procured" | "In Progress" | "Not Integrated" | "Integrated" | "Inherited";
    code: string;
    severity: string;
    score: number;
}

export type T_StatusObject = {
    status: "Answered" | "Skipped" | "Incomplete" | "NA";
    severity: string;
    code: string;
}

export const rand = (low: number, high: number) => Math.floor(Math.random() * high) + low;

export const surveyStatuses: T_StatusObject[] = [
    { status: 'Answered', severity: 'success', code: 'cp' },
    { status: 'Skipped', severity: 'info', code: 'sk' },
    { status: 'Incomplete', severity: 'secondary', code: 'in' },
    { status: 'NA', severity: 'warning', code: 'na' },
]

const maturityRatings: T_MaturityObject[] = [
    { status: 'Incomplete', severity: 'danger', code: 'ie', score: 0 },
    { status: 'Not Planned', severity: 'danger', code: 'np', score: 0 },
    { status: 'Planning', severity: 'warning', code: 'pl', score: 1 },
    { status: 'Procured', severity: 'secondary', code: 'pr', score: 2 },
    { status: 'In Progress', severity: 'info', code: 'ip', score: 3 },
    { status: 'Not Integrated', severity: 'help', code: 'ni', score: 4 },
    { status: 'Integrated', severity: 'success', code: 'in', score: 5 },
    { status: 'Inherited', severity: 'success', code: 'in', score: 5 },
]

export const getStatusObject = (name: string): T_StatusObject => {
    return surveyStatuses.find((obj: T_StatusObject) => obj.status === name) || surveyStatuses[2];
}

export const getMaturityObject = (name: string): T_MaturityObject => {
    return maturityRatings.find((obj: T_MaturityObject) => obj.status === name) || maturityRatings[0];
}

type T_StatusDropwdown = {
    maturity: T_MaturityObject;
    onChange: (newStatus: T_MaturityObject) => void;
}

export const badgeTemplateSurvey = (option: T_StatusObject) => (
    // @ts-ignore
    <Badge value={option.status} severity={option.severity} className="" />
)

export const StatusDropdown = ({ maturity, onChange }: T_StatusDropwdown) => (
    <Dropdown value={maturity} onChange={(e) => onChange(e.value)}
        options={maturityRatings} optionLabel="name"
        placeholder="Maturity" panelClassName="survey-dropdown"
        dropdownIcon={dropDownIconSurvey} valueTemplate={badgeTemplateSurvey} itemTemplate={badgeTemplateSurvey}
        className=""
        style={{ borderRadius: '1rem', backgroundColor: `var(--color-badge-${maturity.severity})`, borderColor: 'transparent', width: 'fit-content', height: 'fit-content' }} />
)

export const dropDownIconSurvey = (option: any) => (
    <div className={`pi pi-chevron-down`} style={{ color: 'white' }}></div>
)

type T_ActivityOutcomeStatusProps = {
    status: 'No' | 'Partial' | 'Yes';
    updateStatus: (status: string) => void;
}

const outcomeStatusLookup: any = {
    'No': 'secondary',
    'Partial': 'warning',
    'Yes': 'success',
}

export const ActivityOutcomeStatus = ({ status, updateStatus }: T_ActivityOutcomeStatusProps) => {

    const handleClick = () => {
        if (status === 'No') {
            updateStatus('Partial');
        } else if (status === 'Partial') {
            updateStatus('Yes');
        } else {
            updateStatus('No');
        }
    };

    return (
        <Button size="small" outlined
            onClick={handleClick}
            severity={outcomeStatusLookup?.[status] || 'secondary'}
            label={status}
            style={{ minWidth: '60px', padding: '0.25rem' }} />
    )
}

const ExportActivityComponent = ({ data, tag, state, context }: any) => {
    const { activeProject, analytics, ztProfileIds }: any = useApp();
    const statusObj = analytics.find((x: any) => x.ztId === data.aId) || null;
    const status = getStatusObject(statusObj?.status);
    const maturity = getMaturityObject(statusObj?.maturity);
    const projectName = activeProject?.selection?.name;

    // const [state, dispatch] = useReducer(reducerEditActivity, emptyEditActivityReducer)
    const inScope = ztProfileIds.includes(data.aId);
    const statusInScope = (inScope || statusObj) ? status : getStatusObject('NA')

    const listCapabilities: any = useMemo(() => {
        if (state && Object.entries(state).length === 0) return [];
        const ztCapabilities = activeProject?.ztCapabilities?.filter((x: any) => x.ztcIds.includes(data.cId)) || [];
        if (ztCapabilities.length === 0) return [{ name: 'None' }];
        return ztCapabilities;
    }, [state])

    const header = (options: any) => {
        const className = `${options.className} justify-content-space-between gap-2 p-0`;

        return (
            <div className={className} style={{ backgroundColor: `var(--color-p${data.pillar})` }}>
                <div className="flex align-items-center" style={{ cursor: 'pointer', padding: '1rem', width: '100%' }}>
                    {tag &&
                        <div className={`at-ztac-mark ${tag}`} title="ZTAC either (P)artially maps to ZT Activitiy or (S)atisfies it.">{tag}</div>
                    }
                    <span className="font-bold">{data.aId}: {data.name}</span>
                </div>
                {(status.status === 'Answered') &&
                    <div className="flex flex-column">
                        {(!statusObj.hasNote) &&
                            <RiQuestionAnswerLine color="red" />
                        }
                        {(!statusObj.hasFiles) &&
                            <CiFileOn color="red" />
                        }
                    </div>
                }
                <div className="flex flex-column justify-content-center align-items-center">
                    <Dropdown value={statusInScope} options={surveyStatuses} optionLabel="status" placeholder="Status" dropdownIcon={<></>}
                        valueTemplate={badgeTemplateSurvey} itemTemplate={badgeTemplateSurvey} className="w-full" style={{ borderRadius: '1rem', backgroundColor: `var(--color-badge-${statusInScope.severity})`, borderColor: 'transparent' }} />
                    {(status.status === 'Answered') &&
                        <div className=" flex flex-row font-italic gap-1 align-items-center" style={{ fontSize: '0.75rem', textDecoration: 'italic' }}>
                            {/* @ts-ignore */}
                            <Badge value="" severity={maturity.severity}></Badge>
                            <div>{maturity.status}</div>
                        </div>
                    }
                </div>
                <div style={{ paddingRight: '1rem' }}>
                    {(inScope && status.status === 'NA') &&
                        <CiBookmarkRemove color='var(--color-badge-danger)' size={'1.5rem'} title="Profile/baseline deviation. This activity is removed from your baseline." />
                    }
                    {(!inScope && statusObj) &&
                        (status.status !== 'NA') &&
                        <CiBookmarkPlus color='var(--color-badge-success)' size={'1.5rem'} title="Profile/baseline deviation. This activity is added to your baseline." />
                    }
                </div>
            </div>
        )
    }

    return (
        <Panel headerTemplate={header} className={`flex flex-column zt-inline-custom`} aria-roledescription={`sid-${data.aId}`}>
            <div className="flex flex-column">
                <div className="flex flex-column justify-content-between">
                    <div className="activity-outcomes">
                        <h3>Outcomes: (Implemented)</h3>
                        <ol>
                            {context?.outcomes && context.outcomes.map((outcome: string, index: number) => {
                                const outcomeNumber = index + 1;
                                const outcomeValue = (state?.outcomes) ? state.outcomes.find((x: any) => x.index === outcomeNumber) || { index: outcomeNumber, value: 'No' } : { index: outcomeNumber, value: 'No' };
                                return (
                                    <div key={`outm-${data.aId}-${index}-${projectName}`} className="flex gap-2 align-items-center outcome-toggle" style={{ marginBottom: '1rem' }}>
                                        <ActivityOutcomeStatus status={outcomeValue.value}
                                            updateStatus={() => { }} />
                                        <li key={`${data.aId}-out-${index}`} style={{ marginLeft: '20px' }}>{outcome}</li>
                                    </div>
                                )
                            })}
                        </ol>
                    </div>
                </div>
                <div className="flex flex-column justify-content-center text-center">
                    <div className="flex flex-row justify-content-center align-items-center gap-3">
                        <h3>Response:</h3>
                    </div>
                    {(state?.note === '') ?
                        <p>None</p>
                        :
                        <p>{state?.note || 'None'}</p>
                    }
                </div>
                <div className="flex flex-column">
                    <h4>Capabilities:</h4>
                    <div className="flex flex-row gap-2">
                        {listCapabilities.map((capabilityObject: T_CapabilityOverview) => (
                            <div className="p-card p-2"
                                key={`aclink-${data.aId}-${capabilityObject.name}`}>
                                {capabilityObject.name}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex flex-column justify-content-center gap-2">
                    <div className="activity-artifacts">
                        <h3>Artifacts:</h3>
                    </div>
                    {(!state?.files || state?.files?.length === 0) &&
                        <div>None</div>
                    }
                    {state?.files && state.files.map((linkFile: any, i: any) => {
                        if (!activeProject?.files) return;
                        const file = activeProject.files.find((x: any) => x.backendName === linkFile);
                        if (!file) return;
                        const filename = file.backendName.split('/').pop();
                        return (
                            <a href={`./files/${filename}`} key={`f-${data.aId}-${i}-${file.filename}`} target="_blank"
                                className="flex flex-row gap-3 p-button p-button-secondary p-button-outlined">
                                {formatFileSize(file.size || 0)} {file?.meta?.displayName || file.filename}
                            </a>
                        )
                    })}
                </div>
            </div>
        </Panel>
    )
}

export default ExportActivityComponent;