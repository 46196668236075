import React from "react";
import { useCollection } from "./useCollection";
import { useApp } from "../components/RealmApp";
import atlasConfig from "../atlasConfig.json";
import { q_getZTInitial, q_getCapabilityDesc, q_getActivity, q_getNIST, q_getActivityMapping } from "./queries/getZT";
import { GZT } from "../components/DataManagement/DataManagement";

const { dataSourceName } = atlasConfig;

export function useZTQueries() {
  // Set up a list of todos in state
  const app = useApp();
  const [ztfw, setZTfw] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  // Get a client object for the todo item collection
  const ztCollection = useCollection({
    cluster: dataSourceName,
    db: "zt",
    collection: "zt-controls",
  });

//   const ztActivityCollection = useCollection({
//     cluster: dataSourceName,
//     db: "zt",
//     collection: "zt-mpe-plan",
//   });

//   const nistCollection = useCollection({
//     cluster: dataSourceName,
//     db: "zt",
//     collection: "nist800-53-controls",
//   })

  // Fetch all todos on load and whenever our collection changes (e.g. if the current user changes)
  React.useEffect(() => {
    // let shouldUpdate = true;
    // const fetchZTfw = ztCollection.aggregate(q_getZTInitial).then((res) => res[0]);
    // if (shouldUpdate) {
    //   fetchZTfw.then((fetchedTodos) => {
    //     GZT.capabilities = fetchedTodos.capabilities;
    //     setZTfw(fetchedTodos);
    //     setLoading(false);
    //   });
    // }
    // return () => {
    //   shouldUpdate = false;
    // }
  }, [ztCollection]);

  // Use a MongoDB change stream to reactively update state when operations succeed
  // useWatch(todoItemCollection, {
  //   onInsert: (change) => {
  //     setTodos((oldTodos) => {
  //       if (loading) {
  //         return oldTodos;
  //       }
  //       const idx =
  //         getTodoIndex(oldTodos, change.fullDocument) ?? oldTodos.length;
  //       if (idx === oldTodos.length) {
  //         return addValueAtIndex(oldTodos, idx, change.fullDocument);
  //       } else {
  //         return oldTodos;
  //       }
  //     });
  //   },
  //   onUpdate: (change) => {
  //     setTodos((oldTodos) => {
  //       if (loading) {
  //         return oldTodos;
  //       }
  //       const idx = getTodoIndex(oldTodos, change.fullDocument);
  //       return updateValueAtIndex(oldTodos, idx, () => {
  //         return change.fullDocument;
  //       });
  //     });
  //   },
  //   onReplace: (change) => {
  //     setTodos((oldTodos) => {
  //       if (loading) {
  //         return oldTodos;
  //       }
  //       const idx = getTodoIndex(oldTodos, change.fullDocument);
  //       return replaceValueAtIndex(oldTodos, idx, change.fullDocument);
  //     });
  //   },
  //   onDelete: (change) => {
  //     setTodos((oldTodos) => {
  //       if (loading) {
  //         return oldTodos;
  //       }
  //       const idx = getTodoIndex(oldTodos, { _id: change.documentKey._id });
  //       if (idx >= 0) {
  //         return removeValueAtIndex(oldTodos, idx);
  //       } else {
  //         return oldTodos;
  //       }
  //     });
  //   },
  // });

  // Given a draft todo, format it and then insert it


  const getActivityMappings = async () => {
    return ztCollection.aggregate(q_getActivityMapping);
  }


  return {
    getActivityMappings,
  };
}
