import React, { useState } from "react";
import { useCollection } from "./useCollection";
// import { useApp } from "../components/RealmApp";
import atlasConfig from "../atlasConfig.json";
import { q_getZTInitial, q_getCapabilityDesc, q_getActivity, q_getNIST, q_getActivityMapping, q_getNistFamily, q_getCapability } from "./queries/getZT";
import { GZT } from "../components/DataManagement/DataManagement";
import axios from "axios";
import { useApp } from "../components/RealmApp";
import { g_api } from "../globals/api";

const { dataSourceName } = atlasConfig;

export function useZT() {
  // Set up a list of todos in state
  // const app = useApp();
  const app = useApp();
  const [ztfw, setZTfw] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [apiError, setApiError] = useState(false);
  const isLocal = app?.isLocalApp;

  // Get a client object for the todo item collection
  const ztCollection = useCollection({
    cluster: dataSourceName,
    db: "zt",
    collection: "zt-controls",
  });

  const ztActivityCollection = useCollection({
    cluster: dataSourceName,
    db: "zt",
    collection: "zt-mpe-plan",
  });

  const nistCollection = useCollection({
    cluster: dataSourceName,
    db: "zt",
    collection: "nist800-53-controls",
  })

  // Fetch all todos on load and whenever our collection changes (e.g. if the current user changes)
  React.useEffect(() => {
    if (isLocal) {
      async function fetchData() {
        try {
          const activities = await axios.get(`${g_api.root}/zt/activities`, { withCredentials: true });
          const nistFamily = await axios.get(`${g_api.root}/nist/800-53/family`, { withCredentials: true });

          GZT.nistFamily = [...nistFamily.data];
          GZT.capabilities = activities.data.capabilities;
          GZT.activities = activities.data.activities;
          GZT.pillars = activities.data.pillars;
          setZTfw(activities.data);
          setLoading(false);
        } catch (error) {
          console.log(error);
        }
      }
      fetchData();
    } else {
      async function fetchData() {
        const fetchZTfw = await ztCollection.aggregate(q_getZTInitial).then((res) => res[0]);
        const nistFamily = await getNistFamily();
        GZT.nistFamily = [...nistFamily];
        GZT.capabilities = fetchZTfw.capabilities;
        GZT.activities = fetchZTfw.activities;
        GZT.pillars = fetchZTfw.pillars;
        setZTfw(fetchZTfw);
        setLoading(false);
      };
      fetchData();
    }


  }, [ztCollection]);


  const getActivity = async (aId, isUser = false) => {
    if (isLocal) return axios.get(`${g_api.root}/zt/activity/${aId}`, { withCredentials: true }).then(res => res.data);
    return ztCollection.aggregate(q_getActivity(aId, isUser));
  }

  const getCapabilityDesc = async (cId) => {
    if (isLocal) return axios.get(`${g_api.root}/zt/capabilityDesc/${cId}`, { withCredentials: true }).then(res => res.data);
    return ztCollection.aggregate(q_getCapabilityDesc(cId));
  }

  const getCapability = async (cId) => {
    return ztCollection.aggregate(q_getCapability(cId));
  }

  const getCurrentProjects = async () => {
    if (isLocal) return axios.get(`${g_api.root}/ztkm/allprojects`, { withCredentials: true }).then(res => res.data);
  }

  const getActivityMappings = async () => {
    return ztCollection.aggregate(q_getActivityMapping);
  }

  const getNistFamily = async () => {
    return nistCollection.aggregate(q_getNistFamily);
  }

  const getMPE = async () => {
    return ztActivityCollection.findOne();
  }

  const updateActivity = async (data) => {
    return ztActivityCollection.updateOne(
      { aId: data.aId },
      {
        $set: { ...data }
      },
      { upsert: true }
    )
  }

  return {
    loading,
    apiError,
    ztfw: ztfw,
    getCapabilityDesc,
    getMPE,
    getActivity,
    updateActivity,
    getActivityMappings,
    getNistFamily,
    getCurrentProjects
  };
}
