import { useApp } from "../components/RealmApp";
import { useAPIHandler } from "./useAPIHandler";

type T_ModifyStatus = {
    ztId: string;
    status: string;
}

const useModifyZTKMData = () => {
    const { activeProject }: any = useApp();
    const { postZTKMDataModify } = useAPIHandler();

    const handleClick = async (modData: any) => {
        const project = activeProject?.selection?.name;

        return postZTKMDataModify({
            project,
            modData,
        });
    }
    return handleClick;
}

export default useModifyZTKMData;