import React, { useContext, useEffect, useState } from "react";
import SurveyToolAC from "./SurveyToolAC";
import { useApp } from "../RealmApp";
import { getProjectAnalytics } from "../../apiCalls/apiZTKM";

type T_ZTACRootContext = {
    ztacAnalytics: any;
    refetchZTACAnalytics: () => void;
}

export const ZTACRootContext = React.createContext<T_ZTACRootContext | undefined>(undefined);

export function useZTACRootContext() {
    const context = useContext(ZTACRootContext);
    if (!context) {
        throw new Error('useZTACContext must be used within a ZTAC Survey Root Component');
    }
    return context;
}

type T_ComponentProps = {
    children: React.ReactNode;
}

export type T_ZTACAnalytics = {
    maturity: string;
    status: string;
    ztacId: string;
}[]

const SurveyToolACProvider = () => {
    const { activeProject }: any = useApp();
    const projectName = activeProject?.selection?.name;
    const [ztacAnalytics, setZTACAnalytics] = useState<T_ZTACAnalytics>([]);

    useEffect(() => {
        if(!projectName) return;
        getZTACAnalytics(projectName);
    },[projectName])


    const getZTACAnalytics = async (projectName: string) => {
        return getProjectAnalytics(projectName, 'ztac').then((data) => {
            if (!data?.ztacData) return setZTACAnalytics([]);
            if (!Object.entries(data.ztacData).length) return setZTACAnalytics([]);
            setZTACAnalytics([
                ...data.ztacData
            ])
        });
    }

    const refetchZTACAnalytics = () => {
        if (!projectName) return;
        return getZTACAnalytics(projectName);
    }

    const rootZTACContext = React.useMemo(() => {
        return {
            refetchZTACAnalytics,
            ztacAnalytics,
        };
    }, [ztacAnalytics, projectName]);

    return (
        <ZTACRootContext.Provider value={rootZTACContext}>
            <SurveyToolAC />
        </ZTACRootContext.Provider>
    )
}

export default SurveyToolACProvider;