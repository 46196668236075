import ACStatus from "./ACStatusButton";
import { useZTACContext, ZTACBullet } from "./ZTAC_Component";



const ZTAC4 = () => {
    return (
        <></>
    )
};

ZTAC4.Title = function ZTAC1_Title() {
    return (
        <>
            <div className="ztac-title">4.0: Are the resources fully ascribed with operating characterizations?</div>
            <div className="ztac-subtitle">attribution</div>
        </>
    )
}

ZTAC4.Intro = function ZTAC4_Intro() {
    return (
        <>
            <div>
                <h3>Acceptance Criteria:</h3>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="ac-001">The specific resource has a fully defined characterization profile (attributes) that provides a clear and precise understanding of the constraints that restrict use during any operation.</ZTACBullet>
                </ul>
            </div>

            <p className="font-italic">The constraints are notionally administered using an automated business rule engine to determine if a leveraging the resource remains in compliance with policy and established constraints.</p>
            <p className="font-italic">For the purpose of this acceptance criteria, “resources” are typically identified as callable services, compute, applications, software, μServices, pipelines, platforms or other capabilities that perform an action based on instructions, commands, programmed sequences or mechanical mechanisms. They can be as simple as an electronic lock to the complexity of a Military Fighter Jet.</p>
            <p className="font-italic">For example: if the resource is required to operate at the Top Secret level, then one attribute that must be present is “operating_classfication” with a value set to “Top Secret” [e.g., Classification: “Top Secret”].</p>
            <p className="font-italic">NOTE: operating_classification is notional and a CCV would actually be used (or an LCV) or the local “Metadata Label”.</p>

            <div>
                <h3>Outcome:</h3>
                <p>The set of attributes for a resource can be explicitly and exhaustively mapped to any use case, vignette, day-in-the-Life thread or process model to regulate and arbitrate if constraint conditions are encountered while executing any action in the “operational” stream that is specified.</p>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="ou-001">Each of the “attributes” are clearly articulated.</ZTACBullet>
                    <ZTACBullet code="ou-002">Each attribute has the specified value for all known (and unknown) operating conditions.</ZTACBullet>
                    <ZTACBullet code="ou-003">A full map of the attributes and outcomes (requirements streams) can be generated to provide comprehensive insight to all acceptable operating conditions for the resource.</ZTACBullet>
                </ul>
            </div>

            <div>
                <h3>Conceptual Measures:</h3>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="cm-001">The Resource attributes are discrete and identifiable.</ZTACBullet>
                    <ZTACBullet code="cm-002">A complete listing of all attributes that are applied to the Resource are discoverable (if authorized).</ZTACBullet>
                    <ZTACBullet code="cm-003">No attribute combination creates a non-executable state.</ZTACBullet>
                    <ZTACBullet code="cm-004">The attributes can be computationally applied to a role request and determine if access is granted to perform an action.</ZTACBullet>
                    <ZTACBullet code="cm-005">The attributes are expressed in a way that are machine-readable and can be applied to Business rules for policy enforcement (digital in cyberspace and non-digital in non-cyberspace [think SCIF access]).</ZTACBullet>
                    <ZTACBullet code="cm-006">The resource attributes are sufficient and can be demonstrated to properly constrain operational execution.</ZTACBullet>
                    <ZTACBullet code="cm-007">The entirety of attributes cannot be composed to an unknown state.</ZTACBullet>
                    <ZTACBullet code="cm-008">All attributes of a Resource can be modeled for positive Human affirmation over time and change states can be reported.</ZTACBullet>
                </ul>
            </div>
        </>
    )
}

ZTAC4.UseCase = function ZTAC4_UseCase() {
    return (
        <div>
            <h3>Implementation Use Case: (example)</h3>
            <p>Scenario: Zero Trust Resource Characterization for a Secure Database Service.</p>

            <ul>
                <li>
                    Define Resource Attributes:
                    <ul>
                        <li>Collaborate with IT, security, and compliance teams to define the necessary identity, operational, behavioral, and supplemental attributes for the Secure Database Service.</li>
                    </ul>
                </li>
                <li>
                    Map Attributes to Use Cases:
                    <ul>
                        <li>Map each attribute of the Secure Database Service to the required use cases, ensuring all operational and exceptional conditions are covered.</li>
                    </ul>
                </li>
                <li>
                    Automate Business Rules:
                    <ul>
                        <li>Implement an automated business rule engine to enforce constraints based on the defined attributes and policies.</li>
                    </ul>
                </li>
                <li>
                    Generate Attribute Maps:
                    <ul>
                        <li>Use automated tools to generate a comprehensive map of attributes and outcomes, providing insight into all acceptable operating conditions for the resource.</li>
                    </ul>
                </li>
                <li>
                    Policy Enforcement:
                    <ul>
                        <li>Implement policy enforcement mechanisms to ensure that access control and operational constraints are maintained according to the defined attributes and business rules.</li>
                    </ul>
                </li>
                <li>
                    Monitor and Report:
                    <ul>
                        <li>Continuously monitor resource attributes and report any changes or anomalies to ensure ongoing compliance and security.</li>
                    </ul>
                </li>
            </ul>
        </div>
    )
}

export default ZTAC4;