import { Button } from "primereact/button";
import { Card } from "primereact/card"
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Panel } from "primereact/panel";
import { ChangeEvent, useEffect, useReducer, useRef, useState } from "react";

import './ZTSurveyEditCapabilities.css';
import { Badge } from "primereact/badge";
import { getMaturityObject } from "./ZTSurveyActivitiesComponent";
import { Dialog } from "primereact/dialog";
import reducerEditCapability, { emptyEditCapabilityReducer, T_CapabilityOverview } from "./reducerEditCapability";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { useApp } from "../RealmApp";
import { IconBase } from "react-icons";
import EditCapabilityPopup from "./EditCapabilityPopup";
import { postModifyCapabilities } from "../../apiCalls/apiZTKM";
import { toastError, toastSuccess } from "../utils/toastUtils";

export const statusTemplate = (data: T_CapabilityOverview) => {
    const obj = getMaturityObject(data.status);
    return (
        // @ts-ignore
        <Badge value={data?.status} severity={obj?.severity} className="" />
    )
}

export const linkedCapabilitiesTemplate = (data: T_CapabilityOverview) => {
    const countLinks = (data?.ztcIds) ? data.ztcIds.length : 0;
    return (
        <>
            {countLinks}
        </>
    )
}

const ZTSurveyEditCapabilities = () => {
    const [state, dispatch] = useReducer(reducerEditCapability, emptyEditCapabilityReducer)
    const { toast, activeProject, refreshProjectData }: any = useApp();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleButtonClick = () => {
        fileInputRef.current?.click();
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            console.log('File selected:', file);
            // You can perform additional actions like uploading the file here
        }
    };

    const headerDatatable = () => (
        <div className="flex flex-row justify-content-center">
            <Button label="Add New Capability" severity="success" outlined icon='pi pi-plus' size="small" onClick={() => dispatch({ type: 'addCapability' })} />
            {/* <div className="flex gap-2">
                <Button icon='pi pi-upload' label="Import" outlined severity="secondary" onClick={handleButtonClick} />
                <Button icon='pi pi-download' label="Export" outlined severity="secondary" />
            </div> */}
        </div>
    )

    const editTemplate = (data: T_CapabilityOverview) => (
        <div className="flex flex-row gap-2">
            <span className="pi pi-pencil p-button p-button-text p-button-secondary"
                data-pc-section='icon' style={{ padding: '0.1rem' }}
                onClick={() => dispatch({ type: 'editCapability', payload: data.name })} />
            <span className="pi pi-times p-button p-button-text p-button-secondary"
                data-pc-section='icon' style={{ padding: '0.1rem' }}
                onClick={() => confirmDeleteCapability(data.name)} />
        </div>
    )

    const closeEditCapability = () => {
        dispatch({ type: 'update', field: 'editCapability', payload: false })
    }

    const handleDeleteCapabilities = (name: string) => {
        const project = activeProject?.selection?.name;
        postModifyCapabilities({
            project,
            capabilities: [name],
        }, 'delete').then((msg) => {
            toastSuccess(toast, msg?.msg || '', 'Delete')
            refreshProjectData();
        }).catch((err) => {
            toastError(toast, err?.response?.data?.message || 'Unknown Error', err?.code || 'Error');
        })
    }

    const confirmDeleteCapability = (name: string) => {
        confirmDialog({
            message: `Delete ${name}?`,
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            // @ts-ignore
            accept: () => handleDeleteCapabilities(name),
            reject: () => { },
        });
    };

    const selectNewCapability = (capabilityName: string) => {
        dispatch({ type: 'editCapability', payload: capabilityName })
    }

    return (
        <>
            <DataTable value={activeProject?.ztCapabilities || []} style={{}}
                key={`dt-${activeProject?.name}`}
                size="small" scrollable scrollHeight="50vh"
                header={headerDatatable}
                sortField="name"
                sortOrder={1}
                dataKey="name" rows={10} paginator >
                <Column field="name" header="Name" sortable />
                <Column header="Links" sortable sortField="links"
                    body={linkedCapabilitiesTemplate}
                    sortFunction={(e: any) => {
                        return e.data.sort((a: any, b: any) => e.order * (b.ztcIds.length - a.ztcIds.length))
                    }} />
                <Column field='status' header="Status" sortable body={statusTemplate} />
                <Column body={editTemplate} />
            </DataTable>
            <input
                type="file"
                ref={fileInputRef}
                accept=".csv"
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
            <EditCapabilityPopup onClose={closeEditCapability} visible={state.editCapability} capabilityName={state.selectedCapability} selectNew={selectNewCapability} />
        </>
    )
}

export default ZTSurveyEditCapabilities;