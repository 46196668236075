import { Button } from "primereact/button";
import { useState } from "react";
import SurveyExportRFI from "../SurveyExportRFI/SurveyExportRFI";
import ImportNewProjectXlsx from "../ImportProject/ImportNewProjectXlsx";
import { NavLink } from "react-router-dom";



const HomeSurvey = () => {
    const [showExport, setShowExport] = useState(false);

    return (
        <>
            <div className="p-4 overflow-y-auto">
                <h3>Instructions:</h3>
                <p>Welcome to the Zero Trust Survey Tool.</p>
                <p>This tool is designed to guide you through the process of completing your Zero Trust Survey for IDAF Section 2.8.1.</p>
                <ol className="flex flex-column gap-3">
                    <li>
                        <strong>Capabilities:</strong> Identify and list all relevant capabilities, products, and services in your environment that support Zero Trust principles. For each capability, provide a brief description and upload any supporting files or artifacts to confirm compliance, if applicable.
                    </li>
                    <li>
                        <strong>Link:</strong> Associate the identified capabilities with specific Zero Trust Capabilities to ensure accurate alignment. If certain Zero Trust Capabilities are not currently implemented, mark them as "Not Planned" or another appropriate status.
                    </li>
                    <li>
                        <strong>Activities:</strong> Review each Zero Trust Activity and respond to the associated questions. Where applicable, upload evidence in the form of files to support your responses.
                    </li>
                </ol>

                <strong>Report:</strong> The Reports section provides an overview of your progress, showing the completeness of the survey and offering a generic scoring metric based on your inputs.

                <h4>Exports:</h4>
                <h5>Export RFI:</h5>
                <p>Export a Request for Information (RFI) document to complete ahead of the survey for easier data entry and preparation.</p>
                <Button label="Export RFI" icon='pi pi-file-export' onClick={() => setShowExport(true)} />
                <h5>Export Page:</h5>
                <p>Click on this link and export the page to HTML for easy review and sharing of all responses and artifacts.</p>
                <NavLink to="/exportpage" className='p p-button no-underline'>
                    {/* <BiTargetLock className='navItem-icon' /> */}
                    <span className="p-button-icon p-c p-button-icon-left pi pi-file-check" />
                    <span className='p-button-label p-c'>Export Page</span>
                </NavLink>
                {/* ExportProjectPage */}
            </div>
            {showExport &&
                <SurveyExportRFI show={showExport} onHide={() => setShowExport(false)} />
            }
        </>
    )
}

export default HomeSurvey;