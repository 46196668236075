import ACStatus from "./ACStatusButton";
import { useZTACContext, ZTACBullet } from "./ZTAC_Component";

const ZTAC3 = () => {
    return (
        <></>
    )
};

ZTAC3.Title = function ZTAC1_Title() {
    return (
        <>
            <div className="ztac-title">3.0: Is the role you assume fully specified?</div>
            <div className="ztac-subtitle">attribution and behavioral rules</div>
        </>
    )
}

ZTAC3.Intro = function ZTAC3_Intro() {

    return (
        <>
            <div>
                <h3>Acceptance Criteria:</h3>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="ac-001">The Role has been ascribed with all required attributes in compliance with policy.</ZTACBullet>
                    <ZTACBullet code="ac-002">The Role has been ascribed with all supplemental attributes necessary to make the Role useful for operational activities.</ZTACBullet>
                    <ZTACBullet code="ac-003">The Role also includes all of the attributes necessary to meet the operational needs established based on authorities, privileges, responsibilities, and permissions to perform the necessary work established for the Role.</ZTACBullet>
                </ul>
            </div>

            <div>
                <h3>Outcome:</h3>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="ou-001">Each of the “duties” assigned to this Role has the necessary attributes mapped, ensuring the outcomes assigned to this role are permissible, practicable, possible and achievable, given normal operating conditions.</ZTACBullet>
                    <ZTACBullet code="ou-002">For exceptional considerations, the attributes necessary to achieve the required outcome shall also be mapped to this role in direct alignment with the exceptions.</ZTACBullet>
                    <ZTACBullet code="ou-003">A full map of the attributes and outcomes (requirements streams) can be generated to provide comprehensive insight to all latitudes the Role is empowered to execute.</ZTACBullet>
                    <ZTACBullet code="ou-004">A complete mapping of the Actors to this Role can be generated to affirm that the Actor-Role pairing.</ZTACBullet>
                </ul>
            </div>

            <div>
                <h3>Conceptual Measures:</h3>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="cm-001">A Role has a complete listing of all attributes that are required for each work-thread the role is responsible to achieve.</ZTACBullet>
                    <ZTACBullet code="cm-002">A Role has no attributes that are not explicitly mapped to a work-thread.</ZTACBullet>
                    <ZTACBullet code="cm-003">A Role has at least one Actor (and specifically “This Actor”) formally associated as an IBAC/RBAC pair.</ZTACBullet>
                    <ZTACBullet code="cm-004">The Attributes are expressed in a way that are machine-readable and can be applied to Business rules for policy enforcement (digital in cyberspace and non-digital in non-cyberspace [think SCIF access]).</ZTACBullet>
                    <ZTACBullet code="cm-005">The Actor-Role attributes are sufficient and can be demonstrated to execute each work-thread.</ZTACBullet>
                    <ZTACBullet code="cm-006">The Actor-Role attributes cannot be composed to an unknown state.</ZTACBullet>
                    <ZTACBullet code="cm-007">All Role associations can be modeled to an Actor for positive Human affirmation over time and change states can be reported.</ZTACBullet>
                </ul>
            </div>
        </>
    )
}

ZTAC3.UseCase = function ZTAC3_UseCase() {
    return (
        <div>
            <h3>Implementation Use Case: (example)</h3>
            <ul>
                <li>
                    Define Role Attributes:
                    <ul>
                        <li>Collaborate with admin's department/division, IT, and security teams to define the necessary identity, behavioral, operational, and supplemental attributes for the Privileged User role.</li>
                    </ul>
                </li>
                <li>
                    Map Duties to Attributes:
                    <ul>
                        <li>Map each duty of the Privileged User role to the required attributes, ensuring all operational and exceptional conditions are covered.</li>
                    </ul>
                </li>
                <li>
                    Assign Actors:
                    <ul>
                        <li>Assign employees to the Privileged User role, ensuring that each actor meets the required attributes and is compliant with security policies.</li>
                    </ul>
                </li>
                <li>
                    Generate Attribute Maps:
                    <ul>
                        <li>Use automated tools to generate a comprehensive map of attributes and outcomes, as well as actor-role pairings.</li>
                    </ul>
                </li>
                <li>
                    Policy Enforcement:
                    <ul>
                        <li>Implement policy enforcement mechanisms to ensure that access control is maintained according to the defined attributes and business rules.</li>
                    </ul>
                </li>
                <li>
                    Monitor and Report:
                    <ul>
                        <li>Continuously monitor role associations and report any changes or anomalies to ensure ongoing compliance and security.</li>
                    </ul>
                </li>
            </ul>
        </div>
    )
}

export default ZTAC3;