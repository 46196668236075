
import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import useResponsive from '../../hooks/useResponsive';

import './SurveySidebar.css';
import { SelectButton } from 'primereact/selectbutton';
import { useApp } from '../RealmApp';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { getZTProfileDataCache } from '../DataManagement/DataManagement';
import { useApi } from '../ProviderAPIs';
import { InputText } from 'primereact/inputtext';
import { TableIconType } from '../ZTPage/ZTActivityTable';
import { Ripple } from 'primereact/ripple';

type phaseOption = {
    name: 0 | 1 | 2 | 3 | 4;
    value: 0 | 1 | 2 | 3 | 4;
}

const phasesOptions: phaseOption[] = [
    { name: 0, value: 0 },
    { name: 1, value: 1 },
    { name: 2, value: 2 },
    { name: 3, value: 3 },
    { name: 4, value: 4 },
]

const ztLevelOptions: any = [
    { name: 'Target', value: 'target' },
    { name: 'Adv', value: 'advanced' },
]

type T_SurveySidebarFilters = {
    profile: string;
    level: string[];
    phases: number[];
    search: string;
    filterCount: string;
}

export const defaultSurveyFilter:T_SurveySidebarFilters = {
    profile: 'ZT-All',
    level: ['target', 'advanced'],
    phases: [0, 1, 2, 3, 4],
    search: '',
    filterCount: '0/152',
}

export type T_SurveySidebarActions = {
    type: string;
    field?: string;
    payload?: any;
}

export function surveyFilterReducer(state: T_SurveySidebarFilters, action: T_SurveySidebarActions) {
    switch (action.type) {
        case 'level':
            return {
                ...state,
                level: action.payload,
            }
        case 'phase':
            return {
                ...state,
                phases: action.payload,
            }
        case 'search':
            return {
                ...state,
                search: action.payload,
            }
        case 'filterCount': {
            return {
                ...state,
                filterCount: action.payload,
            }
        }
        case 'reset':
            return {
                ...defaultSurveyFilter
            }
        case 'update':
            if (!action?.field) return state;
            return {
                ...state,
                [action.field]: action.payload,
            }
        default:
            return state;
    }
}

export default function SurveySidebar({ state, dispatch }: any) {
    const { ztProfiles, activeProject }: any = useApp();
    // const menuLeft = useRef<any>(null);
    const windowSize = useResponsive();
    const showPopup = windowSize !== 'lg';
    const [visible, setVisible] = useState(!showPopup);
    const api: any = useApi();
    // const optionProfiles = ztProfiles.map((x: any) => ({ name: x.name }));

    if (!showPopup && !visible) setVisible(true);

    useEffect(() => {
        if (!activeProject?.profile) return;
        dispatch({ type: 'update', field: 'profile', payload: activeProject.profile })
    }, [activeProject?.profile])

    const handleProfileUpdate = async (e: DropdownChangeEvent) => {
        await getZTProfileDataCache(e.value.name, api.getZTProfileData);
        dispatch({ type: 'update', field: 'profile', payload: e.value.name })
    };

    return (
        <>
            {showPopup &&
                <Button size='small' severity='secondary' icon="pi pi-cog"
                    className="mr-2 sb-survey-btn"
                    style={{ zIndex: 9 }}
                    onClick={() => setVisible(prev => !prev)}
                    aria-controls="popup_menu_left" aria-haspopup />
            }
            {visible &&
                <div className="survey-sidebar-content custom-sidebar surface-section h-screen block flex-shrink-0 absolute lg:static md:static left-0 top-0 z-1 border-right-1 surface-border select-none" style={{ width: '250px' }}>
                    <div className="flex flex-column h-full">
                        <div className="overflow-y-auto">
                            <ul className="list-none p-3 m-0 overflow-hidden">
                                <li>
                                    <div className="p-ripple p-inputgroup flex-1 p-3 border-round text-700 w-full">
                                        <InputText size="small"
                                            type="text"
                                            value={state.search}
                                            style={{ width: '100%' }}
                                            onChange={(e: any) => dispatch({ type: 'search', payload: e.target.value })}
                                            placeholder={`Search ID/Name`} />
                                        <Button icon={TableIconType(state.search)} onClick={() => dispatch({ type: 'search', payload: '' })} outlined severity='secondary' />
                                    </div>
                                </li>
                                <li>
                                    <div className="p-ripple flex flex-column p-3 border-round text-700 w-full gap-1">
                                        <div className=''>Profile:</div>
                                        <Dropdown value={{ 'name': state.profile }} onChange={handleProfileUpdate} options={ztProfiles} optionLabel="name"
                                            placeholder="Filter by Profile" className="w-full" />
                                    </div>
                                </li>
                                <li>
                                    <div className="p-ripple flex flex-column p-3 border-round text-700 w-full">
                                        <span className='font-bold'>Filters [{state.filterCount}]</span>
                                    </div>

                                </li>
                                <li>
                                    <div className="p-ripple flex flex-column p-3 border-round text-700 w-full gap-1">
                                        <span className=''>ZT Level:</span>
                                        <SelectButton className='p-menuitem-link' value={state.level} onChange={(e) => dispatch({ 'type': 'level', payload: e.value })} multiple optionLabel="name" options={ztLevelOptions} />
                                    </div>
                                </li>
                                <li>
                                    <div className="p-ripple flex flex-column p-3 border-round text-700 w-full gap-1">
                                        <span className=''>Phases:</span>
                                        <SelectButton className='p-menuitem-link' value={state.phases} onChange={(e) => dispatch({ 'type': 'phase', payload: e.value })} multiple optionLabel="name" options={phasesOptions} />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}