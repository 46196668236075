import ACStatus from "./ACStatusButton";
import { CodeBlock, useZTACContext, ZTACBullet } from "./ZTAC_Component";



const ZTAC9 = () => {
    return (
        <></>
    )
};

ZTAC9.Title = function ZTAC1_Title() {
    return (
        <>
            <div className="ztac-title">9.0: Can Behavioral Analytics track and evaluate common and uncommon patterns?</div>
            <div className="ztac-subtitle">ML/AI</div>
        </>
    )
}

ZTAC9.Intro = function ZTAC9_Intro() {
    return (
        <>
            <div>
                <h3>Acceptance Criteria:</h3>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="ac-001">Use advanced analytic techniques (to include but not limited to Artificial Intelligence and Machine Learning) to identify behavioral patterns. These patterns have no limitation or specific type of information and should indicate any consistent, erratic or unexpected behaviors exhibited within the system. The boundary of the inspection can be as narrow or as wide as needed to understand unique circumstances.</ZTACBullet>
                    <p>The analytic yields should inform an analyst of operational behaviors that are typical, unacceptable or unanticipated as follows:</p>
                    <ZTACBullet code="ac-002">Typical or doctrinally specified behavior</ZTACBullet>
                    <ZTACBullet code="ac-003">Outlier events that deviate from a dynamically prescribe threshold</ZTACBullet>
                    <ZTACBullet code="ac-004">Aberrant internal: path use; timing cycles; or density of material access</ZTACBullet>
                    <ZTACBullet code="ac-005">User access cycles (fast success attempts; fast failure attempts, etc.)</ZTACBullet>
                    <ZTACBullet code="ac-006">Atypical resource, asset and object use</ZTACBullet>
                    <ZTACBullet code="ac-007">Atypical directed infrastructure specifications</ZTACBullet>
                    <ZTACBullet code="ac-008">Atypical wall clock time scenario</ZTACBullet>
                    <ZTACBullet code="ac-009">Atypical geolocation scenario</ZTACBullet>
                    <ZTACBullet code="ac-010">Atypical utilization scenario (assets, objects, resources)</ZTACBullet>
                    <ZTACBullet code="ac-011">Last minute events (credential expiration within 24 hours) and unusual workload demands</ZTACBullet>
                </ul>
            </div>

            <div>
                <h3>Outcome:</h3>
                <ul className="flex flex-column gap-2">
                    <ZTACBullet code="ou-001">Reporting (static, dynamic) of each event and type</ZTACBullet>
                    <ZTACBullet code="ou-002">Trend tracking using programmed periodicity</ZTACBullet>
                    <ZTACBullet code="ou-003">Trend evolution based on “learning” (non-programmed)</ZTACBullet>
                    <ZTACBullet code="ou-004">Recommendation of remediation approach</ZTACBullet>
                    <ZTACBullet code="ou-005">Predictive opportunities for digital policy formulation</ZTACBullet>
                    <ZTACBullet code="ou-006">Preemptive opportunities for advanced defense postures</ZTACBullet>
                </ul>
            </div>

            <div>
                <h3>Conceptual Measures:</h3>
                <ul className="flex flex-column gap-2">
                    <li>
                        Report generation can accommodate any or all of the following content:
                        <ul className="flex flex-column gap-2">
                            <ZTACBullet code="cm-001">Typical behavior patterns [within the system, mass community, individual actor, asset and resource use patterns]</ZTACBullet>
                            <ZTACBullet code="cm-002">Outlier identification</ZTACBullet>
                            <ZTACBullet code="cm-003">Rule execution does not deviate from the digital policy.</ZTACBullet>
                            <ZTACBullet code="cm-004">Trend depiction of digital policy effects</ZTACBullet>
                            <ZTACBullet code="cm-005">Trend depiction of behavioral shift based on rule adjustments</ZTACBullet>
                            <ZTACBullet code="cm-006">Data generation availability based on discrete observations</ZTACBullet>
                            <ZTACBullet code="cm-007">Recommendations for digital policy rules</ZTACBullet>
                            <ZTACBullet code="cm-008">Assessment of attribute use in rules</ZTACBullet>
                            <ZTACBullet code="cm-009">Assessment of rule invocation during operations</ZTACBullet>
                            <ZTACBullet code="cm-010">Assessment of rule gap events</ZTACBullet>
                            <ZTACBullet code="cm-011">Assessment of rule collision or competition conditions</ZTACBullet>
                        </ul>
                    </li>
                    <ZTACBullet code="cm-012">Information repository density trends of foundational data collection</ZTACBullet>
                    <ZTACBullet code="cm-013">Presentation of adoption trend of proposed digital policy recommendations</ZTACBullet>
                    <ZTACBullet code="cm-014">Propagation timing for dynamic modification of a rule produces</ZTACBullet>
                </ul>
            </div >
        </>
    )
}

ZTAC9.UseCase = function ZTAC9_UseCase() {
    return (
        <div>
            <h3>Implementation Use Case: (example)</h3>
            <p>Zero Trust Behavioral Analytics for Access Control in OCFO</p>
            <p>Scenario: Use advanced analytic techniques, including Artificial Intelligence (AI) and Machine Learning (ML), to identify and evaluate behavioral patterns of users accessing financial data. The goal is to detect typical, unacceptable, or unanticipated behaviors within the system.</p>

            <ul>
                <li>
                    Define Behavioral Attributes:
                    <ul>
                        <li>Collaborate with IT, security, and compliance teams to define the necessary user, behavioral, and activity attributes for monitoring.</li>
                    </ul>
                </li>
                <li>
                    Develop Analytics Models:
                    <ul>
                        <li>
                            Develop AI and ML models to analyze behavioral patterns based on the defined attributes. For example:
                            <ul>
                                <li>Identify typical access patterns for financial analysts.</li>
                                <li>Detect outlier events that deviate from normal behavior.</li>
                                <li>Monitor access cycles and detect fast success or failure attempts.</li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    Automate Data Collection:
                    <ul>
                        <li>Implement automated data collection mechanisms to gather information on user activities, access times, locations, device compliance, and transaction volumes.</li>
                    </ul>
                </li>
                <li>
                    Generate Reports:
                    <ul>
                        <li>Use automated tools to generate static and dynamic reports of each event and type, providing insight into typical and atypical behaviors.</li>
                    </ul>
                </li>
                <li>
                    Trend Tracking and Evolution:
                    <ul>
                        <li>Implement trend tracking mechanisms to monitor behavioral patterns over time and detect shifts based on rule adjustments.</li>
                    </ul>
                </li>
                <li>
                    Policy Recommendations:
                    <ul>
                        <li>Use analytic yields to recommend remediation approaches and formulate predictive digital policies.</li>
                    </ul>
                </li>
                <li>
                    Preemptive Defense Postures:
                    <ul>
                        <li>Implement preemptive defense mechanisms based on identified atypical behaviors and potential threats.</li>
                    </ul>
                </li>
            </ul>
            <br />
            <h4>Example Analytic Scenarios:</h4>
            <ul>
                <li>Typical Behavior:</li>
                <CodeBlock style={{ margin: '1rem' }}>
                    Role: Financial Analyst <br />
                    Access Time: 10 AM <br />
                    Access Location: Secure Office Network <br />
                    Device Compliance: Up-to-date Security Patches <br />
                    Device Type: Company-issued Laptop <br />
                    Access Frequency: 5 times/day <br />
                    Access Duration: 30 minutes/session <br />
                    Data Accessed: Financial Reports <br />
                    Transaction Volume: Normal <br />
                    Outcome: Typical Behavior
                </CodeBlock>
                <li>Outlier Event (Fast Failure Attempts):</li>
                <CodeBlock style={{ margin: '1rem' }}>
                    User ID: 12345 <br />
                    Role: Financial Analyst <br />
                    Access Time: 2 PM <br />
                    Access Location: Secure Office Network <br />
                    Device Compliance: Up-to-date Security Patches <br />
                    Device Type: Company-issued Laptop <br />
                    Access Frequency: 20 times/hour <br />
                    Access Duration: 1 minute/session <br />
                    Data Accessed: Financial Reports <br />
                    Transaction Volume: High <br />
                    Outcome: Outlier Event (Fast Failure Attempts)
                </CodeBlock>
                <li>Atypical Geolocation Scenario:</li>
                <CodeBlock style={{ margin: '1rem' }}>
                    Atypical Geolocation Scenario: <br />
                    User ID: 12345 <br />
                    Role: Financial Analyst <br />
                    Access Time: 3 PM <br />
                    Access Location: Unrecognized IP Address <br />
                    Device Compliance: Up-to-date Security Patches <br />
                    Device Type: Company-issued Laptop <br />
                    Access Frequency: 1 time <br />
                    Access Duration: 5 minutes <br />
                    Data Accessed: Financial Reports <br />
                    Transaction Volume: Normal <br />
                    Outcome: Atypical Geolocation Scenario
                </CodeBlock>
            </ul>

            <h4>Validation and Testing:</h4>
            <ul>
                <li>
                    Validate Analytic Models:
                    <ul>
                        <li>Validate AI and ML models to ensure they accurately identify typical, unacceptable, and unanticipated behaviors.</li>
                    </ul>
                </li>
                <li>
                    Generate and Validate Reports:
                    <ul>
                        <li>Generate static and dynamic reports and validate that they provide accurate insights into behavioral patterns.</li>
                    </ul>
                </li>
                <li>
                    Monitor Trends:
                    <ul>
                        <li>Continuously monitor trends and validate that trend tracking mechanisms accurately depict behavioral shifts.</li>
                    </ul>
                </li>
                <li>
                    Policy Recommendations:
                    <ul>
                        <li>Validate that policy recommendations are based on accurate analytic yields and provide effective remediation approaches.</li>
                    </ul>
                </li>
            </ul>
        </div >
    )
}

export default ZTAC9;