
import React, { useState, useRef, useMemo } from 'react';
import { Steps } from 'primereact/steps';
import { Toast } from 'primereact/toast';
import { MenuItem } from 'primereact/menuitem';
import { Knob } from 'primereact/knob';

import './StepsSurveyNavigation.css';
import { ProgressBar } from 'primereact/progressbar';
import { Badge } from 'primereact/badge';
import { useApp } from '../RealmApp';
import { GZT } from '../DataManagement/DataManagement';

type T_AnalyticObject = {
    ztId: string;
    type: 'activity' | 'capability';
    maturity?: string;
    status: string;
}

const validResponses = ['Answered'];

const getScores = (profileName:string, analytics:any) => {
    const baselineIds = GZT?.profiles?.find((x: any) => x.name === profileName)?.ztIds || [];
    let baselineActivities: Set<string> = new Set(baselineIds);
    let capabilitiesAnswered = 0;
    let activitiesAnswered = 0;

    analytics.forEach((obj: T_AnalyticObject) => {
        if (obj.type !== 'activity') return;
        if (obj.status === 'NA') baselineActivities.delete(obj.ztId);
        else baselineActivities.add(obj.ztId);
    });

    let baselineCapabilities = new Set();

    Array.from(baselineActivities).forEach((ztId) => {
        const capId = ztId.split('.', 2).join('.');
        baselineCapabilities.add(capId);
    })

    const inScopeActivitiesCapabilities = new Set([...baselineActivities, ...baselineCapabilities])

    analytics.forEach((obj: T_AnalyticObject) => {
        if (!inScopeActivitiesCapabilities.has(obj.ztId)) return;  // Skip if out of scope
        if (!validResponses.includes(obj.status)) return;  // Skip if not answered
        if (obj.type === 'activity') activitiesAnswered += 1;
        else if (obj.type === 'capability') capabilitiesAnswered += 1;
    })

    return {
        link: {
            low: capabilitiesAnswered,
            high: baselineCapabilities.size,
        },
        activities: {
            low: activitiesAnswered,
            high: baselineActivities.size,
        }
    }
}

export default function StepsSurveyNavigation({ activeIndex, setActiveIndex, style = {} }: any) {
    const { activeProject, analytics }: any = useApp();

    const scores = useMemo(() => {
        const profileName = activeProject?.profile || 'ZT-All';
        return getScores(profileName, analytics);
    }, [activeProject, analytics])

    const homeRenderer = (item: any, itemIndex: number) => {
        const isActiveItem = activeIndex === itemIndex;
        const backgroundColor = isActiveItem ? 'var(--primary-color)' : 'var(--surface-b)';
        const textColor = isActiveItem ? 'var(--surface-b)' : 'var(--text-color-secondary)';

        return (
            <div className='p-steps-item'>
                <span
                    className="p-menuitem-link inline-flex align-items-center justify-content-center align-items-center border-circle border-primary border-1 z-1 cursor-pointer"
                    style={{ backgroundColor: backgroundColor, color: textColor, height: '40px', width: '40px' }}
                    onClick={() => setActiveIndex(itemIndex)}
                >
                    <i className={`${item.icon} text-xl`} />
                </span>
            </div>
        );
    };

    const iconRender = (item: any, itemIndex: number) => {
        const isActiveItem = activeIndex === itemIndex;
        const backgroundColor = isActiveItem ? 'var(--primary-color)' : 'var(--surface-b)';
        const textColor = isActiveItem ? 'var(--surface-b)' : 'var(--text-color-secondary)';

        return (
            <div className='p-steps-item'>
                <span className='p-menuitem-link inline-flex align-items-center justify-content-between align-items-center cursor-pointer'
                    onClick={() => setActiveIndex(itemIndex)}>
                    <span
                        className="inline-flex align-items-center justify-content-center align-items-center border-circle border-primary border-1 z-1"
                        style={{ backgroundColor: backgroundColor, color: textColor, height: '35px', width: '35px' }}
                    >
                        <i className={`${item.icon} text-xl`} />

                    </span>
                    <div className='p-steps-title m-0'>{item.label}</div>
                </span>
            </div>
        );
    };



    const itemRenderer = (item: any, itemIndex: number) => {
        const type = (item.label.includes('Link')) ? 'link' : 'activities';
        const value = ((scores[type].low / scores[type].high) * 100).toFixed(0);

        const valueTemplate = () => {
            return (
                <div style={{ width: '0px', color: 'black', fontSize: 'smaller', position: 'absolute', top: 0, left: '0', marginLeft: '5px' }}>
                    {scores[type].low}/{scores[type].high}
                </div>
            );
        };

        return (

            <div
                className="card p-menuitem-link inline-flex flex-column align-items-center justify-content-between align-items-center cursor-pointer"
                style={{ height: '60px' }}
                onClick={() => setActiveIndex(itemIndex)}
            >
                {/* <Knob value={valRan[itemIndex - 1]} valueTemplate={'{value}%'} size={40} style={{ zIndex: 9, backgroundColor: 'white' }} readOnly /> */}
                <ProgressBar color='var(--color-badge-success)' value={value} displayValueTemplate={valueTemplate} style={{ width: '60px', marginTop: '3px' }} />
                <div className='p-steps-title m-0'>{item.label}</div>
            </div>

        )
    }

    const capabilityRenderer = (item: any, itemIndex: number) => {
        return (

            <div
                className="card p-menuitem-link inline-flex flex-column align-items-center justify-content-between align-items-center cursor-pointer"
                style={{ height: '60px' }}
                onClick={() => setActiveIndex(itemIndex)}
            >
                {/* <Knob value={valRan[itemIndex - 1]} valueTemplate={'{value}%'} size={40} style={{ zIndex: 9, backgroundColor: 'white' }} readOnly /> */}
                {/* <ProgressBar color='var(--color-badge-success)' value={valRan[itemIndex -1][0]} displayValueTemplate={valueTemplate} style={{width: '60px', marginTop: '3px'}}/> */}
                <Badge value={activeProject?.ztCapabilities?.length || 0} size="large" severity="info" style={{ zIndex: 9 }}></Badge>
                <div className='p-steps-title m-0'>{item.label}</div>
            </div>

        )
    }

    const items: MenuItem[] = [
        {
            label: 'Home',
            icon: 'pi pi-home',
            template: (item) => homeRenderer(item, 0)
        },
        {
            label: '1: Capabilities',
            template: (item) => capabilityRenderer(item, 1)
        },
        {
            label: '2: Link',
            template: (item) => itemRenderer(item, 2)
        },
        {
            label: '3: Activities',
            template: (item) => itemRenderer(item, 3)
        },
        {
            label: 'Reports',
            icon: 'pi pi-chart-bar',
            template: (item) => iconRender(item, 4)
        }
    ];

    return (
        <div className="card" style={{padding: '0.5rem 0rem', ...style }}>
            <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} />
        </div>
    )
}
