
export type T_CapabilityOverview = {
    name: string;
    url: string;
    linked: number;
    status: string;
    notes?: string;
    type: string;
    ztcIds: string[];
}

type T_EditCapabilityReducer = {
    capabilities: T_CapabilityOverview[];
    editCapability: boolean;
    selectedCapability: '';
}

export type T_EditCapabilityActions = {
    type: string;
    field?: string;
    payload?: any;
}

export const emptyEditCapabilityReducer: T_EditCapabilityReducer = {
    capabilities: [],
    editCapability: false,
    selectedCapability: '',
};

export default function reducerEditCapability(state: T_EditCapabilityReducer, action: T_EditCapabilityActions): T_EditCapabilityReducer {
    switch (action.type) {
        case 'init':
            return {
                ...state,
            }
        case 'addCapability':
            return {
                ...state,
                selectedCapability: '',
                editCapability: true,
            }
        case 'editCapability':
            return {
                ...state,
                selectedCapability: action.payload,
                editCapability: true,
            }
        case 'update':
            if (!action?.field) return state;
            return {
                ...state,
                [action.field]: action?.payload,
            }
        default:
            return state;
    }
}