import { useEffect, useRef, useState } from 'react';
import "./ZTMapping.css";
import * as joint from 'jointjs/dist/joint.js';
// import $ from 'jquery';
import './jointjs/dagre.js';
import './jointjs/graphlib.js';
import './jointjs/joint.css';
import { useZTQueries } from '../../hooks/useZTQueries';
import { VerticalAlignCenter } from '@mui/icons-material';
// import 'jointjs/dist/joint.css';
const { shapes, dia, connectionStrategies, elementTools } = joint;

const addPanAndZoom = (paper: any) => {
    let startPosition: { x: number; y: number } | null = null;
    let currentTranslation = { x: 0, y: 0 };
    let startTranslation = { x: 0, y: 0 };
    let currentScale = 1;

    const handleMouseDown = (evt: MouseEvent) => {
        startPosition = { x: evt.clientX, y: evt.clientY };
        startTranslation = { ...currentTranslation };
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const handleMouseMove = (evt: MouseEvent) => {
        if (!startPosition) return;
        const dx = evt.clientX - startPosition.x;
        const dy = evt.clientY - startPosition.y;
        currentTranslation = {
            x: startTranslation.x + dx,
            y: startTranslation.y + dy,
        };
        paper.translate(currentTranslation.x, currentTranslation.y);
    };

    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        startPosition = null;
    };

    const handleWheel = (evt: WheelEvent) => {
        evt.preventDefault();
        const delta = evt.deltaY > 0 ? -0.1 : 0.1;
        currentScale = Math.max(0.1, currentScale + delta);
        paper.scale(currentScale, currentScale);
    };

    paper.el.addEventListener('mousedown', handleMouseDown);
    paper.el.addEventListener('wheel', handleWheel);
};



export function diaSortLayout(graph: any) {
    joint.layout.DirectedGraph.layout(graph, {
        //   filter : (elm:any) => {
        //     return elm.attributes?.['data-id'] !== 'tier';
        //   },
        setLinkVertices: false,
        // align: "UR", // ul, ur, dl, dr
        rankDir: "LR", // tb, bt, lr, rl
        nodeSep: 5,
        rankSep: 100,
        // edgeSep: 5,

    });

}

export function diaSortLayoutBulk(graph: any) {
    joint.layout.DirectedGraph.layout(graph, {
        //   filter : (elm:any) => {
        //     return elm.attributes?.['data-id'] !== 'tier';
        //   },
        // setLinkVertices: false,
        align: "UR", // ul, ur, dl, dr
        rankDir: "LR", // tb, bt, lr, rl
        // edgeSep: 5,

    });

}

const g: any = { assets: {} }

const rectSize = { width: 50, height: 30 };

function addAssetCircle(label: any, bg = 'white') {
    return new shapes.standard.Rectangle({
        position: { x: 0, y: 0 },
        size: { ...rectSize },
        attrs: {
            label: {
                text: label,
                preserveSpaces: true,
                fontWeight: 'bold',
                style: {
                    userSelect: 'text'
                }
            },
            body: {
                rx: 10,
                ry: 10,
                fill: bg,
            },
        }
    });
}

export function addAssetRect(label: any, bg = 'white') {
    let rect = new shapes.standard.Rectangle({
        position: { x: 0, y: 0 },
        size: { ...rectSize },
        attrs: {
            label: {
                text: label,
                preserveSpaces: true,
                fontWeight: 'bold',
                style: {
                    userSelect: 'text'
                }
            },
            body: {
                // strokeWidth: 3
                fill: bg,
            },
        }
    });
    return rect;
}


function addLink(source: any, target: any, graph: any) {
    let loopback = {};
    // if (source.id === target.id) loopback = _loopbackProps;
    let link = new dia.Link({
        source: { id: source.id },
        target: { id: target.id },
        attrs: {
            '.marker-target': {
                d: 'M 10 0 L 0 5 L 10 10 Z', // Arrow shape for the error marker
            },
        },
        connector: {
            name: 'rounded'
        },
        router: {
            name: 'manhattan',
            args: {
                padding: 10,
                step: 100,
                startDirections: ['right'],
                endDirections: ['left'],
            }
        },
    });
    graph.addCell(link);
    return link;
}

function addLinkBulk(source: any, target: any, graph: any) {
    let loopback = {};
    // if (source.id === target.id) loopback = _loopbackProps;
    let link = new dia.Link({
        source: { id: source.id },
        target: { id: target.id },
        attrs: {
            '.marker-target': {
                d: 'M 10 0 L 0 5 L 10 10 Z', // Arrow shape for the error marker
            },
        },
        connector: {
            name: 'jumpover'
        },
        router: {
            name: 'manhattan'
        },
    });
    graph.addCell(link);
    return link;
}

export function ZTMapPredSucc({ data, gWidth = '574px', gHeight = '170px', changeActivityById }: any) {
    const graphRef = useRef<HTMLDivElement>(null);

    const handleElementClick = (elementView: any) => {
        const element = elementView.model;
        changeActivityById(element.attr('label/text'));
    };

    useEffect(() => {
        if (!data) return;
        let _g: any = {};
        const namespace = shapes;

        const graph = new dia.Graph({}, { cellNamespace: namespace });

        let paper = new dia.Paper({
            el: graphRef.current,
            model: graph,
            interactive: false,
            width: '100%',
            height: '100%',
            background: { color: 'white' },
            cellViewNamespace: namespace
        });

        const assets = [...data.predecessor, ...data.successor].reverse();

        _g[data.aId] = addAssetCircle(data.aId, 'var(--color-p1)');
        graph.addCell(_g[data.aId]);

        assets.forEach((asset: any, index: number) => {
            _g[asset] = addAssetRect(asset);
            graph.addCell(_g[asset]);
        });

        data.predecessor.forEach((aId: any) => {
            addLink(_g[aId], _g[data.aId], graph);
        })
        data.successor.forEach((aId: any) => {
            addLink(_g[data.aId], _g[aId], graph);
        })

        diaSortLayout(graph);

        paper.transformToFitContent({
            padding: 20,  // Optional: Padding around the graph
            preserveAspectRatio: true,  // Optional: Preserve aspect ratio
            minScale: 0.5,  // Optional: Minimum scale factor
            maxScale: 1.2,  // Optional: Maximum scale factor
            horizontalAlign: 'middle',
            verticalAlign: 'middle',
        });

        paper.on('element:pointerclick', handleElementClick, { passive: true });

        return () => {
            paper.clearGrid();

            // paper.off()
            graph.clear();

        };

    }, [data])

    return (
        <div ref={graphRef} style={{ height: gHeight, width: gWidth }} />
    )
}

export default function ZTMapping(props: any) {
    const ZTquery = useZTQueries();
    const [data, setData] = useState<any>(null);
    const graphRef = useRef<HTMLDivElement>(null);
    const paperRef = useRef<joint.dia.Paper | null>(null);

    useEffect(() => {
        ZTquery.getActivityMappings().then((data: any) => {
            setData(data);
        });

    }, [])

    useEffect(() => {
        if (!data) return;
        console.log(data);
        const namespace = shapes;

        const graph = new dia.Graph({}, { cellNamespace: namespace });

        const paper = new dia.Paper({
            // el: document.getElementById('paper'),
            el: graphRef.current,
            model: graph,
            interactive: true,
            width: '100%',
            height: '100%',
            background: { color: '#F5F5F5' },
            cellViewNamespace: namespace
        });

        const markerId = paper.defineMarker({
            type: 'path', // SVG Path
            fill: '#666',
            stroke: '#333',
            d: 'M 10 -10 0 0 10 10 Z'
        });

        data.forEach((zt: any) => {
            g.assets[zt.aId] = addAssetRect(zt.aId);
            graph.addCell(g.assets[zt.aId]);
        });

        data.forEach((zt: any) => {
            zt.predecessor.forEach((aId: any) => {
                const svgPath: any = addLinkBulk(g.assets[aId], g.assets[zt.aId], graph);
                // svgPath.setAttribute('marker-start', `url(#${markerId})`)
            })
            zt.successor.forEach((aId: any) => {
                addLinkBulk(g.assets[zt.aId], g.assets[aId], graph);
            })
        });

        diaSortLayoutBulk(graph);
        addPanAndZoom(paper);

    }, [data])

    return (
        <div style={{ height: '100vh', width: '100vw' }}>
            <div>Mapping! 2</div>
            <div ref={graphRef} />
        </div>
    )
}