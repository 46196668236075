import { useEffect, useRef, useState } from 'react';
import { ReactComponent as PillarSVG } from '../../assets/ZTPillars.svg';
import './ZTPillarSVG.css';
import $ from 'jquery';

export default function ZTPillarSVG({ setPillar = null, height = '15rem', width = '30rem' }) {
    const refPillar = useRef(null);
    const [selectedPillar, setSelectedPillar] = useState(["1", 0]);

    useEffect(() => {
        $('#ZTPillars').on('click', (e) => {
            const _id = e.target.parentElement.id.split(' ');
            if (_id.length === 1) return;
            setSelectedPillar(prev => [_id[1], prev[1] + 1]);
            if (setPillar) setPillar(prev => [_id[1], prev[1] + 1]);
        });

        return (() => {
            $('#ZTPillars').off('click');
        });
    }, [refPillar.current])

    return (
        <div className='pillar-svg-container'>
            <PillarSVG ref={refPillar} />
        </div>
    )
}