
import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import { useAPIHandler } from '../../hooks/useAPIHandler';
import { useApp } from '../RealmApp';
import { error } from 'console';
import { isLocal } from '../utils/appUtils';

const fillChartData = (implementedArray: Number[], availableArray: Number[]) => {
    return {
        labels: ['User | 1', 'Device | 2', 'App/Workload | 3', 'Data | 4', 'Ntwk/Env | 5', 'Auto/Orch | 6', 'Vis/Analytics | 7'],
        datasets: [
            {
                type: 'bar',
                label: 'Implemented',
                backgroundColor: "#22c55e",
                data: implementedArray,
            },
            {
                type: 'bar',
                label: 'Available',
                backgroundColor: "#3b82f6",
                data: availableArray,
            },
        ]
    };
}

const demoData = {
    implemented: [0.6, 0.5, 0.9, 0.2, 1, 0.4, 0.3],
    available: [0.4, 0.5, 0.1, 0.8, 0, 0.6, 0.7]
}

export default function StackedBarChart() {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const { getPillarsImplemented } = useAPIHandler();
    const { activeProject }: any = useApp();
    const projectName = activeProject?.selection?.name;

    useEffect(() => {
        if (!projectName) return;
        const title = (isLocal) ? 'Pillars - Implemented' : '*Pillars - Implemented';
        getPillarsImplemented(projectName, activeProject?.profile)
            .then((data: any) => {
                data = data.data;
                setChartData(
                    fillChartData(data.implementedNorm, data.availableNorm)
                )
            })
            .catch((error) => {
                if (!isLocal) setChartData(fillChartData(demoData.implemented, demoData.available))
            })

        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            indexAxis: 'y',
            label: 'my chart',
            plugins: {
                datalabels: false,
                title: {
                    display: true, // Show the title
                    text: title, // Title text
                    font: {
                        size: 12 // Font size of the title
                    }
                },
                tooltip: {
                    mode: 'index',
                    intersect: true,
                    callbacks: {
                        label: (tooltipItem: any) => {
                            return Math.floor(tooltipItem.raw * 100) + '%'; // Convert value to percentage
                        }
                    }
                },
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        color: textColorSecondary,
                        callback: (value: number, index: number, values: any) => {
                            return Math.floor(value * 100) + '%'
                        }
                    },
                    grid: {
                        color: surfaceBorder
                    }
                },
                y: {
                    stacked: true,
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };

        setChartData(fillChartData([], []));
        setChartOptions(options);
    }, [projectName]);

    return (
        <div className="col-12 md:col-6 lg:col-6">
            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                <Chart id={`chart-${projectName}-act-impl`} key={`chart-${projectName}-act-impl`} type="bar" data={chartData} options={chartOptions} />
            </div>
        </div>
    )
}
