import React, { useContext, useEffect, useMemo, useReducer, useRef, useState } from "react"

import './SurveyTool.css';
import { useZT } from "../../hooks/useZTfw";
import { LinearProgress } from "@mui/material";
import { useShowLoader } from "../../hooks/util-hooks";
import ZTSurveyEditCapabilities from "./ZTSurveyEditCapabilities";
import HomeSurvey from "./HomeSurvey";
import SurveyReport from "../SurveyReport/SurveyReport";
import { useApp } from "../RealmApp";
import ACSurveyCriteria from "./ACSurveyCriteria";
import ACSurveySidebar from "../Sidebars/ACSurveySidebar";
import StepsSurveyNavigation_AC from "./StepsSurveyNavigation_AC";

export default function SurveyToolAC() {
    // const { loading }: any = useZT();
    // const showLoader = useShowLoader(loading, 200);
    const { activeProject }: any = useApp();
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const projectName = activeProject?.selection?.name;
    const [selectedAC, setSelectedAC] = useState<string>('');

    const selectedACNum = (selectedAC === '') ? '0' : selectedAC.split('.')[0];

    // if (loading) {
    //     return showLoader ? <LinearProgress /> : null;
    // }


    return (
        <div className="container-survey">
            <div className="container-survey-content border-round border-solid border-1" style={{ zIndex: 7, width: '50%' }}>
                {/* <SurveyPillarOverview /> */}
                <StepsSurveyNavigation_AC activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
                {(activeIndex === 0) &&
                    <HomeSurvey key={`zt-home`} />
                }
                {(activeIndex === 1) &&
                    <ZTSurveyEditCapabilities key={`zt-sv-editcaps`} />
                }
                {(activeIndex === 2) &&
                    <div className="survey-activity-content flex flex-column overflow-y-auto">
                        <ACSurveyCriteria key={`zt-sv-editcaps`} setACId={setSelectedAC} />
                    </div>
                }
                {(activeIndex === 3) &&
                    <SurveyReport />
                }
            </div>
            
            {(activeIndex === 2) &&
                <div className="flex flex-column border-round border-solid border-1 h-full" style={{ zIndex: 8, width: '50%' }}>
                    <div className="text-center p-2 font-bold">{(selectedAC === '') ? '' : `ZTAC ${selectedAC} Activities`}</div>
                    <div className="overflow-y-auto survey-activity-content">
                        <ACSurveySidebar acId={selectedACNum} />
                    </div>
                </div>
            }
        </div>
    )
}