import { getZTProfileData } from "../../apiCalls/apiZT";
import { getCapabilityData } from "../../apiCalls/apiZTKM";
import { useApi } from "../ProviderAPIs";


export const GZT: any = {
    activities: [],
    capabilities: [],
    pillars: [],
    profiles: [],
    nist: {},
    nistFamily: [],
};

export const getCapabilityDataCache = async (cId: string, apiCall:any) => {
    const capabilityData = GZT.capabilities.find((x:any) => x.cId === cId);
    if(capabilityData?.description) return capabilityData;
    const newCapabilityData = await apiCall(cId);
    if(newCapabilityData?.[0]){
        Object.assign(capabilityData, newCapabilityData[0]);
    }
    return capabilityData;
}
export const getZTProfileDataCache = async (profileName: string, apiCall:any) => {
    const profile = GZT.profiles.find((x:any) => x.name === profileName);
    if(profile) return profile.ztIds;
    let newProfileData = null;
    if(apiCall) newProfileData = await apiCall(profileName);
    else newProfileData = await getZTProfileData(profileName);
    if(newProfileData?.name){
        GZT.profiles.push({...newProfileData});
    }
    return newProfileData.ztIds;
}

export const getIdsFromProfileName = (profileName: string): string[] | null => {
    return GZT?.profiles.find((x:any) => x.name === profileName)?.ztIds || null;
}


export default function DataManagement() {
    return (
        <></>
    )
}