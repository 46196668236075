
import { Routes, Route, Navigate } from 'react-router-dom';
import { ZTPage } from '../ZTPage/ZTPage';
import SurveyTool from '../SurveyTool/SurveyTool';
import InformationalZT from '../InformationalZT/InformationalZT';
import TBCS from '../TBCS/TBCS';
import ReportsPage from '../Reports/ReportsPage';
import SurveyToolACProvider from '../SurveyTool/SurveyToolACProvider';
import ExportProjectPage from '../ExportProject/ExportProjectPage';

{/* <NavigateZT /> */}
      {/* {currentUser && <ZTPage user={currentUser} />} */}

export default function RouteLinks(){


    return(
        <Routes>
            <Route path="/explore" element={<ZTPage/>}/>
            <Route path="/" element={<Navigate to="/survey/zt" replace />} />
            <Route path="/survey" element={<SurveyTool />} />
            <Route path="/survey/zt" element={<SurveyTool />} />
            <Route path="/survey/ac" element={<SurveyToolACProvider />} />
            <Route path="/attack" element={<TBCS />} />
            <Route path="/reports" element={<ReportsPage />} />
            <Route path="/other" element={<InformationalZT />} />
            <Route path="/exportpage" element={<ExportProjectPage />} />
        </Routes>
    )
}