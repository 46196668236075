import React, { useEffect, useRef, useState } from "react";
import * as Realm from "realm-web";
import atlasConfig from "../atlasConfig.json";
import { Toast } from "primereact/toast";
import { getProjectAnalytics, getProjectOverview } from "../apiCalls/apiZTKM";
import { getZTProfileData, getZTProfiles } from "../apiCalls/apiZT";
import { getZTProfileDataCache } from "./DataManagement/DataManagement";
// const { baseUrl } = atlasConfig;

// function createApp(id) {
//   return new Realm.App({ id, baseUrl });
// }
function createApp(id) {
    return { id: id, type: 'localapp', isLocalApp: true }
}

export const LocalAppContext = React.createContext(null);
const testPermissions = {
    'app': 'f',
    'projects': {
        'allprojects': 'f'
    }
};



export function LocalAppProvider({ appId, children }) {
    // Store Realm.App in React state. If appId changes, all children will rerender and use the new App.
    const [app, setApp] = useState(createApp(appId));
    const [activeProject, setActiveProject] = useState({});
    const [ztProfiles, setZTProfiles] = useState([]);
    const [ztProfileIds, setZTProfileIds] = useState([]);
    const [criticalFailure, setCriticalFailure] = useState(false);
    const toast = useRef(null);
    //   React.useEffect(() => {
    //     setApp(createApp(appId));
    //   }, [appId]);
    // Store the app's current user in state and wrap the built-in auth functions to modify this state
    //   const [currentUser, setCurrentUser] = React.useState(app.currentUser);
    const [currentUser, setCurrentUser] = useState("LocalUser");
    const [permissions, setPermissions] = useState(testPermissions)
    const [analytics, setAnalytics] = useState([]);
    // Wrap the base logIn function to save the logged in user in state
    const logIn = React.useCallback(
        // async (credentials) => {
        //     await app.logIn(credentials);
        //     setCurrentUser(app.currentUser);
        // },
        // [app]
    );

    useEffect(() => {
        getZTProfiles()
            .then((data) => {
                setZTProfiles(data);
            })
            .catch(err => {
                setCriticalFailure(true);
                toast.current.show({ severity: 'error', summary: 'API Error', detail: 'Cannot load critical project data. Try refreshing.', life: 3000 });
            });
    }, []);

    const getProject = async (projectObj) => {
        getProjectOverview(projectObj.name).then((data) => {
            if (!data?.name) return;
            setActiveProject({
                ...data,
                selection: projectObj,
            });
            getZTProfileDataCache(data?.profile || 'ZT-All').then(data => {
                setZTProfileIds(data);
            });
            getAnalytics(projectObj.name);
        })
    }

    const getAnalytics = async (projectName) => {
        return getProjectAnalytics(projectName).then((data) => {
            if (!data?.data) return setAnalytics([]);
            if (!Object.entries(data.data).length) return setAnalytics([]);
            setAnalytics([
                ...data.data
            ])
        });
    }

    const refreshAnalytics = async () => {
        if (!activeProject?.selection) return;
        return getAnalytics(activeProject.selection.name);
    }

    const refreshProjectData = async () => {
        if (!activeProject?.selection) return;
        return getProject(activeProject.selection);
    }

    // Wrap the current user's logOut function to remove the logged out user from state
    // const logOut = React.useCallback(async () => {
    //     try {
    //         app.users.forEach(async (user) => {
    //             await user?.logOut();
    //             await app.removeUser(user);
    //         })
    //     } catch (err) {
    //         console.error(err);
    //     }
    //     setCurrentUser(null);
    // }, [app]);

    // Override the App's currentUser & logIn properties + include the app-level logout function
    const appContext = React.useMemo(() => {
        return {
            ...app, currentUser,
            permissions,
            toast,
            activeProject,
            getProject,
            refreshProjectData,
            analytics,
            refreshAnalytics,
            ztProfiles,
            ztProfileIds,
            criticalFailure,
        };
    }, [app, currentUser, activeProject, analytics, ztProfiles, ztProfileIds, criticalFailure]);

    return (
        <LocalAppContext.Provider value={appContext}>
            <Toast ref={toast} />
            {children}
        </LocalAppContext.Provider>
    );
}

export function useLocalApp() {
    const app = React.useContext(LocalAppContext);
    if (!app) {
        throw new Error(
            `No App Services App found. Make sure to call useApp() inside of a <LocalAppProvider />.`
        );
    }
    return app;
}
