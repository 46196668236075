import { useEffect, useMemo, useReducer, useRef, useState } from "react"

import './SurveyTool.css';
import { useZT } from "../../hooks/useZTfw";
import { LinearProgress } from "@mui/material";
import { useShowLoader } from "../../hooks/util-hooks";
import SurveySidebar, { defaultSurveyFilter, surveyFilterReducer } from "../Sidebars/SurveySidebar";
import ZTSurveyCapabilityComponent from "./ZTSurveyCapabilityComponent";
import ZTSurveyActivityComponent from "./ZTSurveyActivitiesComponent";
import ZTSurveyEditCapabilities from "./ZTSurveyEditCapabilities";
import StepsSurveyNavigation from "./StepsSurveyNavigation";
import HomeSurvey from "./HomeSurvey";
import SurveyReport from "../SurveyReport/SurveyReport";
import { useApp } from "../RealmApp";
import { getIdsFromProfileName, GZT } from "../DataManagement/DataManagement";

const checkSearchFilter = (elm: any, search: string) => {
    const mainString = `${elm?.aId} ${elm?.name}`.toLowerCase();
    const subString = search.toLowerCase();
    return mainString.includes(subString);
}

const validSidebarIndexes = [2, 3]

export default function SurveyTool() {
    const { loading, apiError, ztfw, ...ztActions }: any = useZT();
    const showLoader = useShowLoader(loading, 200);
    const [stateFilter, dispatchFilter] = useReducer(surveyFilterReducer, defaultSurveyFilter);
    const activitiesShown = useRef(new Set());
    const { activeProject, analytics }: any = useApp();
    const [activeIndex, setActiveIndex] = useState<number>(3);
    const projectName = activeProject?.selection?.name;

    const capabilitiesShown = new Set();
    // const showSidebar = validSidebarIndexes.includes(activeIndex);

    let filteredActivities = [];
    if (ztfw?.activities) {
        const ztIds = getIdsFromProfileName(stateFilter.profile);
        filteredActivities = ztfw?.activities?.filter((elm: any) => {
            if (stateFilter.search !== '' && !checkSearchFilter(elm, stateFilter.search)) return false;
            // if (stateFilter.mpeBaseline && !elm.isMPEBase) return false;
            if (!stateFilter.level.includes(elm.level)) return false;
            if (!stateFilter.phases.includes(elm.phase)) return false;
            if (analytics.find((x: any) => (x.ztId === elm.aId) && (x.status !== 'NA'))) return true;
            if (ztIds && !ztIds.includes(elm.aId)) return false

            activitiesShown.current.add(elm.aId);
            return true;
        });
    }

    const ztToCaps = useMemo(() => {
        if ((activeIndex !== 2) || !activeProject?.ztCapabilities) return {};
        const result: any = {};
        activeProject?.ztCapabilities.forEach(({ name, ztcIds = [] }: any) => {
            ztcIds.forEach((cId: string) => {
                if (!result[cId]) {
                    result[cId] = [];
                }
                result[cId].push(name);
            });
        });
        return result;
    }, [activeProject?.ztCapabilities, activeIndex]);

    useEffect(() => {
        dispatchFilter({ type: 'filterCount', payload: `${filteredActivities.length}/${ztfw?.activities?.length}` });
    }, [filteredActivities.length]); // This will trigger only when the number of filtered activities changes

    if (loading) {
        return showLoader ? <LinearProgress /> : null;
    }

    // if(!projectName) {
    //     return(
    //         <h2>Select a Project to begin.</h2>
    //     )
    // }

    return (
        <div className="container-survey" style={{zIndex: 7}}>
            <div style={{zIndex: 8}}>
                <SurveySidebar state={stateFilter} dispatch={dispatchFilter} />
            </div>

            <div className="container-survey-content">
                {/* <SurveyPillarOverview /> */}
                <StepsSurveyNavigation activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
                {(activeIndex === 0) &&
                    <HomeSurvey key={`zt-home`} />
                }
                {(activeIndex === 1) &&
                    <ZTSurveyEditCapabilities key={`zt-sv-editcaps`} />
                }
                {(activeIndex === 2) &&
                    <div className="survey-activity-content flex flex-column overflow-y-auto">
                        {filteredActivities?.map((elm: any, index: number) => {
                            const showCapability = !capabilitiesShown.has(elm.cId);
                            if (showCapability) capabilitiesShown.add(elm.cId);
                            if (!showCapability) return <div key={`zt-cp-empty-${index}`}></div>
                            return (
                                <div key={`${elm.aId}-cp-${index}-${projectName}`}>
                                    <ZTSurveyCapabilityComponent data={ztfw?.capabilities?.find((x: any) => x.cId === elm.cId) || {}} isView={true} capabilities={ztToCaps?.[elm.cId]} />
                                </div>
                            );
                        })}
                    </div>
                }
                {(activeIndex === 3) &&
                    <div className="survey-activity-content flex flex-column overflow-y-auto">
                        {filteredActivities?.map((elm: any, index: number) => {
                            const showCapability = !capabilitiesShown.has(elm.cId);
                            if (showCapability) capabilitiesShown.add(elm.cId);
                            const keyValue = `${elm.aId}-ap-${index}-${projectName}`
                            return (
                                <div key={keyValue}>
                                    {(showCapability) &&
                                        <ZTSurveyCapabilityComponent data={ztfw?.capabilities?.find((x: any) => x.cId === elm.cId) || {}} isView={false} />
                                    }
                                    <ZTSurveyActivityComponent data={elm} />
                                </div>
                            );
                        })}
                    </div>
                }
                {(activeIndex === 4) &&
                    <SurveyReport />
                }
            </div>
        </div>
    )
}