import { Suspense, useState } from 'react';
import './InformationalZT.css'; // Add your custom styles here
import InformationalSidebar, { DashboardLazy } from '../Sidebars/InformationalSidebar';

const InformationalZT = () => {
    const [selectedComponent, setSelectedComponent] = useState(<DashboardLazy />); // Default component

    return (
        <div className="container-informational">

            <InformationalSidebar setContent={setSelectedComponent} />

            <div className="container-informational-content">
                <div className='restrict-large-display' style={{maxWidth: 'var(--reading-width)'}}>
                    <Suspense fallback={<div>Loading...</div>}>
                        {selectedComponent}
                    </Suspense>
                </div>
            </div>
        </div>
    );
};

export default InformationalZT;
