const baseURL = window.location.hostname.split('.').slice(-2).join('.');

export const g_api = {
    root : `https://api.${baseURL}`,
    updateProject : '/ztkm/projects/update',
    addProject : '/ztkm/projects/add',
    addCapabilities: '/ztkm/capabilities/add',
    updateCapabilities: '/ztkm/capabilities/update',
    deleteCapabilities: '/ztkm/capabilities/delete',
    getProfileNames: '/zt/profiles',
    postExportRFI: '/ztkm/exports/rfi',
    getProfileData: (profileName) => `/zt/profiles/${profileName}`,
    uploadFiles: (project) => `/uploads/ztkm/${project}`,
    modifyStatuses: (project) => `/ztkm/p/${project}/modify`,
    postZTACModify: (project) => `/ztkm/p/${project}/ztacmodify`,
    getProjectAnalytics: (project) => `/ztkm/p/${project}/analytics`,
    getDataResponse: (project, aId, type) => `/ztkm/p/${project}/${type}/${aId}`,
    getPillarsImplemented: (project) => `/ztkm/p/${project}/report/pillarsimplemented`,
    getReportActivityGaps: (project) => `/ztkm/p/${project}/report/activitygaps`,
    getReportActivitiesImplemented: (project) => `/ztkm/p/${project}/report/activitiesimplemented`,
    getReportOutcomesImplemented: (project) => `/ztkm/p/${project}/report/outcomesimplemented`,
    getOverlayContentByTypeId: '/nist/800-53/overlays',
    postImportNewProjectExcel: '/uploads/ztkm/importxlsx',
    getZTACMappings: (acId) => `/zt/ztac/${acId}`,
    getEntireProject: (project) => `/ztkm/p/${project}/exportall`,
    getEntireZTDatabase: '/zt/entiredatabase',
    getProjectFiles: (project) => `/ztkm/p/${project}/exportfiles`,

}