import { T_FileObject } from "./EditCapabilityPopup";
import { T_FileObjectBackend } from "./ZTSurveyActivitiesComponent";

export type T_ActivityResponse = {
    maturity: string;
    outcomes: {
        index: number;
        value: 'No' | 'Partial' | 'Yes';
    }[];
    note: string;
    updated: boolean;
    type: string;
    files: T_FileObject[];
}

export type T_DefaultReducerAction = {
    type: string;
    field?: string;
    payload?: any;
}

export const emptyEditActivityReducer: T_ActivityResponse = {
    maturity: 'Incomplete',
    outcomes: [],
    note: '',
    updated: false,
    type: 'activity',
    files: [],
};

export default function reducerEditActivity(state: T_ActivityResponse, action: T_DefaultReducerAction): T_ActivityResponse {
    switch (action.type) {
        case 'init':
            return {
                ...emptyEditActivityReducer,
                ...action.payload,
            }
        case 'discard': {
            return {
                ...action.payload,
            }
        }
        case 'update':
            if (!action?.field) return state;
            return {
                ...state,
                [action.field]: action?.payload,
                updated: true,
            }
        case 'changeOutcome':
            const newOutcomes = state.outcomes.filter((x:any) => x.index !== action.payload.index)
            newOutcomes.push(action.payload);
            return {
                ...state,
                outcomes: [...newOutcomes],
                updated: true,
            }
        default:
            return state;
    }
}