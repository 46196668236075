// context/ApiContext.tsx
import React, { createContext, useContext, ReactNode } from 'react';
import { useAPIHandler } from '../hooks/useAPIHandler';

interface ApiFunctions {
  fetchData: () => Promise<any>;
}

const ApiContext = createContext<ApiFunctions | null>(null);

interface ApiProviderProps {
  children: ReactNode;
}

export const ApiProvider: React.FC<ApiProviderProps> = ({ children }) => {
  const api:any = useAPIHandler();

  return (
    <ApiContext.Provider value={api}>
      {children}
    </ApiContext.Provider>
  );
};

export const useApi = (): ApiFunctions => {
  const context = useContext(ApiContext);
  if (context === null) {
    throw new Error('useApi must be used within an ApiProvider');
  }
  return context;
};
