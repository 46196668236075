

const ReportsPage = () => {
    return(
        <>
            Reports page
        </>
    )
}

export default ReportsPage;